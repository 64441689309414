'use client';

import { useState } from 'react';
import { Input, Button, Card, CardHeader, CardBody, Select, SelectItem, Checkbox, Tooltip, NumberInput, Divider, Autocomplete, AutocompleteItem, Accordion, AccordionItem } from '@heroui/react';
import { FormField } from '../models/FormField';
import { Icon } from '@iconify/react/dist/iconify.js';
import Editor from './Editor';

interface EbayFormProps {
    formFields: FormField[];
    onSubmit: () => void;
    onSaveDraft: () => void;
    onDirectUpload: () => void;
    onFormDataChange: (data: Record<string, any>) => void;
    isLoading?: boolean;
    isSaving?: boolean;
}

export default function EbayForm({ 
    formFields, 
    onSubmit, 
    onSaveDraft, 
    onDirectUpload,
    onFormDataChange,
    isLoading,
    isSaving 
}: EbayFormProps) {
    const [formData, setFormData] = useState<Record<string, any>>({});
    const [errors, setErrors] = useState<Record<string, string>>({});

    const handleSubmit = () => {
        onSubmit();
    };

    const handleSaveDraft = () => {
        onSaveDraft();
    };

    const handleChange = (key: string, value: any) => {
        const newFormData = { ...formData, [key]: value };
        setFormData(newFormData);
        onFormDataChange?.(newFormData);
    };

    const renderFormField = (field: FormField) => {
        switch (field.type) {
            case 'text':
                if (field.fieldType === 'number' || field.fieldType === 'numberWithDigits') {
                    return (
                        <div className="relative">
                            <NumberInput
                                variant='bordered'
                                label={field.name}
                                placeholder={field.name}
                                value={formData[field.key] || field.initialValue || ''}
                                onValueChange={(value) => handleChange(field.key, value)}
                                isRequired={field.mandatory}
                                endContent={
                                    field.info ? (
                                        <Tooltip content={field.info}>
                                            <Icon
                                                icon="solar:info-circle-outline"
                                                className="text-gray-400 cursor-help"
                                                width={20}
                                            />
                                        </Tooltip>
                                    ) : null
                                }
                            />
                        </div>
                    );
                }
                return (
                    <div className="relative">
                        <Input
                            variant='bordered'
                            label={field.name}
                            placeholder={field.name}
                            value={formData[field.key] || field.initialValue || ''}
                            onValueChange={(value) => handleChange(field.key, value)}
                            isRequired={field.mandatory}
                            maxLength={field.maxLength}
                            defaultValue={field.initialValue}
                            endContent={
                                field.info ? (
                                    <Tooltip content={field.info}>
                                        <Icon
                                            icon="solar:info-circle-outline"
                                            className="text-gray-400 cursor-help"
                                            width={20}
                                        />
                                    </Tooltip>
                                ) : field.maxLength ? (
                                    <span className="text-gray-400 text-sm">
                                        {(formData[field.key] || field.initialValue || '').length}/{field.maxLength}
                                    </span>
                                ) : null
                            }
                        />
                    </div>
                );
            case 'html':
                return (
                    <div className="relative">
                        <div className="mb-2">
                            <span className="text-sm font-medium">{field.name}</span>
                            {field.mandatory && <span className="text-danger ml-1">*</span>}
                            {field.info && (
                                <Tooltip content={field.info}>
                                    <Icon
                                        icon="solar:info-circle-outline"
                                        className="text-gray-400 cursor-help ml-2"
                                        width={20}
                                    />
                                </Tooltip>
                            )}
                        </div>
                        <div className="border-solid border-2 border-default-200 rounded-xl overflow-hidden">
                            <Editor
                                value={formData[field.key] || field.initialValue || ''}
                                onChange={(value) => handleChange(field.key, value)}
                                order={0}
                                formData={formData}
                            />
                        </div>
                    </div>
                );
            case 'bool':
                if (field.key === 'is_adult') {
                    return (
                        <div className="relative flex flex-col gap-2">
                            <div
                                className="flex justify-between items-center p-1 rounded-xl border-2 border-default-200 hover:bg-default-100 transition-colors cursor-pointer"
                                onClick={() => handleChange(field.key, !formData[field.key])}
                                role="button"
                                tabIndex={0}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter' || e.key === ' ') {
                                        e.preventDefault();
                                        handleChange(field.key, !formData[field.key]);
                                    }
                                }}
                            >
                                <div className="flex items-center gap-2">
                                    <Icon icon="solar:shield-warning-outline" className="text-primary" width={20} />
                                    <div className="flex flex-col">
                                        <span className="font-medium">{field.name}</span>
                                        <span className="text-sm text-default-500">Angebot enthält FSK18 Inhalte</span>
                                    </div>
                                </div>
                                <Checkbox
                                    isSelected={formData[field.key] || field.initialValue || false}
                                    onValueChange={(value) => handleChange(field.key, value)}
                                    className="pointer-events-none"
                                />
                            </div>
                        </div>
                    );
                }
                return (
                    <div className="relative">
                        <Checkbox
                            isSelected={formData[field.key] || field.initialValue || false}
                            onValueChange={(value) => handleChange(field.key, value)}
                        >
                            <div className="flex items-center gap-2">
                                <span>{field.name}</span>
                                {field.mandatory && <span className="text-danger">*</span>}
                                {field.info && (
                                    <Tooltip content={field.info}>
                                        <Icon
                                            icon="solar:info-circle-outline"
                                            className="text-gray-400 cursor-help"
                                            width={20}
                                        />
                                    </Tooltip>
                                )}
                            </div>
                        </Checkbox>
                    </div>
                );
            case 'singleSelect':
                return (
                    <Autocomplete
                        variant='bordered'
                        label={field.name}
                        placeholder={field.name}
                        selectedKey={formData[field.key]?.value || field.initialValue?.value || ''}
                        onSelectionChange={(e) => handleChange(field.key, { value: e, name: field.options.find(opt => opt.value === e)?.name })}
                        isRequired={field.mandatory}
                        allowsEmptyCollection={field.mandatory}
                        allowsCustomValue={field.allowCustomValue}
                        showScrollIndicators={true}
                        listboxProps={{
                            emptyContent: "Kein Ergebnis gefunden.",
                        }}
                        endContent={
                            field.info ? (
                                <Tooltip content={field.info}>
                                    <Icon
                                        icon="solar:info-circle-outline"
                                        className="text-gray-400 cursor-help"
                                        width={20}
                                    />
                                </Tooltip>
                            ) : null
                        }
                    >
                        {field.options.map((option) => (
                            <AutocompleteItem key={option.value} textValue={option.name}>
                                {option.name}
                            </AutocompleteItem>
                        ))}
                    </Autocomplete>
                );
            case 'multiSelect':
                return (
                    <div className="relative">
                        <Select
                            variant='bordered'
                            label={field.name}
                            placeholder={field.name}
                            selectionMode="multiple"
                            selectedKeys={formData[field.key]?.map((item: any) => item.value) || field.initialValues?.map((item: any) => item.value) || []}
                            onSelectionChange={(keys) => {
                                const selectedOptions = field.options.filter(opt =>
                                    Array.from(keys).includes(opt.value)
                                );
                                handleChange(field.key, selectedOptions);
                            }}
                            isRequired={field.mandatory}
                            disallowEmptySelection={field.mandatory}
                            endContent={
                                field.info ? (
                                    <Tooltip content={field.info}>
                                        <Icon
                                            icon="solar:info-circle-outline"
                                            className="text-gray-400 cursor-help"
                                            width={20}
                                        />
                                    </Tooltip>
                                ) : null
                            }
                        >
                            {field.options.map((option) => (
                                <SelectItem key={option.value}>
                                    {option.name}
                                </SelectItem>
                            ))}
                        </Select>
                    </div>
                );
            default:
                return null;
        }
    };

    return (
        <Card className="w-full rounded-lg border bg-background shadow">
            <CardBody className="flex flex-col gap-8">
                {/* 1. Angebotsformat */}
                <section>
                    <div className="flex items-center gap-2 mb-4">
                        <Icon icon="solar:tag-price-outline" width={20} />
                        <h2 className="text-xl font-semibold">Angebotsformat</h2>
                    </div>
                    <div className="grid grid-cols-2 gap-2">
                        {formFields.find(f => f.key === 'format') && renderFormField(formFields.find(f => f.key === 'format')!)}
                        {formFields.find(f => f.key === 'price') && renderFormField(formFields.find(f => f.key === 'price')!)}
                    </div>
                </section>

                {/* 2. Angebotsdaten */}
                <section>
                    <div className="flex items-center gap-2 mb-4">
                        <Icon icon="solar:documents-outline" width={20} />
                        <h2 className="text-xl font-semibold">Angebotsdaten</h2>
                    </div>
                    <div className="flex flex-col gap-2">
                        {formFields.find(f => f.key === 'title') && renderFormField(formFields.find(f => f.key === 'title')!)}
                        {formFields.find(f => f.key === 'seller_notes') && renderFormField(formFields.find(f => f.key === 'seller_notes')!)}
                        {formFields.find(f => f.key === 'condition') && renderFormField(formFields.find(f => f.key === 'condition')!)}
                        {formFields.find(f => f.key === 'description') && renderFormField(formFields.find(f => f.key === 'description')!)}
                        {formFields.find(f => f.key === 'scope_of_delivery') && renderFormField(formFields.find(f => f.key === 'scope_of_delivery')!)}
                        {formFields.find(f => f.key === 'other_scope_of_delivery') && renderFormField(formFields.find(f => f.key === 'other_scope_of_delivery')!)}
                    </div>

                    {/* Pflichtfelder für Artikelmerkmale */}
                    {formFields.some(field => field &&
                        !['format', 'price', 'weight', 'is_adult',
                            'title', 'seller_notes', 'condition', 'description',
                            'scope_of_delivery', 'other_scope_of_delivery', 'category_id'].includes(field.key) &&
                        field.key.startsWith('ItemSpecifics') &&
                        field.mandatory
                    ) && (
                            <div className="mt-8">
                                <div className="flex items-center gap-2 mb-4">
                                    <Icon icon="solar:bag-smile-outline" width={20} />
                                    <h2 className="text-xl font-semibold">eBay-Pflichtangaben</h2>
                                </div>
                                <div className="flex flex-col gap-2">
                                    {formFields
                                        .filter(field => field &&
                                            !['format', 'price', 'weight', 'is_adult',
                                                'title', 'seller_notes', 'condition', 'description',
                                                'scope_of_delivery', 'other_scope_of_delivery', 'category_id'].includes(field.key) &&
                                            field.key.startsWith('ItemSpecifics') &&
                                            field.mandatory
                                        )
                                        .map((field) => (
                                            <div key={field.key}>
                                                {renderFormField(field)}
                                            </div>
                                        ))}
                                </div>
                            </div>
                        )}
                </section>

                {/* 3. Optionale Artikelmerkmale */}
                {formFields.some(field => field.key.startsWith('ItemSpecifics') && !field.mandatory) && (
                    <section>
                        <Accordion variant="splitted" itemClasses={{ base: "w-full rounded-lg border bg-background shadow", content: "pb-4" }}>
                            <AccordionItem
                                key="optional-specifics"
                                aria-label="Optionale Artikelmerkmale"
                                startContent={
                                    <Icon icon="solar:cart-plus-outline" width={20} />
                                }
                                title={
                                    <div className="flex items-center gap-2">
                                        <span className="text-xl font-semibold">Optionale Artikelmerkmale</span>
                                    </div>
                                }
                            >
                                <div className="flex flex-col gap-2">
                                    {formFields
                                        .filter(field =>
                                            field.key.startsWith('ItemSpecifics') &&
                                            !field.mandatory
                                        )
                                        .map((field) => (
                                            <div key={field.key}>
                                                {renderFormField(field)}
                                            </div>
                                        ))}
                                </div>
                            </AccordionItem>
                        </Accordion>
                    </section>
                )}

                {/* 4. Paketangaben */}
                <section>
                    <div className="flex items-center gap-2 mb-4">
                        <Icon icon="solar:box-outline" width={20} />
                        <h2 className="text-xl font-semibold">Paketangaben</h2>
                    </div>
                    <div className="grid grid-cols-2 gap-2">
                        {formFields.find(f => f.key === 'weight') && renderFormField(formFields.find(f => f.key === 'weight')!)}
                        {formFields.find(f => f.key === 'is_adult') && renderFormField(formFields.find(f => f.key === 'is_adult')!)}
                    </div>
                </section>

                <div className="flex justify-between items-center mt-4 gap-2">
                    <Button
                        isIconOnly
                        color="default"
                        variant="bordered"
                        onPress={onSubmit}
                    >
                        <Icon icon="solar:code-square-outline" width={20} />
                    </Button>
                    <Button
                        color="default"
                        variant="bordered"
                        onPress={onSaveDraft}
                        className="flex-1"
                        isLoading={isSaving}
                    >
                        Entwurf speichern
                    </Button>
                    <Button
                        color="primary"
                        onPress={onDirectUpload}
                        className="flex-1"
                        isLoading={isLoading}
                    >
                        Angebot hochladen
                    </Button>
                </div>
            </CardBody>
        </Card>
    );
} 