export class EbayDraft {
    id: string;
    title: string;
    firstImageUrl: string | null;
    dataRequest?: any;
    json: any;

    constructor(data: {
        id: string;
        title: string;
        firstImageUrl?: string | null;
        dataRequest?: any;
        json: any;
    }) {
        this.id = data.id;
        this.title = data.title;
        this.firstImageUrl = data.firstImageUrl || null;
        if (data.dataRequest) {
            this.dataRequest = data.dataRequest;
        }
        this.json = data.json;
    }

    static fromJson(json: any): EbayDraft {
        return new EbayDraft({
            id: json.id,
            title: json.title,
            firstImageUrl: json.firstImageUrl || null,
            dataRequest: json.dataRequest,
            json: json.json
        });
    }

    toJson(): any {
        const json: any = {
            id: this.id,
            title: this.title,
            firstImageUrl: this.firstImageUrl,
            json: this.json
        };

        if (this.dataRequest) {
            json.dataRequest = this.dataRequest;
        }

        return json;
    }
} 