import { ArticleCheckCondition } from './articleCondition';
import { MailText } from './mailText';
import { ArticleAction } from './articleActions';

export interface ConditionCalculatorLightRequest {
  answers: Record<string, number>;
}

export interface ConditionCalculatorLightResponse {
  score: number;
  condition: ArticleCheckCondition;
  texts: MailText[];
  actions: ArticleAction[];
}

export class ConditionCalculatorLightRequestModel {
  answers: Record<string, number>;

  constructor(init: Partial<ConditionCalculatorLightRequestModel>) {
    Object.assign(this, {
      answers: {},
      ...init
    });
  }

  static fromJson(json: any): ConditionCalculatorLightRequestModel {
    return new ConditionCalculatorLightRequestModel({
      answers: json.answers
    });
  }

  static toJson(request: ConditionCalculatorLightRequestModel): any {
    return {
      answers: request.answers
    };
  }
}

export class ConditionCalculatorLightResponseModel {
  score: number;
  condition: ArticleCheckCondition;
  texts: MailText[];
  actions: ArticleAction[];

  constructor(init: Partial<ConditionCalculatorLightResponseModel>) {
    Object.assign(this, {
      score: 0,
      condition: new ArticleCheckCondition({}),
      texts: [],
      actions: [],
      ...init
    });
  }

  static fromJson(json: any): ConditionCalculatorLightResponseModel {
    return new ConditionCalculatorLightResponseModel({
      score: json.score,
      condition: ArticleCheckCondition.fromJson(json.condition),
      texts: json.texts?.map(MailText.fromJson),
      actions: json.actions
    });
  }

  static toJson(response: ConditionCalculatorLightResponseModel): any {
    return {
      score: response.score,
      condition: {
        condition: response.condition.id,
        conditionName: response.condition.name
      },
      texts: response.texts?.map(text => MailText.toJson(text)),
      actions: response.actions
    };
  }
}
