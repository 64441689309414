'use client';

import { Button, Divider } from '@heroui/react';
import { 
    Bold, 
    Italic, 
    List, 
    ListOrdered, 
    AlignLeft, 
    AlignCenter, 
    AlignRight,
    Undo,
    Redo,
    Sparkles
} from 'lucide-react';
import { OpenAiService } from '../api/openAiService';
import { useState } from 'react';
import { Spinner } from '@heroui/react';

interface EditorMenuBarProps {
    editor: any;
    formData?: Record<string, any>;
}

//TODO: ADD TEXT MODAL

export default function EditorMenuBar({ editor, formData }: EditorMenuBarProps) {
    const [isGenerating, setIsGenerating] = useState(false);

    if (!editor) {
        return null;
    }

    const handleAiGenerate = async () => {
        try {
            setIsGenerating(true);
            const openAiService = new OpenAiService();
            
            const response = await openAiService.generateArticleText({
                offerTitle: formData?.title || '',
                sellerNotes: formData?.seller_notes || '',
                conditionName: formData?.condition?.name || '',
                legacyText: editor.getHTML()
            });

            const generatedText = response.choices[0]?.message?.content;
            if (generatedText) {
                editor.commands.setContent(generatedText);
            }
        } catch (error) {
            console.error('Fehler bei der KI-Textgenerierung:', error);
        } finally {
            setIsGenerating(false);
        }
    };

    return (
        <div className="border-b p-2 flex flex-wrap gap-2 items-center">
            <Button
                isIconOnly
                size="sm"
                variant="light"
                onPress={() => editor.chain().focus().toggleBold().run()}
                isDisabled={!editor.can().chain().focus().toggleBold().run()}
                className={editor.isActive('bold') ? 'bg-primary-100' : ''}
            >
                <Bold className="w-4 h-4" />
            </Button>
            <Button
                isIconOnly
                size="sm"
                variant="light"
                onPress={() => editor.chain().focus().toggleItalic().run()}
                isDisabled={!editor.can().chain().focus().toggleItalic().run()}
                className={editor.isActive('italic') ? 'bg-primary-100' : ''}
            >
                <Italic className="w-4 h-4" />
            </Button>
            <Button
                isIconOnly
                size="sm"
                variant="light"
                onPress={() => editor.chain().focus().toggleBulletList().run()}
                isDisabled={!editor.can().chain().focus().toggleBulletList().run()}
                className={editor.isActive('bulletList') ? 'bg-primary-100' : ''}
            >
                <List className="w-4 h-4" />
            </Button>
            <Button
                isIconOnly
                size="sm"
                variant="light"
                onPress={() => editor.chain().focus().toggleOrderedList().run()}
                isDisabled={!editor.can().chain().focus().toggleOrderedList().run()}
                className={editor.isActive('orderedList') ? 'bg-primary-100' : ''}
            >
                <ListOrdered className="w-4 h-4" />
            </Button>
            <Button
                isIconOnly
                size="sm"
                variant="light"
                onPress={() => editor.chain().focus().setTextAlign('left').run()}
                isDisabled={!editor.can().chain().focus().setTextAlign('left').run()}
                className={editor.isActive({ textAlign: 'left' }) ? 'bg-primary-100' : ''}
            >
                <AlignLeft className="w-4 h-4" />
            </Button>
            <Button
                isIconOnly
                size="sm"
                variant="light"
                onPress={() => editor.chain().focus().setTextAlign('center').run()}
                isDisabled={!editor.can().chain().focus().setTextAlign('center').run()}
                className={editor.isActive({ textAlign: 'center' }) ? 'bg-primary-100' : ''}
            >
                <AlignCenter className="w-4 h-4" />
            </Button>
            <Button
                isIconOnly
                size="sm"
                variant="light"
                onPress={() => editor.chain().focus().setTextAlign('right').run()}
                isDisabled={!editor.can().chain().focus().setTextAlign('right').run()}
                className={editor.isActive({ textAlign: 'right' }) ? 'bg-primary-100' : ''}
            >
                <AlignRight className="w-4 h-4" />
            </Button>
            <Button
                isIconOnly
                size="sm"
                variant="light"
                onPress={() => editor.chain().focus().undo().run()}
                isDisabled={!editor.can().chain().focus().undo().run()}
            >
                <Undo className="w-4 h-4" />
            </Button>
            <Button
                isIconOnly
                size="sm"
                variant="light"
                onPress={() => editor.chain().focus().redo().run()}
                isDisabled={!editor.can().chain().focus().redo().run()}
            >
                <Redo className="w-4 h-4" />
            </Button>

            <Divider orientation="vertical" className="h-8" />

            <Button
                isIconOnly
                size="sm"
                variant="light"
                onPress={handleAiGenerate}
                isDisabled={isGenerating}
                className="relative"
            >
                {isGenerating ? (
                    <Spinner size="sm" />
                ) : (
                    <Sparkles className="w-4 h-4" />
                )}
            </Button>
        </div>
    );
} 