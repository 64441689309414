import { MailText } from "./mailText";

export class PriceResponse {
    articleId: number;
    title: string;
    hasZoxsCheck: boolean;
    price: number;
    decision: string;
    actions: string[];
    text: MailText | null;
    textIdsToRemove: number[];
  
    constructor(init: Partial<PriceResponse>) {
      Object.assign(this, {
        articleId: 0,
        title: "",
        hasZoxsCheck: false,
        price: 0,
        decision: "",
        actions: [],
        text: null,
        textIdsToRemove: [],
        ...init
      });
    }
  
    static fromJson(json: any): PriceResponse {
      return new PriceResponse({
        articleId: json.articleId,
        title: json.title,
        hasZoxsCheck: json.hasZoxsCheck,
        price: json.price,
        decision: json.decision,
        actions: json.actions,
        text: json.text ? MailText.fromJson(json.text) : null,
        textIdsToRemove: json.textIdsToRemove,
      });
    }
  
    static toJson(priceResponse: PriceResponse): any {
      return {
        articleId: priceResponse.articleId,
        title: priceResponse.title,
        hasZoxsCheck: priceResponse.hasZoxsCheck,
        price: priceResponse.price,
        decision: priceResponse.decision,
        actions: priceResponse.actions,
        text: priceResponse.text ? MailText.toJson(priceResponse.text) : null,
        textIdsToRemove: priceResponse.textIdsToRemove,
      };
    }
  }