import { DataFile } from "@/models/files/dataFile";
import { VideoFile } from "@/models/files/videoFile";
import { VoiceFile } from "@/models/files/voiceFile";

export async function processFiles(files) {
  const images = [];
  const videos = [];
  const audios = [];
  const others = [];

  for (const file of files) {
    const fileType = file.type.split('/')[0];

    switch (fileType) {
      case 'image':
        images.push(file);
        break;
      case 'video':
        const videoData = await getVideoMetadata(file);
        videos.push(new VideoFile({
          url: file,
          thumbnail: videoData.thumbnail,
          duration: videoData.duration,
          aspectRatio: videoData.aspectRatio,
        }));
        break;
      case 'audio':
        const audioData = await getAudioMetadata(file);
        audios.push(new VoiceFile({
          url: file,
          duration: audioData.duration,
        }));
        break;
      default:
        others.push(new DataFile({
          url: file,
          filename: file.name,
          size: file.size,
          filetype: file.type,
        }));
        break;
    }
  }

  return { images, videos, audios, others };
}

async function getVideoMetadata(file) {
  return new Promise((resolve) => {
    const video = document.createElement('video');
    video.src = URL.createObjectURL(file);

    video.onloadedmetadata = async () => {
      const aspectRatio = video.videoWidth / video.videoHeight;
      const filename = file.name ? file.name.split('.')[0] : `default-video-${Date.now()}`;

      const thumbnail = await generateVideoThumbnail(video, filename);

      const duration = video.duration * 1000;

      resolve({
        file,
        duration,
        aspectRatio: aspectRatio.toFixed(2),
        thumbnail,
      });
    };
  });
}

async function generateVideoThumbnail(video, filename) {
  return new Promise((resolve, reject) => {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;

    context.drawImage(video, 0, 0, canvas.width, canvas.height);

    canvas.toBlob((blob) => {
      if (blob) {
        const thumbnailFile = new File([blob], `${filename}-thumbnail.png`, {
          type: 'image/png',
          lastModified: Date.now(),
        });
        resolve(thumbnailFile);
      } else {
        reject(new Error("Failed to generate thumbnail"));
      }
    }, 'image/png');
  });
}

async function getAudioMetadata(file) {
  return new Promise((resolve) => {
    const audio = document.createElement('audio');
    audio.src = URL.createObjectURL(file);

    audio.onloadedmetadata = () => {
      const duration = audio.duration * 1000;
      resolve({
        duration,
      });
    };
  });
}

export async function fileToBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}