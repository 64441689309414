import { ref, uploadBytes, getDownloadURL, getStorage } from "firebase/storage";

export async function uploadFileToFirebase(file, folderPath) {
    try {
        const storage = getStorage();
        const filename = file.name ? file.name : `default-file-${Date.now()}`;
        const storageRef = ref(storage, `${folderPath}/${filename}`);
        const snapshot = await uploadBytes(storageRef, file);
        const downloadURL = await getDownloadURL(snapshot.ref);
        return downloadURL;
    } catch (error) {
        throw error;
    }
}
