import React, { Component } from 'react';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { 
      hasError: false,
      errorHtml: ''
    };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    fetch('/error.html')
      .then(response => response.text())
      .then(html => this.setState({ errorHtml: html }))
      .catch(err => {
        this.setState({ errorHtml: "<h1>An unexpected error occurred.</h1>" });
      });
  }

  render() {
    if (this.state.hasError) {
      return (
        <div
          dangerouslySetInnerHTML={{ __html: this.state.errorHtml }}
        />
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;