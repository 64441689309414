import React, { useState, useEffect, useRef } from 'react';
import { Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, Button, Alert } from '@heroui/react';
import { ControllerButton, ControllerTestResult, DEFAULT_CONTROLLER_BUTTONS } from '../models/controller';

interface ControllerTestModalProps {
    isOpen: boolean;
    onClose: () => void;
    onComplete: (result: ControllerTestResult) => void;
}

const ControllerTestModal: React.FC<ControllerTestModalProps> = ({ isOpen, onClose, onComplete }) => {
    const [buttons, setButtons] = useState<ControllerButton[]>(DEFAULT_CONTROLLER_BUTTONS);
    const [serialNumber, setSerialNumber] = useState<string | null>(null);
    const [isConnected, setIsConnected] = useState(false);
    const [currentGamepad, setCurrentGamepad] = useState<Gamepad | null>(null);
    const animationFrameRef = useRef<number>();
    const previousButtonStates = useRef<Record<string, boolean>>({});

    // Controller-Erkennung
    useEffect(() => {
        const handleGamepadConnected = (event: GamepadEvent) => {
            setIsConnected(true);
            setCurrentGamepad(event.gamepad);
            // Versuche die Seriennummer zu lesen (falls verfügbar)
            if (event.gamepad.id) {
                const snMatch = event.gamepad.id.match(/SN: ([A-Za-z0-9]+)/);
                if (snMatch) {
                    setSerialNumber(snMatch[1]);
                }
            }
        };

        const handleGamepadDisconnected = () => {
            setIsConnected(false);
            setCurrentGamepad(null);
        };

        window.addEventListener('gamepadconnected', handleGamepadConnected);
        window.addEventListener('gamepaddisconnected', handleGamepadDisconnected);

        // Initial Check
        const gamepads = navigator.getGamepads();
        const activeGamepad = Array.from(gamepads).find(gamepad => gamepad !== null);
        if (activeGamepad) {
            setIsConnected(true);
            setCurrentGamepad(activeGamepad);
        }

        return () => {
            window.removeEventListener('gamepadconnected', handleGamepadConnected);
            window.removeEventListener('gamepaddisconnected', handleGamepadDisconnected);
            if (animationFrameRef.current) {
                cancelAnimationFrame(animationFrameRef.current);
            }
        };
    }, []);

    // Controller-Input-Überwachung
    useEffect(() => {
        if (!isConnected || !isOpen) return;

        const checkGamepadState = () => {
            const gamepad = navigator.getGamepads()[currentGamepad?.index ?? 0];
            if (!gamepad) return;

            // Button-Mapping
            const buttonMapping: Record<number, string> = {
                0: 'button_a',      // A
                1: 'button_b',      // B
                2: 'button_x',      // X
                3: 'button_y',      // Y
                4: 'shoulder_l',    // LB
                5: 'shoulder_r',    // RB
                6: 'trigger_l',     // LT
                7: 'trigger_r',     // RT
                8: 'select',        // Select/Back/Share
                9: 'start',         // Start/Options
            };

            // D-Pad-Mapping (über Achsen)
            const axesMapping: Record<string, string> = {
                'up': 'dpad_up',
                'down': 'dpad_down',
                'left': 'dpad_left',
                'right': 'dpad_right'
            };

            // Prüfe Standard-Buttons
            gamepad.buttons.forEach((button, index) => {
                const buttonId = buttonMapping[index];
                if (buttonId) {
                    const isPressed = button.pressed;
                    const wasPressed = previousButtonStates.current[buttonId];

                    if (isPressed && !wasPressed) {
                        handleButtonTest(buttonId, false);
                    }
                    previousButtonStates.current[buttonId] = isPressed;
                }
            });

            // Prüfe D-Pad (Achsen)
            const [xAxis, yAxis] = gamepad.axes;
            const deadzone = 0.5; // Toleranzbereich

            if (Math.abs(yAxis) > deadzone) {
                const direction = yAxis < 0 ? 'up' : 'down';
                const buttonId = axesMapping[direction];
                const wasPressed = previousButtonStates.current[buttonId];
                if (!wasPressed) {
                    handleButtonTest(buttonId, false);
                }
                previousButtonStates.current[buttonId] = true;
            } else {
                previousButtonStates.current['dpad_up'] = false;
                previousButtonStates.current['dpad_down'] = false;
            }

            if (Math.abs(xAxis) > deadzone) {
                const direction = xAxis < 0 ? 'left' : 'right';
                const buttonId = axesMapping[direction];
                const wasPressed = previousButtonStates.current[buttonId];
                if (!wasPressed) {
                    handleButtonTest(buttonId, false);
                }
                previousButtonStates.current[buttonId] = true;
            } else {
                previousButtonStates.current['dpad_left'] = false;
                previousButtonStates.current['dpad_right'] = false;
            }

            animationFrameRef.current = requestAnimationFrame(checkGamepadState);
        };

        animationFrameRef.current = requestAnimationFrame(checkGamepadState);

        return () => {
            if (animationFrameRef.current) {
                cancelAnimationFrame(animationFrameRef.current);
            }
        };
    }, [isConnected, isOpen, currentGamepad]);

    const handleButtonTest = (buttonId: string, isDefect: boolean = false) => {
        setButtons(prevButtons => 
            prevButtons.map(button => 
                button.id === buttonId && !button.tested
                    ? { ...button, tested: true, isDefect }
                    : button
            )
        );
    };

    const isAllTested = buttons.every(button => button.tested);
    const hasDefects = buttons.some(button => button.isDefect);

    const handleComplete = () => {
        const result: ControllerTestResult = {
            serialNumber,
            buttons,
            isComplete: true,
            isDefect: hasDefects
        };
        onComplete(result);
        onClose();
    };

    return (
        <Modal isOpen={isOpen} onClose={onClose} size="2xl">
            <ModalContent>
                <ModalHeader>
                    <h3 className="text-lg font-semibold">Controller Funktionstest</h3>
                </ModalHeader>
                <ModalBody>
                    {!isConnected ? (
                        <Alert variant="solid" className="mb-4">
                            Bitte schließe den Controller per USB an.
                        </Alert>
                    ) : (
                        <>
                            <Alert variant="solid" className="mb-4 bg-success-50 text-success-600 border-success-200">
                                Controller verbunden{serialNumber ? ` (SN: ${serialNumber})` : ''}. 
                                Drücke alle Tasten, um sie zu testen.
                            </Alert>
                            <div className="grid grid-cols-2 gap-4 mb-4">
                                {buttons.map(button => (
                                    <div 
                                        key={button.id}
                                        className={`p-4 rounded-lg border ${
                                            !button.tested 
                                                ? 'bg-gray-100 border-gray-300'
                                                : button.isDefect
                                                    ? 'bg-primary-50 border-primary-500'
                                                    : 'bg-success-50 border-success-500'
                                        }`}
                                    >
                                        <div className="flex justify-between items-center">
                                            <span>{button.name}</span>
                                            {!button.tested ? (
                                                <div className="space-x-2">
                                                    <Button
                                                        size="sm"
                                                        variant="solid"
                                                        className="bg-success-500 text-white hover:bg-success-600"
                                                        onClick={() => handleButtonTest(button.id, false)}
                                                    >
                                                        OK
                                                    </Button>
                                                    <Button
                                                        size="sm"
                                                        variant="solid"
                                                        className="bg-primary-500 text-white hover:bg-primary-600"
                                                        onClick={() => handleButtonTest(button.id, true)}
                                                    >
                                                        Defekt
                                                    </Button>
                                                </div>
                                            ) : (
                                                <span className={button.isDefect ? 'text-primary-500' : 'text-success-500'}>
                                                    {button.isDefect ? 'Defekt' : 'OK'}
                                                </span>
                                            )}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </>
                    )}
                </ModalBody>
                <ModalFooter>
                    {isAllTested ? (
                        <Button
                            variant="solid"
                            className={hasDefects ? 'bg-primary-500 text-white hover:bg-primary-600' : 'bg-success-500 text-white hover:bg-success-600'}
                            onClick={handleComplete}
                        >
                            {hasDefects ? 'Defekt melden' : 'Funktioniert!'}
                        </Button>
                    ) : (
                        <Button variant="ghost" onClick={onClose}>
                            Abbrechen
                        </Button>
                    )}
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};

export default ControllerTestModal; 