export class EbayRequest {
    speedUploadId: number | null;
    sn_imei: string | null;
    ean: string | null;
    epid: string | null;
    category_id: string | null;
    firebaseId: string | null;

    constructor(
        speedUploadId: number | null = null,
        sn_imei: string | null = null,
        ean: string | null = null,
        epid: string | null = null,
        category_id: string | null = null,
        firebaseId: string | null = null
    ) {
        this.speedUploadId = speedUploadId;
        this.sn_imei = sn_imei;
        this.ean = ean;
        this.epid = epid;
        this.category_id = category_id;
        this.firebaseId = firebaseId;
    }

    static fromJson(json: any): EbayRequest {
        return new EbayRequest(
            json.speedUploadId || null,
            json.sn_imei || null,
            json.ean || null,
            json.epid || null,
            json.category_id || null,
            json.firebaseId || null
        );
    }

    toJson(): Record<string, string | number | null> {
        return {
            speedUploadId: this.speedUploadId,
            sn_imei: this.sn_imei,
            ean: this.ean,  
            epid: this.epid,
            category_id: this.category_id,
            firebaseId: this.firebaseId
        };
    }
}
