export class ArticleCheckDeliveryScope {
    id: number;
    name: string;
    short: string;
  
    constructor(init: Partial<ArticleCheckDeliveryScope>) {
      Object.assign(this, init);
    }
  
    static fromJson(json: any): ArticleCheckDeliveryScope {
      return new ArticleCheckDeliveryScope({
        id: json.id,
        name: json.name,
        short: json.short,
      });
    }
  
    static toJson(scope: ArticleCheckDeliveryScope): any {
      return {
        id: scope.id,
        name: scope.name,
        short: scope.short,
      };
    }
  }