'use client';

import { Button, Divider, addToast } from '@heroui/react';
import { 
    Bold, 
    Italic, 
    List, 
    ListOrdered, 
    AlignLeft, 
    AlignCenter, 
    AlignRight,
    Undo,
    Redo,
    Sparkles
} from 'lucide-react';
import { useState } from 'react';
import { Spinner } from '@heroui/react';
import { OpenAiService } from '../api/openAiService';

interface EditorMenuBarProps {
    editor: any;
    articleTitle?: string;
    articleConditionOrigin?: string;
    articleConditionNew?: string;
    photos?: string[];
}

//TODO: ADD TEXT MODAL

export default function EditorMenuBar({ editor, articleTitle, articleConditionOrigin, articleConditionNew, photos }: EditorMenuBarProps) {
    const [isGenerating, setIsGenerating] = useState(false);

    if (!editor) {
        return null;
    }

    const handleAiGenerate = async () => {
        try {
            const currentText = editor.getHTML();
            
            if (!currentText || currentText.length < 50) {
                addToast({
                    title: "Text zu kurz",
                    description: "Der Text muss mindestens 50 Zeichen lang sein.",
                    color: "primary",
                    timeout: 3000,
                    shouldShowTimeoutProgress: true,
                });
                return;
            }

            setIsGenerating(true);
            const openAiService = new OpenAiService();
            
            const response = await openAiService.generateArticleText({
                articleTitle: articleTitle || '',
                articleConditionOrigin: articleConditionOrigin || '',
                articleConditionNew: articleConditionNew || '',
                legacyText: currentText,
                images: photos || []
            });

            const generatedText = response?.choices?.[0]?.message?.content;
            if (generatedText) {
                editor.commands.setContent(generatedText);
                addToast({
                    title: "Text wurde überarbeitet",
                    description: "Der Text wurde erfolgreich durch KI optimiert.",
                    color: "success",
                    timeout: 3000,
                    shouldShowTimeoutProgress: true,
                });
            } else {
                throw new Error('Keine Textgenerierung von OpenAI erhalten');
            }
        } catch (error) {
            console.error('Fehler bei der KI-Textgenerierung:', error);
            addToast({
                title: "Fehler",
                description: "Der Text konnte nicht durch KI überarbeitet werden.",
                color: "primary",
                timeout: 3000,
                shouldShowTimeoutProgress: true,
            });
        } finally {
            setIsGenerating(false);
        }
    };

    return (
        <div className="border-b p-2 flex flex-wrap gap-2 items-center">
            <Button
                isIconOnly
                size="sm"
                variant="light"
                onPress={() => editor.chain().focus().toggleBold().run()}
                isDisabled={!editor.can().chain().focus().toggleBold().run()}
                className={editor.isActive('bold') ? 'bg-primary-100' : ''}
            >
                <Bold className="w-4 h-4" />
            </Button>
            <Button
                isIconOnly
                size="sm"
                variant="light"
                onPress={() => editor.chain().focus().toggleItalic().run()}
                isDisabled={!editor.can().chain().focus().toggleItalic().run()}
                className={editor.isActive('italic') ? 'bg-primary-100' : ''}
            >
                <Italic className="w-4 h-4" />
            </Button>
            <Button
                isIconOnly
                size="sm"
                variant="light"
                onPress={() => editor.chain().focus().toggleBulletList().run()}
                isDisabled={!editor.can().chain().focus().toggleBulletList().run()}
                className={editor.isActive('bulletList') ? 'bg-primary-100' : ''}
            >
                <List className="w-4 h-4" />
            </Button>
            <Button
                isIconOnly
                size="sm"
                variant="light"
                onPress={() => editor.chain().focus().toggleOrderedList().run()}
                isDisabled={!editor.can().chain().focus().toggleOrderedList().run()}
                className={editor.isActive('orderedList') ? 'bg-primary-100' : ''}
            >
                <ListOrdered className="w-4 h-4" />
            </Button>
            <Button
                isIconOnly
                size="sm"
                variant="light"
                onPress={() => editor.chain().focus().setTextAlign('left').run()}
                isDisabled={!editor.can().chain().focus().setTextAlign('left').run()}
                className={editor.isActive({ textAlign: 'left' }) ? 'bg-primary-100' : ''}
            >
                <AlignLeft className="w-4 h-4" />
            </Button>
            <Button
                isIconOnly
                size="sm"
                variant="light"
                onPress={() => editor.chain().focus().setTextAlign('center').run()}
                isDisabled={!editor.can().chain().focus().setTextAlign('center').run()}
                className={editor.isActive({ textAlign: 'center' }) ? 'bg-primary-100' : ''}
            >
                <AlignCenter className="w-4 h-4" />
            </Button>
            <Button
                isIconOnly
                size="sm"
                variant="light"
                onPress={() => editor.chain().focus().setTextAlign('right').run()}
                isDisabled={!editor.can().chain().focus().setTextAlign('right').run()}
                className={editor.isActive({ textAlign: 'right' }) ? 'bg-primary-100' : ''}
            >
                <AlignRight className="w-4 h-4" />
            </Button>
            <Button
                isIconOnly
                size="sm"
                variant="light"
                onPress={() => editor.chain().focus().undo().run()}
                isDisabled={!editor.can().chain().focus().undo().run()}
            >
                <Undo className="w-4 h-4" />
            </Button>
            <Button
                isIconOnly
                size="sm"
                variant="light"
                onPress={() => editor.chain().focus().redo().run()}
                isDisabled={!editor.can().chain().focus().redo().run()}
            >
                <Redo className="w-4 h-4" />
            </Button>

            <Divider orientation="vertical" className="h-8" />

            <Button
                isIconOnly
                size="sm"
                variant="light"
                onPress={handleAiGenerate}
                isDisabled={isGenerating}
                className="relative"
            >
                {isGenerating ? (
                    <Spinner size="sm" />
                ) : (
                    <Sparkles className="w-4 h-4" />
                )}
            </Button>
        </div>
    );
} 