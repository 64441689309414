import {
    Drawer,
    DrawerContent,
    DrawerHeader,
    DrawerBody,
    DrawerFooter,
    Button,
    Tooltip,
    Spinner,
    Input,
    Select,
    SelectItem,
    ScrollShadow,
    Textarea,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownTrigger,
    Autocomplete,
    AutocompleteItem,
    AutocompleteSection,
} from "@heroui/react";
import { Icon } from "@iconify/react/dist/iconify.js";
import { useCallback, useEffect, useState } from "react";
import React from "react";
import { useDropzone } from "react-dropzone";
import { AppFile } from "../models/file";
import { CategoryModel } from "../models/category";
import articleService from "../api/articleService";

export const types = [
    { key: "addArticle", label: "Artikelaufahme", showCategory: true, fields: ["asin", "ean", "mod", "title"] },
    { key: "blacklistArticle", label: "Artikel blacklisten", showCategory: false, fields: [] },
    { key: "addEan", label: "EAN hinzufügen", showCategory: false, fields: ["ean"] },
    { key: "changeCategory", label: "Kategorie ändern", showCategory: true, fields: [] },
    { key: "changeModel", label: "Modell ändern", showCategory: false, fields: ["mod"] },
    { key: "wrongPrice", label: "Preisfehler", showCategory: false, fields: [] },
    { key: "changeTitle", label: "Titel anpassen", showCategory: false, fields: ["title"] },
    { key: "misc", label: "Sonstiges", showCategory: false, fields: [] },
];

// TODO: REMOVE DUMMY
export const categories = [
    { key: "1", label: "Handy/ Smartphone" },
    { key: "2", label: "Tablets" },
    { key: "3", label: "Konsolen" },
];

export default function ImporvementDrawer({ isOpen, onOpenChange, improvementId }) {
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingCategories, setIsLoadingCategories] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [improvement, setImprovement] = useState(null); // TODO: DEFINE IMPROVEMENT MODEL
    const [sending, setSending] = useState(false);
    const [type, setType] = useState("addArticle");
    const [cat, setCat] = useState<string | null>(null);
    const [categories, setCategories] = useState<CategoryModel[]>([]);
    const [asin, setAsin] = useState("");
    const [ean, setEan] = useState("");
    const [mod, setMod] = useState("");
    const [title, setTitle] = useState("");
    const [text, setText] = useState("");

    // FILE UPLOAD
    const [uploadedFiles, setUploadedFiles] = useState<AppFile[]>([]);

    // Lade Kategorien beim Öffnen des Drawers
    useEffect(() => {
        if (isOpen) {
            loadCategories();
        }
    }, [isOpen]);

    const loadCategories = async () => {
        try {
            setIsLoadingCategories(true);
            const fetchedCategories = await articleService.getCategories();
            setCategories(fetchedCategories);
        } catch (error: any) {
            setError(error.message);
        } finally {
            setIsLoadingCategories(false);
        }
    };

    // Funktion zum Abflachen der Kategorien mit Level-Information
    const flattenCategories = (categories: CategoryModel[], level = 0): Array<{ category: CategoryModel; level: number }> => {
        return categories.reduce((acc: Array<{ category: CategoryModel; level: number }>, category: CategoryModel) => {
            acc.push({ category, level });
            if (category.subCategories && category.subCategories.length > 0) {
                acc.push(...flattenCategories(category.subCategories, level + 1));
            }
            return acc;
        }, []);
    };

    const renderCategoryItem = ({ category, level }: { category: CategoryModel; level: number }) => {
        const isLeafCategory = !category.subCategories || category.subCategories.length === 0;
        const isMainCategory = level === 0;

        return (
            <AutocompleteItem
                key={category.id.toString()}
                className={`py-2 ${!isLeafCategory ? 'cursor-default' : ''}`}
                style={{ 
                    paddingLeft: `${level * 16}px`,
                    fontWeight: isMainCategory ? 'bold' : 'normal',
                }}
                textValue={category.title}
                isDisabled={!isLeafCategory}
            >
                {category.title}
            </AutocompleteItem>
        );
    };

    const onDrop = useCallback((acceptedFiles: File[]) => {
        const filteredFiles = acceptedFiles.filter(file => file.size <= 50 * 1024 * 1024);
        const uniqueFiles = filteredFiles.filter((file) =>
            !uploadedFiles.some((uploadedFile) => uploadedFile.name === file.name)
        );
        setUploadedFiles((prev) => [...prev, ...uniqueFiles]);
    }, [uploadedFiles]);

    const handleRemoveFile = (fileName: string) => {
        setUploadedFiles((prev) => prev.filter((file) => file.name !== fileName));
    };

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        accept: {
            'image/*': [],
            'application/pdf': [],
            'text/*': [],
            'application/msword': [],
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document': []
        },
        maxSize: 50 * 1024 * 1024
    });

    // TODO: FETCH IMPROVEMENT IF ID AVAILABLE

    // TODO: VALIDATION, FILE UPLOAD AND REQUEST

    return (
        <Drawer
            hideCloseButton
            backdrop="blur"
            classNames={{
                base: "data-[placement=right]:sm:m-2 data-[placement=left]:sm:m-2 rounded-medium",
            }}
            isOpen={isOpen}
            onOpenChange={onOpenChange}
        >
            <DrawerContent>
                <div className="flex flex-col h-full">
                    <DrawerHeader className="absolute top-0 inset-x-0 z-50 flex flex-row gap-2 px-2 py-2 border-b border-default-200/50 justify-between bg-content1/50 backdrop-saturate-150 backdrop-blur-lg">
                        <Tooltip content="Schließen">
                            <Button
                                isIconOnly
                                className="text-default-400"
                                size="sm"
                                variant="light"
                                onPress={onOpenChange}
                            >
                                <svg
                                    fill="none"
                                    height="20"
                                    stroke="currentColor"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    viewBox="0 0 24 24"
                                    width="20"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path d="m13 17 5-5-5-5M6 17l5-5-5-5" />
                                </svg>
                            </Button>
                        </Tooltip>
                    </DrawerHeader>

                    <DrawerBody className="px-0">
                        <ScrollShadow>
                            <div className="pt-16 px-6 space-y-2">
                                <Select
                                    fullWidth
                                    disallowEmptySelection
                                    label="Typ"
                                    variant="bordered"
                                    size="sm"
                                    placeholder="Grund / Anliegen"
                                    selectedKeys={[type]}
                                    onChange={(e) => setType(e.target.value)}
                                >
                                    {types.map((type) => (
                                        <SelectItem key={type.key}>{type.label}</SelectItem>
                                    ))}
                                </Select>
                                {types.find(t => t.key === type)?.showCategory && (
                                    <Autocomplete
                                        fullWidth
                                        isVirtualized
                                        label="Kategorie"
                                        variant="bordered"
                                        size="sm"
                                        placeholder="Kategorie"
                                        selectedKey={cat}
                                        listboxProps={{
                                            emptyContent: isLoadingCategories ? (
                                                <div className="flex justify-center p-4">
                                                    <Spinner size="sm" />
                                                </div>
                                            ) : "Keine Kategorie gefunden.",
                                        }}
                                        onSelectionChange={(e) => setCat(e?.toString() ?? null)}
                                    >
                                        {flattenCategories(categories).map(renderCategoryItem)}
                                    </Autocomplete>
                                )}
                                {types.find(t => t.key === type)?.fields?.includes("title") && (
                                    <Input
                                        fullWidth
                                        label="Titel"
                                        placeholder="Artikeltitel"
                                        variant="bordered"
                                        size="sm"
                                        value={title}
                                        onChange={(e) => setTitle(e.target.value)}
                                    />
                                )}
                                {types.find(t => t.key === type)?.fields?.includes("mod") && (
                                    <Input
                                        fullWidth
                                        label="Modell"
                                        placeholder="Modellnummer"
                                        variant="bordered"
                                        size="sm"
                                        value={mod}
                                        onChange={(e) => setMod(e.target.value)}
                                    />
                                )}
                                {types.find(t => t.key === type)?.fields?.includes("ean") && (
                                    <Input
                                        fullWidth
                                        label="EAN"
                                        placeholder="EANs"
                                        variant="bordered"
                                        size="sm"
                                        value={ean}
                                        onChange={(e) => setEan(e.target.value)}
                                    />
                                )}
                                {types.find(t => t.key === type)?.fields?.includes("asin") && (
                                    <Input
                                        fullWidth
                                        label="ASIN"
                                        placeholder="ASIN"
                                        variant="bordered"
                                        size="sm"
                                        value={asin}
                                        onChange={(e) => setAsin(e.target.value)}
                                    />
                                )}
                                <Textarea
                                    isClearable
                                    defaultValue={text}
                                    label="Sonsties"
                                    placeholder="Weitere Details (z.B. Amazon-Link)"
                                    variant="bordered"
                                    onClear={() => console.log("textarea cleared")}
                                    onChange={(e) => setText(e.target.value)}
                                />
                                <div className="flex gap-2 items-center min-w-full">
                                    <div {...getRootProps({ className: 'dropzone' })} className="border-dashed border-2 border-gray-300 p-4 rounded-lg text-center cursor-pointer w-full">
                                        <input {...getInputProps()} />
                                        <p className="text-small text-gray-500">
                                            Dateien reinziehen oder per Klick hochladen
                                        </p>
                                        <p className="text-xs text-gray-400">
                                            (Alle Dateitypen erlaubt, max. 50 MB pro Datei)
                                        </p>
                                    </div>
                                    <Dropdown backdrop="blur" showArrow>
                                        <DropdownTrigger>
                                            <Button isIconOnly
                                                radius="full"
                                                size="sm"
                                                variant="bordered"
                                            >
                                                <Icon
                                                    className="text-default-500"
                                                    icon="solar:camera-outline"
                                                    width={20}
                                                />
                                            </Button>
                                        </DropdownTrigger>
                                        <DropdownMenu>
                                            <DropdownItem
                                                key="camera"
                                                description="Neue Fotos aufnehmen"
                                                startContent={<Icon
                                                    className="text-default-500"
                                                    icon="solar:camera-outline"
                                                    width={24}
                                                />}
                                            >
                                                Kamera
                                            </DropdownItem>
                                            <DropdownItem
                                                key="gallery"
                                                description="Bestehende Fotos wählen"
                                                startContent={<Icon
                                                    className="text-default-500"
                                                    icon="solar:gallery-wide-outline"
                                                    width={24}
                                                />}
                                            >
                                                Galerie
                                            </DropdownItem>
                                        </DropdownMenu>
                                    </Dropdown>
                                </div>
                                {uploadedFiles.length > 0 && (
                                    <div className="mt-4">
                                        <h3 className="text-small text-default-500 mb-2">Anhänge:</h3>
                                        <ul>
                                            {uploadedFiles.map((file, index) => (
                                                <li key={index} className="flex justify-between items-center text-small text-default-400">
                                                    {file.name} ({(file.size / 1024).toFixed(2)} KB)
                                                    <Icon
                                                        className="text-default-400 cursor-pointer"
                                                        icon="mdi:close"
                                                        width={20}
                                                        height={20}
                                                        onClick={() => handleRemoveFile(file.name)}
                                                    />
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                )}
                                {isLoading && <Spinner className="mt-2" />}
                                {error && <p className="text-red-500 mt-2">{error}</p>}
                            </div>
                        </ScrollShadow>
                    </DrawerBody>

                    <DrawerFooter>
                        <Button color="primary" fullWidth>
                            Verbesserungsvorschlag senden
                        </Button>
                    </DrawerFooter>
                </div>
            </DrawerContent>
        </Drawer>
    );
}

