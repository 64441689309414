import React, { useEffect, useRef, useState } from "react";
import {
    Modal,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Button,
    useDraggable,
} from "@heroui/react";
import { PinturaEditor } from '@pqina/react-pintura';
import { getEditorDefaults } from '@pqina/pintura';
import '@pqina/pintura/pintura.css';

interface PhotoEditorModalProps {
    isOpen: boolean;
    onOpenChange: () => void;
    img: string;
    photoId: string;
    onSave: (photoId: string, editedUrl: string) => void;
    onCancel: () => void;
}

const editorDefaults = getEditorDefaults({
    cropSelectPresetFilter: 'landscape',
});

const downloadFile = (file: Blob) => {
    const link = document.createElement('a');
    link.style.display = 'none';
    link.href = URL.createObjectURL(file);
    link.download = 'edited-image.jpg';

    document.body.appendChild(link);
    link.click();

    setTimeout(() => {
        URL.revokeObjectURL(link.href);
        link.parentNode?.removeChild(link);
    }, 0);
};

export default function PhotoEditorModal({ isOpen, onOpenChange, img, photoId, onSave, onCancel }: PhotoEditorModalProps) {
    const targetRef = React.useRef(null);
    const { moveProps } = useDraggable({ targetRef, isDisabled: !isOpen });
    const editorRef = useRef<any>(null);
    const [processedImage, setProcessedImage] = useState<string | null>(null);

    const handleSave = async () => {
        if (editorRef.current?.editor) {
            try {
                const result = await editorRef.current.editor.processImage();
                const url = URL.createObjectURL(result.dest);
                console.log('Processing image for photo:', photoId, 'URL:', url);
                setProcessedImage(url);
                onSave(photoId, url);
                //downloadFile(result.dest);
                onOpenChange();
            } catch (error) {
                console.error('Fehler beim Speichern des Bildes:', error);
            }
        }
    };

    return (
        <Modal ref={targetRef} isOpen={isOpen} onOpenChange={onOpenChange} backdrop="blur" isDismissable={false} size="5xl">
            <ModalContent>
                {(onClose) => (
                    <>
                        <ModalHeader {...moveProps}>Fotoeditor</ModalHeader>
                        <ModalBody>
                            <div style={{ height: "70vh" }}>
                                <PinturaEditor
                                    ref={editorRef}
                                    {...editorDefaults}
                                    src={img}
                                    onLoad={(res) => console.log("load image", res)}
                                    utils={[
                                        'crop',
                                        'annotate',
                                        'redact',
                                    ]}
                                    enableButtonExport={false}
                                />
                            </div>
                        </ModalBody>
                        <ModalFooter className="justify-end gap-2">
                            <Button color="primary" variant="light" onPress={onCancel}>
                                Abbrechen
                            </Button>
                            <Button 
                                color="primary" 
                                onPress={handleSave}
                            >
                                Speichern
                            </Button>
                        </ModalFooter>
                    </>
                )}
            </ModalContent>
        </Modal>
    );
}