import useToast from '@/hooks/useToast';
import { Icon } from '@iconify/react/dist/iconify.js';
import { getLocalTimeZone, parseAbsoluteToLocal, parseDate, today } from '@internationalized/date';
import { Autocomplete, AutocompleteItem, Button, Card, CardBody, Checkbox, DateRangePicker, Divider, Input, Spacer, Spinner, Textarea, Tooltip } from "@heroui/react";
import { I18nProvider } from '@react-aria/i18n';
import React, { useEffect, useState } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import JsonView from 'react18-json-view'
import 'react18-json-view/src/style.css'
import { Timestamp } from 'firebase/firestore';
import AppPromoImageUpload from '@/components/AppPromoImageUpload';
import zoxsManagementService from '@/services/zoxsManagementService';
import { fileToBase64 } from '@/helpers/fileHelper';

const AppPromotionEditor = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const id = params.get('id');
  const isEditing = location.pathname === '/admin-promotion/edit';

  const { showToast } = useToast();

  const [loading, setLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [isProMode, setIsProMode] = useState(false);
  const [showInfo, setShowInfo] = useState(false);
  const [uploadedImage, setUploadedImage] = useState(null);

  const [formData, setFormData] = useState(() => {
    const today = new Date();
    return {
      imgUrl: null,
      type: null,
      route: '',
      args: '',
      url: '',
      infoTitle: '',
      infoDesc: '',
      cupon: '',
      disclaimer: '',
      time: {
        start: parseAbsoluteToLocal(today.toISOString()),
        end: parseAbsoluteToLocal(today.toISOString()),
      },
    };
  });

  const [errors, setErrors] = useState({
    type: false,
    time: false,
    route: false,
    args: false,
    url: false,
    infoTitle: false,
    infoDesc: false,
    disclaimer: false,
  });

  const [stats, setStats] = useState({
    clicks: 0,
    uniqueClicks: 0,
  });


  useEffect(() => {
    const initialize = async () => {
      setLoading(true);
      try {
        if (isEditing && id) {
          await fetchPromotion(id);
        }
      } catch (error) {
        showToast('Fehler beim Laden der Promotion.', 'error');
      } finally {
        setLoading(false);
      }
    };
    initialize();
  }, [isEditing, id]);

  const fetchPromotion = async (id) => {
    try {
      const response = await zoxsManagementService.getPromotionById(id);
      if (response && response.element) {
        const {
          type,
          start,
          end,
          route,
          args,
          imgUrl,
          clickCount,
          clickCountUnique,
          infoBox,
        } = response.element;

        setFormData({
          ...formData,
          type,
          route,
          args: args ? JSON.stringify(args) : '',
          imgUrl,
          time: {
            start: parseAbsoluteToLocal(new Date(start).toISOString()),
            end: parseAbsoluteToLocal(new Date(end).toISOString()),
          },
          infoTitle: infoBox?.title || '',
          infoDesc: infoBox?.desc || '',
          cupon: infoBox?.voucher || '',
          disclaimer: infoBox?.disclaimer || '',
        });

        setUploadedImage(imgUrl);
        setIsProMode(true);
        setShowInfo(!!infoBox);

        setStats({
          clicks: clickCount?.length ?? 0,
          uniqueClicks: Object.keys(clickCountUnique ?? {}).length,
        });
      } else {
        showToast('Promotion konnte nicht geladen werden.', 'error');
      }
    } catch (error) {
      showToast('Ein Fehler ist aufgetreten: ' + error.message, 'error');
    }
  };


  const handleInputChange = (field, value) => {
    setFormData((prev) => ({ ...prev, [field]: value }));
  };

  const handleImageChange = (file) => {
    setUploadedImage(file);
  };

  const handleNestedInputChange = (category, field, value) => {
    setFormData((prev) => ({
      ...prev,
      [category]: { ...prev[category], [field]: value },
    }));
  };

  const handleErrorChange = (field, value) => {
    setErrors((prev) => ({ ...prev, [field]: value }));
  };


  const generateJson = () => {
    const startDate = new Date(
      formData.time.start.year,
      formData.time.start.month - 1,
      formData.time.start.day,
      formData.time.start.hour,
      formData.time.start.minute,
      formData.time.start.second,
      formData.time.start.millisecond
    );

    const endDate = new Date(
      formData.time.end.year,
      formData.time.end.month - 1,
      formData.time.end.day,
      formData.time.end.hour,
      formData.time.end.minute,
      formData.time.end.second,
      formData.time.end.millisecond
    );

    const adjustedRoute = !isProMode ? '/customTab' : formData.route || null;
    let adjustedArgs;
    if (isProMode) {
      try {
        adjustedArgs = JSON.parse(formData.args) || { url: formData.url };
      } catch (error) {
        adjustedArgs = {};
      }
    } else {
      adjustedArgs = { url: formData.url };
    }

    return {
      id: id || null,
      type: formData.type || null,
      start: startDate,
      end: endDate,
      route: adjustedRoute,
      args: JSON.stringify(adjustedArgs),
      infoBox: showInfo
        ? {
          title: formData.infoTitle || null,
          desc: formData.infoDesc || null,
          voucher: formData.cupon || null,
          disclaimer: formData.disclaimer || null,
        }
        : null,
    };
  };

  const handleSubmit = async () => {
    let valid = true;
    const now = new Date();
    const startDate = new Date(Date.UTC(
      formData.time.start.year,
      formData.time.start.month - 1,
      formData.time.start.day,
      formData.time.start.hour,
      formData.time.start.minute,
      formData.time.start.second,
      formData.time.start.millisecond
    ));

    const endDate = new Date(Date.UTC(
      formData.time.end.year,
      formData.time.end.month - 1,
      formData.time.end.day,
      formData.time.end.hour,
      formData.time.end.minute,
      formData.time.end.second,
      formData.time.end.millisecond
    ));

    if (!uploadedImage) {
      showToast('Bitte lade ein Bild hoch.', 'error');
      valid = false;
    }

    if (!formData.type) {
      handleErrorChange('type', true);
      valid = false;
    }

    if (endDate < now || endDate < startDate) {
      handleErrorChange('time', true);
      valid = false;
    }

    if (!isProMode) {
      try {
        new URL(formData.url);
      } catch (_) {
        handleErrorChange('url', true);
        valid = false;
      }
    }

    if (showInfo) {
      if (!formData.infoTitle) {
        handleErrorChange('infoTitle', true);
        valid = false;
      }

      if (!formData.infoDesc) {
        handleErrorChange('infoDesc', true);
        valid = false;
      }

      if (formData.cupon && !formData.disclaimer) {
        handleErrorChange('disclaimer', true);
        valid = false;
      }
    }

    if (!valid) return;

    const jsonBody = generateJson();

    try {
      setIsSaving(true);
      if (isEditing) {
        await zoxsManagementService.updatePromotion(id, { promotionData: jsonBody });
        showToast('Promotion erfolgreich bearbeitet.', 'success');
      } else {
        const base64Image = uploadedImage ? await fileToBase64(uploadedImage) : null;
        await zoxsManagementService.createPromotion({ img: base64Image, promotionData: jsonBody });
        showToast('Promotion erfolgreich erstellt.', 'success');
      }
      navigate('/admin-promotion');
    } catch (error) {
      showToast('Ein Fehler ist aufgetreten: ' + error.message, 'error');
    } finally {
      setIsSaving(false);
    }
  };

  const handleClose = () => {
    navigate('/admin-promotion');
  };

  return (
    <div className="w-full flex-1 p-4">
      <Icon
        className="text-default-400 absolute top-4 right-4 cursor-pointer z-50"
        icon="solar:close-square-bold"
        width={28}
        height={28}
        onClick={handleClose}
      />

      <div className="flex items-center gap-x-3">
        <h1 className="text-3xl font-bold leading-9 text-default-foreground">
          {isEditing ? 'Promotion bearbeiten' : 'Promotion erstellen'}
        </h1>
      </div>
      <h2 className="mt-2 text-small text-default-500 mb-6">
        Erstelle oder bearbeite eine Promotion.
      </h2>

      {loading ? (
        <Spinner />
      ) : (
        <>
          <div className="mt-4 flex flex-col lg:flex-row gap-3">
            <div className="w-full lg:w-2/3">
              <Card className="rounded-lg border bg-background shadow p-2">
                <CardBody>
                  <div className="flex justify-between items-center">
                    <Autocomplete
                      variant="bordered"
                      label="Typ"
                      isDisabled={isEditing}
                      isLoading={loading}
                      size='md'
                      placeholder='Bitte auswählen'
                      isInvalid={errors.type}
                      selectedKey={formData.type}
                      allowsEmptyCollection={false}
                      isClearable={false}
                      startContent={
                        <Icon
                          className="text-default-400 pointer-events-none"
                          height={18}
                          icon="solar:layers-minimalistic-outline"
                          width={18}
                        />
                      }
                      onSelectionChange={(e) => {
                        handleInputChange("type", e);
                        handleErrorChange("type", false)
                      }}
                      listboxProps={{
                        emptyContent: 'Keine Typen.'
                      }}
                    >
                      <AutocompleteItem key="zoxs">ZOXS</AutocompleteItem>
                      <AutocompleteItem key="buyzoxs">buyZOXS</AutocompleteItem>
                    </Autocomplete>
                    <Spacer x={2} />
                    <I18nProvider locale="de">
                      <DateRangePicker
                        label="Zeitraum"
                        value={formData.time}
                        variant='bordered'
                        onChange={(e) => {
                          setFormData((prev) => ({
                            ...prev,
                            time: {
                              start: e.start,
                              end: e.end,
                            }
                          }));
                          handleErrorChange("time", false)
                        }
                        }
                        visibleMonths={3}
                        isInvalid={errors.time}
                        startContent={
                          <Icon
                            className="text-default-400 pointer-events-none"
                            height={18}
                            icon="solar:calendar-date-outline"
                            width={18}
                          />
                        }
                      />
                    </I18nProvider>
                  </div>
                  <Spacer y={2} />
                  <div className="flex items-center gap-4">
                    <Checkbox isSelected={isProMode} onValueChange={setIsProMode} className="flex-shrink-0">
                      Ziel manuell setzen
                    </Checkbox>
                    <div className="flex items-center w-full">
                      {!isProMode ? (
                        <Input
                          variant="bordered"
                          type="text"
                          size="md"
                          label="URL"
                          value={formData.url}
                          labelPlacement="inside"
                          isInvalid={errors.url}
                          startContent={
                            <Icon
                              className="text-default-400 pointer-events-none"
                              height={18}
                              icon="solar:routing-2-outline"
                              width={18}
                            />
                          }
                          onChange={(e) => {
                            handleInputChange("url", e.target.value);
                            handleErrorChange("url", false);
                          }}
                        />
                      ) : (
                        <>
                          <Input
                            variant="bordered"
                            type="text"
                            size="md"
                            label="Route"
                            value={formData.route}
                            labelPlacement="inside"
                            isInvalid={errors.route}
                            startContent={
                              <Icon
                                className="text-default-400 pointer-events-none"
                                height={18}
                                icon="solar:routing-2-outline"
                                width={18}
                              />
                            }
                            onChange={(e) => {
                              handleInputChange("route", e.target.value);
                              handleErrorChange("route", false);
                            }}
                          />
                          <Spacer x={2} />
                          <Input
                            variant="bordered"
                            type="text"
                            size="md"
                            label="Arguments (JSON)"
                            value={formData.args}
                            labelPlacement="inside"
                            isInvalid={errors.args}
                            startContent={
                              <Icon
                                className="text-default-400 pointer-events-none"
                                height={18}
                                icon="solar:code-bold"
                                width={18}
                              />
                            }
                            onChange={(e) => {
                              handleInputChange("args", e.target.value);
                              handleErrorChange("args", false);
                            }}
                          />
                        </>
                      )}
                    </div>
                  </div>
                  <Spacer y={8} />
                  <Checkbox isSelected={showInfo} onValueChange={setShowInfo} className="flex-shrink-0">
                    Infobox beim Tippen öffnen (erscheint vor Weiterleitung auf Klickziel)
                  </Checkbox>
                  {showInfo &&
                    <div>
                      <Spacer y={2} />
                      <div className='flex items-center w-full'>
                        <Input
                          variant="bordered"
                          type="text"
                          size="md"
                          label="Titel"
                          value={formData.infoTitle}
                          labelPlacement="inside"
                          isInvalid={errors.infoTitle}
                          startContent={
                            <Icon
                              className="text-default-400 pointer-events-none"
                              height={18}
                              icon="solar:text-outline"
                              width={18}
                            />
                          }
                          onChange={(e) => {
                            handleInputChange("infoTitle", e.target.value);
                            handleErrorChange("infoTitle", false);
                          }}
                        />
                        <Spacer x={2} />
                        <Input
                          variant="bordered"
                          type="text"
                          size="md"
                          label="ggf. Gutscheincode"
                          value={formData.cupon}
                          labelPlacement="inside"
                          startContent={
                            <Icon
                              className="text-default-400 pointer-events-none"
                              height={18}
                              icon="solar:ticket-sale-outline"
                              width={18}
                            />
                          }
                          onChange={(e) => {
                            handleInputChange("cupon", e.target.value);
                          }}
                        />
                      </div>
                      <Spacer y={2} />
                      <Textarea
                        variant="bordered"
                        type="text"
                        size='md'
                        label="Beschreibung"
                        value={formData.infoDesc}
                        labelPlacement="inside"
                        isInvalid={errors.infoDesc}
                        startContent={
                          <Icon
                            className="text-default-400 pointer-events-none"
                            height={18}
                            icon="solar:document-text-outline"
                            width={18}
                          />
                        }
                        onChange={(e) => {
                          handleInputChange("infoDesc", e.target.value);
                          handleErrorChange("infoDesc", false);
                        }}
                      />
                      <Spacer y={2} />
                      <Input
                        variant="bordered"
                        type="text"
                        size="md"
                        label="ggf. Disclaimer"
                        value={formData.disclaimer}
                        labelPlacement="inside"
                        isInvalid={errors.disclaimer}
                        startContent={
                          <Icon
                            className="text-default-400 pointer-events-none"
                            height={18}
                            icon="solar:info-circle-outline"
                            width={18}
                          />
                        }
                        onChange={(e) => {
                          handleInputChange("disclaimer", e.target.value);
                          handleErrorChange("disclaimer", false);
                        }}
                      />
                    </div>
                  }
                </CardBody>
              </Card>
            </div>
            <div className="w-full lg:w-1/3">
              <Card className="rounded-lg border bg-background shadow p-2">
                <CardBody>
                  {isEditing ? <div className="w-full flex justify-center">
                    <Tooltip showArrow content="Bilder können nicht mehr geändert werden.">
                      <div
                        className="relative border border-gray-300 rounded-lg overflow-hidden max-w-xl"
                        style={{ aspectRatio: '16 / 9' }}
                      >
                        <img src={formData.imgUrl} alt="Uploaded" className="w-full h-full object-cover" />
                      </div>
                    </Tooltip>
                  </div> : <AppPromoImageUpload onImageChange={handleImageChange} />}
                  {isEditing &&
                    <>
                      <Spacer y={4} />
                      <div className='w-full'>
                        <div className="grid grid-cols-1 sm:grid-cols-2 gap-2 max-w-52 mx-auto">
                          <div
                            className="aspect-square border flex items-center flex-col justify-center rounded-lg transition-colors hover:bg-primary group cursor-pointer"
                          >
                            <Icon
                              className="text-default-500 group-hover:text-white"
                              icon="solar:mouse-outline"
                              width={24}
                            />
                            <span className="text-foreground-500 font-normal text-small mb-1 group-hover:text-white">
                              Clicks
                            </span>
                            <span className="text-foreground-500 font-normal bold group-hover:text-white">
                              {stats.clicks}
                            </span>
                          </div>
                          <div
                            className="aspect-square border flex items-center flex-col justify-center rounded-lg transition-colors hover:bg-primary group cursor-pointer"
                          >
                            <Icon
                              className="text-default-500 group-hover:text-white"
                              icon="solar:user-rounded-outline"
                              width={24}
                            />
                            <span className="text-foreground-500 font-normal text-small mb-1 group-hover:text-white">
                              Unique Clicks
                            </span>
                            <span className="text-foreground-500 font-normal bold group-hover:text-white">
                              {stats.uniqueClicks}
                            </span>
                          </div>
                        </div>
                      </div>
                    </>}
                  <Spacer y={4} />
                  <Button
                    onPress={handleSubmit}
                    isLoading={isSaving}
                    color="primary"
                  >
                    {isEditing ? 'Promotion bearbeiten' : 'Promotion erstellen'}
                  </Button>
                </CardBody>
              </Card>
            </div>
          </div>
          {/* <div className="mt-4 flex flex-col space-y-4">
            <Divider />
            <h4 className="text-medium font-medium">Dev-Details</h4>
            <div className="flex justify-between items-center">
              <JsonView src={generateJson()} />
            </div>
          </div> */}
        </>)
      }
    </div >
  );
};

export default AppPromotionEditor;
