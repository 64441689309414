import React, { useState } from "react";
import {
    Modal,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Button,
    useDraggable,
    Card,
    CardBody,
    CardFooter,
    ButtonGroup,
    Tooltip,
} from "@heroui/react";
import { Icon } from "@iconify/react/dist/iconify.js";
import PhotoEditorModal from "./modal_editor";
import { Photo } from "../models/photo";

interface GalleryModalProps {
    isOpen: boolean;
    onOpenChange: () => void;
    detailId: string;
    photos: Photo[];
    onPhotoDelete: (photoId: string) => void;
    onPhotoEdit: (photoId: string, editedUrl: string) => void;
    onPhotoRestore: (photoId: string) => void;
    onNavigateToCamera: () => void;
}

export default function GalleryModal({
    isOpen,
    onOpenChange,
    detailId,
    photos,
    onPhotoDelete,
    onPhotoEdit,
    onPhotoRestore,
    onNavigateToCamera
}: GalleryModalProps) {
    const targetRef = React.useRef(null);
    const { moveProps } = useDraggable({ targetRef, isDisabled: !isOpen });
    const [selectedPhoto, setSelectedPhoto] = useState<Photo | null>(null);
    const [isEditorOpen, setIsEditorOpen] = useState(false);

    const handleEdit = (photo: Photo) => {
        setSelectedPhoto(photo);
        setIsEditorOpen(true);
    };

    const handleEditorSave = (photoId: string, editedUrl: string) => {
        onPhotoEdit(photoId, editedUrl);
        setIsEditorOpen(false);
        setSelectedPhoto(null);
    };

    const handleEditorCancel = () => {
        setIsEditorOpen(false);
        setSelectedPhoto(null);
    };

    return (
        <>
            <Modal ref={targetRef} isOpen={isOpen} onOpenChange={onOpenChange} backdrop="blur" isDismissable={false} size="5xl" scrollBehavior="inside">
                <ModalContent>
                    {(onClose) => (
                        <>
                            <ModalHeader {...moveProps}>Galerie</ModalHeader>
                            <ModalBody>
                                <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-2">
                                    {photos.map((photo) => (
                                        <Card key={photo.id} className="relative rounded-lg border bg-background shadow">
                                            <CardBody className="p-0">
                                                <div className="relative">
                                                    <img
                                                        src={photo.edited || photo.original}
                                                        alt={`Photo ${photo.id}`}
                                                        className={`w-full h-auto object-contain ${photo.isDeleted ? 'brightness-50' : ''}`}
                                                    />
                                                    {photo.isEdited && !photo.isDeleted && (
                                                        <div className="absolute top-2 right-2 bg-primary text-white text-xs px-2 py-1 rounded-full">
                                                            Bearbeitet
                                                        </div>
                                                    )}
                                                    {photo.isDeleted && (
                                                        <div className="absolute inset-0 flex items-center justify-center">
                                                            <span className="text-white text-lg font-semibold">Gelöscht</span>
                                                        </div>
                                                    )}
                                                </div>
                                            </CardBody>
                                            <CardFooter className="flex justify-end items-center p-2">
                                                {!photo.isDeleted ? (
                                                    <div className="flex gap-2">
                                                        <Tooltip content="Bearbeiten" showArrow>
                                                            <Button isIconOnly
                                                                radius="full"
                                                                size="sm"
                                                                variant="bordered"
                                                                onPress={() => handleEdit(photo)}
                                                            >
                                                                <Icon
                                                                    className="text-default-500"
                                                                    icon="solar:pen-outline"
                                                                    width={20}
                                                                />
                                                            </Button>
                                                        </Tooltip>
                                                        {photo.isEdited && (
                                                            <Tooltip content="Wiederherstellen" showArrow>
                                                                <Button isIconOnly
                                                                    radius="full"
                                                                    size="sm"
                                                                    variant="bordered"
                                                                    onPress={() => onPhotoRestore(photo.id)}
                                                                >
                                                                    <Icon
                                                                        className="text-default-500"
                                                                        icon="solar:refresh-circle-outline"
                                                                        width={20}
                                                                    />
                                                                </Button>
                                                            </Tooltip>
                                                        )}
                                                        <Tooltip content="Löschen" showArrow>
                                                            <Button isIconOnly
                                                                radius="full"
                                                                size="sm"
                                                                variant="solid"
                                                                color="primary"
                                                                onPress={() => onPhotoDelete(photo.id)}
                                                            >
                                                                <Icon
                                                                    className="text-white"
                                                                    icon="solar:trash-bin-trash-outline"
                                                                    width={20}
                                                                />
                                                            </Button>
                                                        </Tooltip>
                                                    </div>
                                                ) : (
                                                    <Tooltip content="Wiederherstellen" showArrow>
                                                        <Button isIconOnly
                                                            radius="full"
                                                            size="sm"
                                                            variant="bordered"
                                                            onPress={() => onPhotoRestore(photo.id)}
                                                        >
                                                            <Icon
                                                                className="text-default-500"
                                                                icon="solar:refresh-circle-outline"
                                                                width={20}
                                                            />
                                                        </Button>
                                                    </Tooltip>
                                                )}
                                            </CardFooter>
                                        </Card>
                                    ))}
                                </div>
                            </ModalBody>
                            <ModalFooter className="justify-end">
                                <Button color="primary" onPress={onNavigateToCamera}>
                                    Neue Fotos aufnehmen
                                </Button>
                            </ModalFooter>
                        </>
                    )}
                </ModalContent>
            </Modal>

            {selectedPhoto && (
                <PhotoEditorModal
                    isOpen={isEditorOpen}
                    onOpenChange={() => setIsEditorOpen(false)}
                    img={selectedPhoto.edited || selectedPhoto.original}
                    photoId={selectedPhoto.id}
                    onSave={handleEditorSave}
                    onCancel={handleEditorCancel}
                />
            )}
        </>
    );
}
