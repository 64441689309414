'use client';

import { useState, useRef, useEffect } from 'react';
import { Button, Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, Spinner } from '@heroui/react';
import { EbayBase } from './models/EbayBase';
import { EbayRequest } from './models/EbayRequest';
import EbayForm from './components/EbayForm';
import ImageUpload from './components/ImageUpload';
import { useLocation, useNavigate } from 'react-router-dom';
import ImageSelectionModal from './components/ImageSelectionModal';
import JsonView from 'react18-json-view'
import 'react18-json-view/src/style.css'
import ebayService from './api/ebayService';
import { EbayDoc } from './models/EbayDoc';
import { uploadFileToFirebase } from '@/helpers/storageHelper';
import { Icon } from '@iconify/react/dist/iconify.js';
import { EbayDraft } from './models/EbayDraft';

interface ImageFile {
    id: string;
    file?: File;
    preview?: string;
    url?: string;
    width?: number;
    height?: number;
}

interface CompleteFormData {
    firebaseId: string;
    epid: string | null;
    category_id: number;
    sn: string | null;
    images: ImageFile[];
    timestamp: string;
    [key: string]: any;
}

export default function EbayOffer({ id }: { id: string }) {
    const location = useLocation();
    const navigate = useNavigate();
    const [ebayData, setEbayData] = useState<EbayBase | null>(null);
    const [ebayRequest, setEbayRequest] = useState<EbayRequest | null>(null);
    const [images, setImages] = useState<ImageFile[]>([]);
    const [existingImages, setExistingImages] = useState<{ url: string; width: number; height: number }[]>([]);
    const [showImageSelectionModal, setShowImageSelectionModal] = useState(false);
    const [showJsonModal, setShowJsonModal] = useState(false);
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [completeFormData, setCompleteFormData] = useState<CompleteFormData | null>(null);
    const [formData, setFormData] = useState<Record<string, any>>({});
    const [error, setError] = useState<string | null>(null);
    const [isLoading, setIsLoading] = useState(true);
    const [isInitialized, setIsInitialized] = useState(false);
    const [isUploading, setIsUploading] = useState(false);
    const [isSaving, setIsSaving] = useState(false);

    useEffect(() => {
        const initializeData = async () => {
            if (isInitialized) return;
            
            setIsLoading(true);
            try {
                const state = location.state as { ebayData?: EbayBase, ebayRequest?: EbayRequest };
                
                if (state?.ebayData) {
                    if (!state.ebayData.formFields) {
                        setError('Ungültige Daten: Formularfelder fehlen');
                        return;
                    }
                    setEbayData(state.ebayData);
                    if (state?.ebayRequest) {
                        setEbayRequest(state.ebayRequest);
                    }
                } else {
                    const doc = await ebayService.getEbayDoc(id);
                    if (doc !== "DOC NOT FOUND" && doc instanceof Object) {
                        const ebayDoc = doc as EbayDoc;
                        setEbayRequest(ebayDoc.request);
                        
                        const ebayData = await ebayService.getEbayData(ebayDoc.request);
                        if (!('commitUrl' in ebayData)) {
                            if (!ebayData.formFields) {
                                setError('Ungültige Daten: Formularfelder fehlen');
                                return;
                            }
                            setEbayData(ebayData as EbayBase);
                            setFormData(ebayDoc.values);
                            setCompleteFormData(ebayDoc.answers as CompleteFormData);
                            
                            if (ebayDoc.imageUrls.length > 0) {
                                const imageFiles = ebayDoc.imageUrls.map((url, index) => ({
                                    id: `existing-${index}`,
                                    file: new File([], `image-${index}.jpg`),
                                    preview: url,
                                    url: url
                                }));
                                setImages(imageFiles);
                            }
                        } else {
                            setError('Fehler beim Laden der Felder: Unvollständige Daten');
                        }
                    } else {
                        navigate('/admin-ebay');
                    }
                }
            } catch (error) {
                console.error('Error initializing data:', error);
                setError('Fehler beim Laden der Daten');
            } finally {
                setIsLoading(false);
                setIsInitialized(true);
            }
        };

        initializeData();
    }, [id, location.state, navigate]);

    const generateCompleteFormData = async () => {
        try {
            const uploadedImages = await uploadImages(images);

            const completeFormData = ebayData?.formFields.reduce((acc, field) => {
                if (formData[field.key] !== undefined) {
                    if ((field.type === 'text' || field.type === 'html') && formData[field.key] === '') {
                        acc[field.key] = null;
                    } else if (field.type === 'html' && formData[field.key]) {
                        acc[field.key] = btoa(formData[field.key]);
                    } else {
                        acc[field.key] = formData[field.key];
                    }
                }
                else {
                    switch (field.type) {
                        case 'html':
                            if ('initialValue' in field && field.initialValue) {
                                acc[field.key] = btoa(field.initialValue);
                            } else {
                                acc[field.key] = null;
                            }
                            break;
                        case 'text':
                            if ('initialValue' in field) {
                                acc[field.key] = field.initialValue === '' ? null : field.initialValue;
                            }
                            break;
                        case 'bool':
                            if ('initialValue' in field && field.initialValue !== undefined) {
                                acc[field.key] = field.initialValue;
                            }
                            break;
                        case 'singleSelect':
                            if ('initialValue' in field && field.initialValue !== undefined) {
                                acc[field.key] = field.initialValue;
                            }
                            break;
                        case 'multiSelect':
                            if ('initialValues' in field && field.initialValues !== undefined) {
                                acc[field.key] = field.initialValues;
                            }
                            break;
                    }
                }
                return acc;
            }, {} as Record<string, any>) || {};

            const newCompleteFormData = {
                firebaseId: id,
                category_id: ebayData?.category_id || 0,
                epid: ebayData?.epid || null,
                sn: ebayData?.sn || null,
                ...completeFormData,
                images: uploadedImages.map(img => {
                    const { file, preview, ...cleanImage } = img;
                    return cleanImage;
                }),
                timestamp: new Date().toISOString()
            };

            setCompleteFormData(newCompleteFormData);
            return newCompleteFormData;
        } catch (error) {
            console.error('Fehler beim Generieren der Daten:', error);
            throw error;
        }
    };

    useEffect(() => {
        let isMounted = true;
        let timeoutId: NodeJS.Timeout;

        const syncWithBackend = async () => {
            if (!isInitialized || !isMounted) return;

            try {
                const currentFormData = await generateCompleteFormData();
                
                const firstImageUrl = currentFormData.images?.[0]?.url || null;
                
                const ebayDraft = new EbayDraft({
                    id: id,
                    title: formData.title || '',
                    firstImageUrl,
                    json: JSON.stringify(currentFormData)
                });

                if (ebayRequest) {
                    ebayDraft.dataRequest = ebayRequest;
                }

                await ebayService.addEbayDoc(ebayDraft.toJson());
            } catch (error) {
                console.error('Fehler beim Synchronisieren mit dem Backend:', error);
            }
        };

        timeoutId = setTimeout(syncWithBackend, 1000);

        return () => {
            isMounted = false;
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
        };
    }, [formData, ebayRequest, id, isInitialized, images]);

    const uploadImages = async (imageFiles: ImageFile[]): Promise<ImageFile[]> => {
        const uploadPromises = imageFiles.map(async (image) => {
            if (image.url) return image;

            try {
                const url = await uploadFileToFirebase(image.file, `internal/ebay_offers/${id}`);
                return { ...image, url };
            } catch (error) {
                console.error('Fehler beim Hochladen des Bildes:', error);
                throw error;
            }
        });

        return Promise.all(uploadPromises);
    };

    const showJson = async () => {
        const errors: string[] = [];
        const missingFields: string[] = [];
        
        if (images.length === 0) {
            errors.push('Mindestens ein Bild ist erforderlich');
        }

        ebayData?.formFields.forEach(field => {
            const value = formData[field.key];
            const defaultValue = 'initialValue' in field ? field.initialValue : 
                               'initialValues' in field ? field.initialValues : null;
            
            if (field.mandatory && (value === undefined || value === null || value === '') && 
                (defaultValue === undefined || defaultValue === null || defaultValue === '')) {
                missingFields.push(field.name);
                return;
            }

            if (field.type === 'html' && (value || defaultValue)) {
                const textToCheck = value || defaultValue;
                const plainText = textToCheck.replace(/<[^>]*>/g, '');
                if (plainText.length < 50) {
                    errors.push(`Die Beschreibung muss mindestens 50 Zeichen lang sein (ohne HTML-Tags)`);
                }
            }

            if (field.type === 'text' && (value || defaultValue)) {
                const textToCheck = value || defaultValue;
                if (field.minLength !== undefined && textToCheck.length < field.minLength) {
                    errors.push(`Das Feld "${field.name}" muss mindestens ${field.minLength} Zeichen lang sein`);
                }
                if (field.maxLength !== undefined && textToCheck.length > field.maxLength) {
                    errors.push(`Das Feld "${field.name}" darf maximal ${field.maxLength} Zeichen lang sein`);
                }
            }

            if (field.type === 'text' && field.fieldType === 'number' && (value || defaultValue)) {
                const numValue = Number(value || defaultValue);
                if (isNaN(numValue)) {
                    errors.push(`Das Feld "${field.name}" muss eine gültige Zahl sein`);
                }
            }
        });

        if (missingFields.length > 0) {
            errors.push('\nFehlende Pflichtfelder:');
            missingFields.forEach(field => {
                errors.push(`- ${field}`);
            });
        }

        if (errors.length > 0) {
            setError(errors.join('\n'));
            setShowErrorModal(true);
            return;
        }

        try {
            await generateCompleteFormData();
            setShowJsonModal(true);
        } catch (error) {
            console.error('Fehler beim Generieren der Daten:', error);
            setError('Fehler beim Generieren der Daten');
            setShowErrorModal(true);
        }
    };

    const handleDirectUpload = async () => {
        const errors: string[] = [];
        const missingFields: string[] = [];
        
        if (images.length === 0) {
            errors.push('Mindestens ein Bild ist erforderlich');
        }

        ebayData?.formFields.forEach(field => {
            const value = formData[field.key];
            const defaultValue = 'initialValue' in field ? field.initialValue : 
                               'initialValues' in field ? field.initialValues : null;
            
            if (field.mandatory && (value === undefined || value === null || value === '') && 
                (defaultValue === undefined || defaultValue === null || defaultValue === '')) {
                missingFields.push(field.name);
                return;
            }

            if (field.type === 'html' && (value || defaultValue)) {
                const textToCheck = value || defaultValue;
                const plainText = textToCheck.replace(/<[^>]*>/g, '');
                if (plainText.length < 50) {
                    errors.push(`Die Beschreibung muss mindestens 50 Zeichen lang sein (ohne HTML-Tags)`);
                }
            }

            if (field.type === 'text' && (value || defaultValue)) {
                const textToCheck = value || defaultValue;
                if (field.minLength !== undefined && textToCheck.length < field.minLength) {
                    errors.push(`Das Feld "${field.name}" muss mindestens ${field.minLength} Zeichen lang sein`);
                }
                if (field.maxLength !== undefined && textToCheck.length > field.maxLength) {
                    errors.push(`Das Feld "${field.name}" darf maximal ${field.maxLength} Zeichen lang sein`);
                }
            }

            if (field.type === 'text' && field.fieldType === 'number' && (value || defaultValue)) {
                const numValue = Number(value || defaultValue);
                if (isNaN(numValue)) {
                    errors.push(`Das Feld "${field.name}" muss eine gültige Zahl sein`);
                }
            }
        });

        if (missingFields.length > 0) {
            errors.push('\nFehlende Pflichtfelder:');
            missingFields.forEach(field => {
                errors.push(`- ${field}`);
            });
        }

        if (errors.length > 0) {
            setError(errors.join('\n'));
            setShowErrorModal(true);
            return;
        }

        try {
            setIsUploading(true);
            const currentFormData = await generateCompleteFormData();
            await ebayService.uploadToEbay(currentFormData);
            navigate('/admin-ebay');
        } catch (error) {
            console.error('Fehler beim Hochladen zu eBay:', error);
            setError('Fehler beim Hochladen zu eBay');
            setShowErrorModal(true);
        } finally {
            setIsUploading(false);
        }
    };

    const handleSaveDraft = async () => {
        try {
            setIsSaving(true);
            const uploadedImages = await uploadImages(images);
            const newCompleteFormData = {
                firebaseId: id,
                category_id: ebayData?.category_id || 0,
                epid: ebayData?.epid || null,
                sn: ebayData?.sn || null,
                ...formData,
                images: uploadedImages,
                timestamp: new Date().toISOString()
            };
            setCompleteFormData(newCompleteFormData);
        } catch (error) {
            console.error('Fehler beim Speichern des Entwurfs:', error);
            setError('Fehler beim Speichern des Entwurfs');
            setShowErrorModal(true);
        } finally {
            setIsSaving(false);
        }
    };

    const handleExistingImagesSelect = async (selectedUrls: string[]) => {
        try {
            selectedUrls.forEach((url) => {
                const existingImage = existingImages.find(img => img.url === url);
                if (!existingImage) return;

                const emptyBlob = new Blob([], { type: 'image/jpeg' });
                const file = new File([emptyBlob], `image-${Math.random().toString(36).substr(2, 9)}.jpg`, {
                    type: 'image/jpeg'
                });

                setImages(prev => [...prev, {
                    id: Math.random().toString(36).substr(2, 9),
                    file,
                    preview: url
                }]);
            });

            setShowImageSelectionModal(false);
        } catch (error) {
            console.error('Fehler beim Verarbeiten der Bilder:', error);
        }
    };

    const handleFormDataChange = (data: Record<string, any>) => {
        const combinedData = { ...data };
        
        ebayData?.formFields.forEach(field => {
            if (!(field.key in combinedData)) {
                if (field.type === 'multiSelect' && 'initialValues' in field) {
                    combinedData[field.key] = field.initialValues;
                } else if ('initialValue' in field) {
                    combinedData[field.key] = field.initialValue;
                }
            }
        });
        
        setFormData(combinedData);
    };

    if (isLoading) {
        return (
            <div className="w-full h-full flex items-center justify-center">
                <Spinner />
            </div>
        );
    }

    if (!ebayData || !ebayData.formFields) {
        return <div>Keine Daten verfügbar</div>;
    }

    return (
        <div className="w-full h-full flex-1 p-4" style={{ overflowY: "auto" }}>
            <div className="flex items-center gap-x-3">
                <h1 className="text-3xl font-bold leading-9 text-default-foreground">eBay Angebot erstellen</h1>
            </div>
            <h2 className="mt-2 text-small text-default-500 pb-6">
                Erstelle eBay Auktionen oder Festpreis-Angebote.
            </h2>

            <div className="flex gap-2">
                <div className="w-2/3">
                    <EbayForm
                        formFields={ebayData.formFields}
                        onSubmit={showJson}
                        onDirectUpload={handleDirectUpload}
                        onSaveDraft={handleSaveDraft}
                        onFormDataChange={handleFormDataChange}
                        isLoading={isUploading}
                        isSaving={isSaving}
                    />
                </div>
                <div className="w-1/3">
                    <ImageUpload 
                        images={images as any} // Type assertion to fix type mismatch
                        onImagesChange={setImages}
                    />
                </div>
            </div>

            <ImageSelectionModal
                isOpen={showImageSelectionModal}
                onClose={() => setShowImageSelectionModal(false)}
                images={existingImages}
                onImagesSelect={handleExistingImagesSelect}
            />

            <Modal 
                isOpen={showJsonModal} 
                onClose={() => setShowJsonModal(false)}
                size="5xl"
                scrollBehavior="inside"
                backdrop="blur"
            >
                <ModalContent>
                    <ModalHeader className="flex flex-col gap-1">
                        Vollständige Angebotsdaten
                    </ModalHeader>
                    <ModalBody>
                        <div className="bg-default-50 p-4 rounded-lg">
                            {completeFormData && (
                               <JsonView src={completeFormData} />
                            )}
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <Button 
                            color="primary" 
                            onPress={() => setShowJsonModal(false)}
                        >
                            Schließen
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>

            <Modal 
                isOpen={showErrorModal} 
                onClose={() => setShowErrorModal(false)}
                size="2xl"
                backdrop="blur"
            >
                <ModalContent>
                    <ModalHeader className="flex flex-col gap-1">
                        Ups - Überprüfe das noch einmal
                    </ModalHeader>
                    <ModalBody>
                            <ul className="list-disc list-inside space-y-2">
                                {error?.split('\n').map((errorMessage, index) => (
                                    <li key={index}>
                                        {errorMessage}
                                    </li>
                                ))}
                            </ul>
                    </ModalBody>
                    <ModalFooter>
                        <Button 
                            color="primary" 
                            onPress={() => setShowErrorModal(false)}
                        >
                            Alles klar
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </div>
    );
}
