import OpenAI from "openai";
import { ChatCompletionMessageParam } from "openai/resources/index.mjs";
import { OpenAiResponse, OpenAiTextGenerator } from "../models/openAiTextGenerator";

export class OpenAiService {
    private openai: OpenAI;

    constructor() {
        this.openai = new OpenAI({
            apiKey: process.env.REACT_APP_OPENAI_API_KEY,
            dangerouslyAllowBrowser: true
        });
    }

    public async generateArticleText(data: OpenAiTextGenerator): Promise<OpenAiResponse> {
        try {
            const prompt = `
                Angebotsname: ${data.offerTitle}
                Verkäufernotizen: ${data.sellerNotes}
                Aktueller Text: ${data.legacyText}
                
                Bitte generiere einen professionellen und freundlichen Verkaufstext für diesen Artikel.
                Dieser Text soll als kurze Beschreibung für eBay dienen.
            `;

            const completion = await this.openai.chat.completions.create({
                model: "gpt-3.5-turbo",
                messages: [
                    {
                        role: "system",
                        content: `Du bist ein erfahrener und professioneller eBay Verkäufer, der Artikel möglichst ehrlich und gewinnbringend verkaufen möchte:
            
1. **Keine Begrüßung** – beginne direkt mit dem Inhalt (z. B. keine Anrede wie "Hallo" oder "Lieber Kunde").
2. **Immer Duzen** – verwende ausschließlich "Du", "Dich", "Dir" usw. und schreibe diese Wörter **immer groß**.
3. **HTML-Format als Ausgabe** – keine Markdown-Syntax! Verwende <b>, <i>, <ul>, <li>, <p>, <br>, <a>, etc.
4. Du darfst gerne ein freundliches Emoji wie 😊 einbauen, aber sparsam.
5. **Keine Abschlussfloskeln** – keine Sätze wie "Falls Du Fragen hast, stehe ich gerne zur Verfügung" oder ähnliche.
6. **Vermeide Redundanzen** – keine Wiederholungen von Informationen oder überflüssige Ausschmückungen.
7. **Formatierung** – verwende <b> für wichtige Informationen und <a target="_blank" rel="noopener noreferrer"> für Links.
8. **Preis & Widerruf** – gehe NIEMALS auf Preis und Widerruf ein.

Deine Antwort muss in **deutscher Sprache** erfolgen und **immer** im beschriebenen HTML-Format sein. Gebe NUR den Verkaufstext aus.`
                    },
                    {
                        role: "user",
                        content: prompt
                    }
                ],
                temperature: 0.7,
                max_tokens: 500
            });

            console.log('OpenAI Response:', {
                prompt,
                response: completion.choices[0]?.message?.content,
                fullResponse: completion
            });

            return completion as unknown as OpenAiResponse;
        } catch (error) {
            console.error('Fehler bei der OpenAI-Anfrage:', error);
            throw error;
        }
    }
}