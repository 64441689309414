import { getCookie } from "@/helpers/cookieHelper";
import { ArticleCheckModel } from "../models/article";
import { ConditionCalculatorModel } from "../models/conditionCalculator";
import { ArticleCheckCondition } from "../models/articleCondition";
import { ArticleBasicModel } from "../models/articleBasic";
import { AppArticleState } from "../models/appArticleState";
import { type } from "os";
import { PriceRequest } from "../models/priceRequest";
import { PriceResponse } from "../models/priceResponse";
import { MailText } from "../models/mailText";
import { ConditionCalculatorLightResponseModel } from "../models/conditionCalculatorLight";
import { ConditionCalculatorLightRequest } from "../models/conditionCalculatorLight";
import { CategoryModel } from "../models/category";

const BASE_URL = "https://app.api.zoxs.de";

class ArticleService {
    private baseUrl: string;

    constructor(baseUrl: string) {
        this.baseUrl = baseUrl;
    }

    // GET CHECK ARTICLE
    async getSellArticleForCheck(type: string, id: string): Promise<ArticleCheckModel> {
        console.log(`GET ARTICLE DATA FOR: ${type} ${id}`)
        try {
            const authToken = getCookie('inteamuser');

            const headers: HeadersInit = {
                'Content-Type': 'application/json',
            };

            if (authToken) {
                headers['auth-token'] = authToken;
            }

            const response = await fetch(`${this.baseUrl}/v1/app/zz/article/${type}/${id}`, {
                method: 'GET',
                headers,
            });

            if (!response.ok) {
                throw new Error(`Failed to fetch the article: ${response.statusText}`);
            }

            const jsonResponse = await response.json();

            if (!jsonResponse) {
                throw new Error("Empty response or malformed data.");
            }

            const data = ArticleCheckModel.fromJson(jsonResponse);

            return data;
        } catch (error: any) {
            console.log(error);
            throw new Error(error.message || "Failed to fetch the article.");
        }
    }

    // GET CONDITION CALCULATOR // TODO: ZZ-CALCULATOR
    async getConditionCalculator(id: number): Promise<ConditionCalculatorModel> {
        console.log(`GET CONDITION CALCULATOR FOR: ${id}`)
        try {
            const authToken = getCookie('inteamuser');

            const headers: HeadersInit = {
                'Content-Type': 'application/json',
            };

            if (authToken) {
                headers['auth-token'] = authToken;
            }

            const response = await fetch(`${this.baseUrl}/v1/app/sell/calculator/${id}`, {
                method: 'GET',
                headers,
            });

            if (!response.ok) {
                throw new Error(`Failed to fetch the calculator: ${response.statusText}`);
            }

            const jsonResponse = await response.json();

            if (!jsonResponse) {
                throw new Error("Empty response or malformed data.");
            }

            const data = ConditionCalculatorModel.fromJson(jsonResponse);

            return data;
        } catch (error: any) {
            console.log(error);
            throw new Error(error.message || "Failed to fetch the calculator.");
        }
    }

    // GET CONDITION CALCULATOR RESULT // TODO: ZZ-CALCULATOR
    async getConditionCalculatorResult(id: number, answers: number[]): Promise<ArticleCheckCondition> {
        console.log(`GET CONDITION CALCULATOR RESULT FOR: ${id}, ${answers}`)
        try {
            const authToken = getCookie('inteamuser');

            const headers: HeadersInit = {
                'Content-Type': 'application/json',
            };

            if (authToken) {
                headers['auth-token'] = authToken;
            }

            const response = await fetch(`${this.baseUrl}/v1/app/sell/calculator/${id}`, {
                method: 'POST',
                headers,
                body: JSON.stringify(answers)
            });

            if (!response.ok) {
                throw new Error(`Failed to fetch the calculator: ${response.statusText}`);
            }

            const jsonResponse = await response.json();

            if (!jsonResponse) {
                throw new Error("Empty response or malformed data.");
            }

            const data = ArticleCheckCondition.fromJson(jsonResponse);

            return data;
        } catch (error: any) {
            console.log(error);
            throw new Error(error.message || "Failed to fetch the calculator.");
        }
    }

    // GET AVAILABLE ORDER STATES

    // GET AVAILABLE ARTICLE STATES
    async getArticleStates(id: number): Promise<AppArticleState[]> {
        return [{
            id,
            updatedAt: new Date().toISOString(),
        } as unknown as AppArticleState];
    }

    // SYNC ORDER STATE

    // SYNC ARTICLE STATE

    // GET AVAILABLE TEXTS
    async getAvailableMailTexts(id: string): Promise<MailText[]> {
        try {
            const authToken = getCookie('inteamuser');

            const headers: HeadersInit = {
                'Content-Type': 'application/json', 
            };

            if (authToken) {
                headers['auth-token'] = authToken;
            }

            const response = await fetch(`${this.baseUrl}/v1/app/zz/mailtext/articleId/${id}`, {
                method: 'GET',
                headers,
            }); 

            if (!response.ok) {
                throw new Error(`Failed to fetch the mail texts: ${response.statusText}`);
            }   

            const jsonResponse = await response.json();

            if (!jsonResponse) {
                throw new Error("Empty response or malformed data.");
            }   

            const data = jsonResponse.map(MailText.fromJson);

            return data;
        } catch (error: any) {
            console.log(error);
            throw new Error(error.message || "Failed to fetch the mail texts.");
        }
    }

    // GET CONDITION-PERCENTAGE CALCULATOR (BASED ON QUESTIONS)
    async getConditionPercentageCalculator(body: ConditionCalculatorLightRequest): Promise<ConditionCalculatorLightResponseModel> {
        console.log(`GET CONDITION PERCENTAGE CALCULATOR FOR: ${JSON.stringify(body)}`)
        try {
            const authToken = getCookie('inteamuser');

            const headers: HeadersInit = {
                'Content-Type': 'application/json',
            };

            if (authToken) {
                headers['auth-token'] = authToken;
            }

            const response = await fetch(`${this.baseUrl}/v1/app/zz/article/condition`, {
                method: 'POST',
                headers,
                body: JSON.stringify(body)
            });

            if (!response.ok) {
                throw new Error(`Failed to fetch the condition percentage: ${response.statusText}`);
            }

            const jsonResponse = await response.json();

            if (!jsonResponse) {
                throw new Error("Empty response or malformed data.");
            }

            const data = ConditionCalculatorLightResponseModel.fromJson(jsonResponse);

            return data;
        } catch (error: any) {
            console.log(error);
            throw new Error(error.message || "Failed to fetch the condition percentage.");
        }
    }

    // GET ARTICLE PRICE (BASED ON QUESTIONS)
    async getArticlePrice(body: PriceRequest): Promise<PriceResponse> {
        console.log(JSON.stringify(body))
        try {
            const authToken = getCookie('inteamuser');

            const headers: HeadersInit = {
                'Content-Type': 'application/json',
            };

            if (authToken) {
                headers['auth-token'] = authToken;
            }

            const response = await fetch(`${this.baseUrl}/v1/app/zz/article/price`, {
                method: 'POST',
                headers,
                body: JSON.stringify(body)
            });
    
            if (!response.ok) {
                throw new Error(`Failed to fetch the article price: ${response.statusText}`);
            }

            const jsonResponse = await response.json();

            if (!jsonResponse) {
                throw new Error("Empty response or malformed data.");
            }

            const data = PriceResponse.fromJson(jsonResponse);

            return data;
        } catch (error: any) {
            console.log(error);
            throw new Error(error.message || "Failed to fetch the article price.");
        }
    }

    // PRECHECK SERIAL-NUMBER

    // CANCEL CHECK

    // SAVE CHECK

    // CLOSE CHECK

    // SEARCH ARTICLES BY ID, EAN, ASIN
    async getSellArticleSearchResults(type: string, id: string): Promise<ArticleBasicModel> {
        console.log(`GET ARTICLES BY: ${type} ${id}`)
        try {
            const authToken = getCookie('inteamuser');

            const headers: HeadersInit = {
                'Content-Type': 'application/json',
            };

            if (authToken) {
                headers['auth-token'] = authToken;
            }

            const response = await fetch(`${this.baseUrl}/v1/app/sell/article/${type}/${id}`, {
                method: 'GET',
                headers,
            });

            if (!response.ok) {
                throw new Error(`Failed to fetch the article: ${response.statusText}`);
            }

            const jsonResponse = await response.json();

            if (!jsonResponse) {
                throw new Error("Empty response or malformed data.");
            }

            const data = ArticleBasicModel.fromJson(jsonResponse);

            return data;
        } catch (error: any) {
            console.log(error);
            throw new Error(error.message || "Failed to fetch the article.");
        }
    }

    // SEARCH ARTICLES BY TEXT
    async getSellArticleSearchResultsByText(query: string): Promise<ArticleBasicModel[]> {
        console.log(`GET ARTICLES FOR QUERY: ${query}`)
        try {
            const authToken = getCookie('inteamuser');

            const headers: HeadersInit = {
                'Content-Type': 'application/json',
            };

            if (authToken) {
                headers['auth-token'] = authToken;
            }

            const response = await fetch(`${this.baseUrl}/v1/app/sell/article?q=${query}`, {
                method: 'GET',
                headers,
            });

            if (!response.ok) {
                throw new Error(`Failed to fetch the article: ${response.statusText}`);
            }

            const jsonResponse = await response.json();

            if (!jsonResponse) {
                throw new Error("Empty response or malformed data.");
            }

            const data = jsonResponse.map(ArticleBasicModel.fromJson);

            return data;
        } catch (error: any) {
            console.log(error);
            throw new Error(error.message || "Failed to fetch the article.");
        }
    }

    // GET CATEGORIES
    async getCategories(): Promise<CategoryModel[]> {
        try {
            const authToken = getCookie('inteamuser');

            const headers: HeadersInit = {
                'Content-Type': 'application/json',
            };

            if (authToken) {
                headers['auth-token'] = authToken;
            }

            const response = await fetch(`${this.baseUrl}/v1/app/zz/categories`, {
                method: 'GET',
                headers,
            });

            if (!response.ok) {
                throw new Error(`Failed to fetch the categories: ${response.statusText}`);
            }

            const jsonResponse = await response.json();

            if (!jsonResponse) {
                throw new Error("Empty response or malformed data.");
            }

            const data = jsonResponse.map(CategoryModel.fromJson);

            return data;
        } catch (error: any) {
            console.log(error);
            throw new Error(error.message || "Failed to fetch the categories.");
        }
    }
}

const articleService = new ArticleService(BASE_URL);
export default articleService;