import React, { useState } from 'react';
import { QuillDeltaToHtmlConverter } from 'quill-delta-to-html';
import { Card, CardBody, CardFooter, Button } from '@heroui/react';
import { Play, Pause, FileText, Download, Volume2, Trash, Share2 } from 'lucide-react';
import PDFViewerModal from '@/components/PDFViewer';

const WikiContent = ({ item, userRights }) => {
    const [playingAudio, setPlayingAudio] = useState(null);
    const [pdfModalOpen, setPdfModalOpen] = useState(false);
    const [currentPdfFile, setCurrentPdfFile] = useState(null);

    let htmlContent = '';
    if (item?.textContent) {
        try {
            const deltaOps = JSON.parse(item.textContent);
            const converter = new QuillDeltaToHtmlConverter(deltaOps, {
                inlineStyles: {
                    color: (value) => {
                        if (!value) {
                            return 'color: var(--heroui-colors-default-foreground);';
                        }
                        if (value === '#f00') {
                            return 'color: var(--heroui-colors-primary);';
                        }
                        return `color: ${value};`;
                    },
                },
            });
            htmlContent = converter.convert();
        } catch (error) {
            console.error('Error converting delta to HTML:', error);
        }
    }

    const hasMedia = (
        item?.imageMedia?.length > 0 ||
        item?.videoMedia?.length > 0 ||
        item?.fileMedia?.length > 0 ||
        item?.voiceMedia?.length > 0
    );

    const formatDuration = (milliseconds) => {
        if (!milliseconds) return '00:00';

        const totalSeconds = Math.floor(milliseconds / 1000);
        const minutes = Math.floor(totalSeconds / 60);
        const seconds = totalSeconds % 60;

        return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    };

    const toggleAudioPlayback = (audioIndex) => {
        if (playingAudio === audioIndex) {
            setPlayingAudio(null);
        } else {
            if (playingAudio !== null) {
            }
            setPlayingAudio(audioIndex);
        }
    };

    const handleFileClick = (file, event) => {
        if (file.filetype.toString().toLowerCase().includes('pdf')) {
            event.preventDefault();
            setCurrentPdfFile({
                url: file.url,
                name: file.filename
            });
            setPdfModalOpen(true);
        }
    };

    return (
        <div className="wiki-content w-full">
            <div className="flex flex-col md:flex-row gap-6">
                <div className="w-full md:w-2/3">
                    {htmlContent ? (
                        <div
                            className="zx-quill-content prose max-w-none"
                            dangerouslySetInnerHTML={{ __html: htmlContent }}
                        />
                    ) : (
                        <div className="p-6 text-center text-gray-500 bg-white rounded-lg shadow-sm">
                            No text content available
                        </div>
                    )}
                </div>
                <div className="w-full md:w-1/3">
                    {hasMedia ? (
                        <div className="space-y-4">
                            {item?.imageMedia?.length > 0 && (
                                <div className="bg-default-100 rounded-xl shadow-sm p-4">
                                    <h3 className="text-lg font-medium mb-3">Bilder</h3>
                                    <div className="grid grid-cols-2 gap-2">
                                        {item.imageMedia.map((imgUrl, index) => (
                                            <img
                                                key={`img-${index}`}
                                                src={imgUrl}
                                                alt={`Image ${index + 1}`}
                                                className="w-full h-24 object-cover rounded-md hover:opacity-90 transition-opacity cursor-pointer"
                                            />
                                        ))}
                                    </div>
                                </div>
                            )}
                            {item?.videoMedia?.length > 0 && (
                                <div className="bg-default-100 rounded-xl shadow-sm p-4">
                                    <h3 className="text-lg font-medium mb-3">Videos</h3>
                                    <div className="space-y-2">
                                        {item.videoMedia.map((video, index) => (
                                            <Card key={`video-${index}`} className="shadow-sm">
                                                <CardBody className="p-0 relative">
                                                    <img
                                                        src={video.thumbnail}
                                                        alt={`Video ${index + 1} thumbnail`}
                                                        className="w-full h-24 object-cover"
                                                    />
                                                    <div className="absolute inset-0 flex items-center justify-center">
                                                        <Button
                                                            isIconOnly
                                                            className="bg-black/50 text-white hover:bg-black/70"
                                                            size="sm"
                                                            radius="full"
                                                        >
                                                            <Play size={16} />
                                                        </Button>
                                                    </div>
                                                </CardBody>
                                                <CardFooter className="py-1 px-2 text-xs">
                                                    <span>{video.duration}</span>
                                                </CardFooter>
                                            </Card>
                                        ))}
                                    </div>
                                </div>
                            )}
                            {item?.voiceMedia?.length > 0 && (
                                <div className="bg-default-100 rounded-xl shadow-sm p-4">
                                    <h3 className="text-lg font-medium mb-3">Audios</h3>
                                    <div className="space-y-2">
                                        {item.voiceMedia.map((voice, index) => (
                                            <div
                                                key={`voice-${index}`}
                                                className="flex items-center justify-between p-2 border border-gray-200 rounded-md hover:bg-gray-50"
                                            >
                                                <div className="flex items-center gap-2">
                                                    <div className="p-1.5 rounded-full bg-purple-100 text-purple-600 flex-shrink-0">
                                                        <Volume2 size={16} />
                                                    </div>
                                                    <div>
                                                        <div className="text-sm font-medium">Audio {index + 1}</div>
                                                        <div className="text-xs text-gray-500">{formatDuration(voice.duration)}</div>
                                                    </div>
                                                </div>
                                                <div className="flex items-center gap-2">
                                                    <Button
                                                        size="sm"
                                                        isIconOnly
                                                        variant="light"
                                                        color="primary"
                                                        onClick={() => toggleAudioPlayback(index)}
                                                    >
                                                        {playingAudio === index ? <Pause size={16} /> : <Play size={16} />}
                                                    </Button>
                                                    <Button
                                                        size="sm"
                                                        isIconOnly
                                                        variant="light"
                                                        as="a"
                                                        href={voice.url}
                                                        download
                                                    >
                                                        <Download size={16} />
                                                    </Button>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            )}
                            {item?.fileMedia?.length > 0 && (
                                <div className="bg-default-100 rounded-xl shadow-sm p-4">
                                    <h3 className="text-lg font-medium mb-3">Dateien</h3>
                                    <div className="space-y-2">
                                        {item.fileMedia.map((file, index) => (
                                            <a
                                                key={`file-${index}`}
                                                className="flex items-center justify-between p-2 border border-gray-200 rounded-md hover:bg-gray-50 cursor-pointer"
                                                href={file.url}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                download
                                                onClick={(e) => handleFileClick(file, e)}
                                            >
                                                <div className="flex items-center gap-2">
                                                    <div className={`
                                                        p-1.5 rounded-md flex-shrink-0
                                                        ${file.filetype === 'pdf' ? 'bg-red-100 text-red-600' :
                                                            file.filetype === 'doc' || file.filetype === 'docx' ? 'bg-blue-100 text-blue-600' :
                                                                file.filetype === 'xls' || file.filetype === 'xlsx' ? 'bg-green-100 text-green-600' :
                                                                    'bg-gray-100 text-gray-600'}
                                                    `}>
                                                        <FileText size={16} />
                                                    </div>
                                                    <div className="truncate max-w-[120px]">
                                                        <div className="text-sm font-medium truncate">{file.filename}</div>
                                                        <div className="text-xs text-gray-500">{file.size}</div>
                                                    </div>
                                                </div>
                                                <div className="flex-shrink-0">
                                                    <Download size={16} />
                                                </div>
                                            </a>
                                        ))}
                                    </div>
                                </div>
                            )}
                        </div>
                    ) : (
                        <div className="bg-default-100 rounded-xl shadow-sm p-4 text-center text-gray-500">
                            Keine Ahnhänge
                        </div>
                    )}
                </div>
            </div>

            {currentPdfFile && (
                <PDFViewerModal
                    isOpen={pdfModalOpen}
                    onClose={() => setPdfModalOpen(false)}
                    fileUrl={currentPdfFile.url}
                    fileName={currentPdfFile.name}
                />
            )}
        </div>
    );
};

export default WikiContent;