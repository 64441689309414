import React, { useEffect, useState, useMemo } from "react";
import {
    Drawer,
    DrawerContent,
    DrawerHeader,
    DrawerBody,
    DrawerFooter,
    Button,
    Autocomplete,
    AutocompleteItem,
    Spinner,
    ScrollShadow,
    Tooltip
} from "@heroui/react";
import { Icon } from "@iconify/react/dist/iconify.js";
import articleService from "../api/articleService";
import { MailText } from "../models/mailText";

interface TextListDrawerProps {
    isOpen: boolean;
    onOpenChange: () => void;
    onTextSelect: (text: string) => void;
    articleId: number;
}

const TextListDrawer: React.FC<TextListDrawerProps> = ({
    isOpen,
    onOpenChange,
    onTextSelect,
    articleId
}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [texts, setTexts] = useState<MailText[]>([]);
    const [searchValue, setSearchValue] = useState("");
    const [selectedText, setSelectedText] = useState<MailText | null>(null);

    // Lade die Texte beim ersten Öffnen
    useEffect(() => {
        const fetchTexts = async () => {
            if (!isOpen) return;

            setIsLoading(true);
            try {
                const response = await articleService.getAvailableMailTexts(articleId.toString());
                setTexts(response);
            } catch (error) {
                console.error('Fehler beim Laden der Texte:', error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchTexts();
    }, [isOpen, articleId]);

    // Filtere Texte für das Autocomplete (nur Texte mit sellText und ohne children)
    const availableTexts = useMemo(() => {
        const flattenTexts = (texts: MailText[], parentPath: string[] = []): MailText[] => {
            return texts.reduce((acc: MailText[], text) => {
                const currentPath = [...parentPath, text.title];
                
                // Wenn der Text einen sellText hat, keine children und nicht versteckt ist, füge ihn hinzu
                if (text.sellText && (!text.subTexts || text.subTexts.length === 0) && !text.hide) {
                    acc.push({
                        ...text,
                        path: currentPath
                    });
                }

                // Wenn der Text children hat, füge sie rekursiv hinzu
                if (text.subTexts && text.subTexts.length > 0) {
                    acc.push(...flattenTexts(text.subTexts, currentPath));
                }

                return acc;
            }, []);
        };

        return flattenTexts(texts);
    }, [texts]);

    // Filtere Texte basierend auf der Suche
    const filteredTexts = useMemo(() => {
        if (!searchValue.trim()) return availableTexts;

        const searchLower = searchValue.toLowerCase();
        return availableTexts.filter(text =>
            text.title.toLowerCase().includes(searchLower) ||
            text.sellText.toLowerCase().includes(searchLower)
        );
    }, [availableTexts, searchValue]);

    const handleSelectionChange = (textId: string) => {
        const selected = availableTexts.find(text => text.id.toString() === textId);
        if (selected) {
            setSelectedText(selected);
        }
    };

    const handleApply = () => {
        if (selectedText?.sellText) {
            onTextSelect(selectedText.sellText);
            onOpenChange();
        }
    };

    return (
        <Drawer
            hideCloseButton
            backdrop="blur"
            classNames={{
                base: "data-[placement=right]:sm:m-2 data-[placement=left]:sm:m-2 rounded-medium",
            }}
            isOpen={isOpen}
            onOpenChange={onOpenChange}
            size="3xl"
        >
            <DrawerContent>
                {(onClose) => (
                    <>
                        <DrawerHeader className="absolute top-0 inset-x-0 z-50 flex flex-row gap-2 px-2 py-2 border-b border-default-200/50 justify-between bg-content1/50 backdrop-saturate-150 backdrop-blur-lg">
                            <Tooltip content="Schließen">
                                <Button
                                    isIconOnly
                                    className="text-default-400"
                                    size="sm"
                                    variant="light"
                                    onPress={onClose}
                                >
                                    <svg
                                        fill="none"
                                        height="20"
                                        stroke="currentColor"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        viewBox="0 0 24 24"
                                        width="20"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path d="m13 17 5-5-5-5M6 17l5-5-5-5" />
                                    </svg>
                                </Button>
                            </Tooltip>
                        </DrawerHeader>

                        <DrawerBody className="px-0">
                            <ScrollShadow>
                                <div className="pt-16 px-6 space-y-2">
                                    {isLoading ? (
                                        <div className="flex justify-center items-center h-full">
                                            <Spinner size="lg" />
                                        </div>
                                    ) : (
                                        <div className="flex flex-col gap-4">
                                            <Autocomplete
                                                isVirtualized
                                                label="Suche nach Texten"
                                                placeholder="Titel oder Text eingeben..."
                                                value={searchValue}
                                                onValueChange={setSearchValue}
                                                onSelectionChange={handleSelectionChange}
                                                className="w-full"
                                                size="sm"
                                                itemHeight={80}
                                                maxListboxHeight={500}
                                                variant="bordered"
                                                radius="sm"
                                                defaultItems={filteredTexts}
                                            >
                                                {(item) => (
                                                    <AutocompleteItem
                                                        key={item.id}
                                                        textValue={`${item.path?.join(" > ")} ${item.sellText}`}
                                                        className="py-2"
                                                    >
                                                        <div className="flex flex-col gap-1">
                                                            <div className="flex flex-col">
                                                                <span className="text-tiny text-default-400">
                                                                    {item.path?.slice(0, -1).join(" > ")}
                                                                </span>
                                                                <span className="text-small font-medium text-default-700">
                                                                    {item.title}
                                                                </span>
                                                            </div>
                                                            {item.sellText && (
                                                                <p className="text-tiny text-default-500 line-clamp-1">
                                                                    {item.sellText}
                                                                </p>
                                                            )}
                                                        </div>
                                                    </AutocompleteItem>
                                                )}
                                            </Autocomplete>

                                            {selectedText && (
                                                <div className="mt-4 p-4 rounded-lg bg-default-100">
                                                    <h4 className="font-medium mb-2">{selectedText.title}</h4>
                                                    <p className="text-small whitespace-pre-wrap">
                                                        {selectedText.sellText}
                                                    </p>
                                                </div>
                                            )}
                                        </div>
                                    )}
                                </div>
                            </ScrollShadow>
                        </DrawerBody>

                        <DrawerFooter>
                            <Button color="primary" fullWidth onPress={handleApply}>
                                Text übernehmen
                            </Button>
                        </DrawerFooter>
                    </>
                )}
            </DrawerContent>
        </Drawer>
    );
};

export default TextListDrawer;
