import {
    Drawer,
    DrawerContent,
    DrawerHeader,
    DrawerBody,
    DrawerFooter,
    Button,
    Image,
    Tooltip,
    Spinner,
    Card,
    RadioGroup,
    Input,
    Select,
    SelectItem,
    useRadio,
    cn,
    VisuallyHidden,
    ScrollShadow,
} from "@heroui/react";
import { useEffect, useState } from "react";
import articleService from "../api/articleService";
import React from "react";
import { ArticleBasicModel } from "../models/articleBasic";
import { debounce } from "lodash"

export const types = [
    { key: "asin", label: "ASIN" },
    { key: "ean", label: "EAN" },
    { key: "articleId", label: "Artikel-ID" },
    { key: "search", label: "Suchbegriff" },
];

interface ModelChangeDrawerProps {
    isOpen: boolean;
    onOpenChange: () => void;
    articleId: number;
    onArticleChange: (newArticleId: number) => void;
}

export default function ModelChangeDrawer({ isOpen, onOpenChange, articleId, onArticleChange }: ModelChangeDrawerProps) {
    const [isLoading, setIsLoading] = useState(false);
    const [articles, setArticles] = useState<ArticleBasicModel[] | null>(null);
    const [type, setType] = useState("ean");
    const [value, setValue] = useState("");
    const [error, setError] = useState<string | null>(null);
    const [selectedArticleId, setSelectedArticleId] = useState<number | null>(null);

    const handleSearch = async () => {
        if (!value) return;
        setIsLoading(true);
        setError(null);
        setArticles([]);
        try {
            let results;
            if (type === "search") {
                results = await articleService.getSellArticleSearchResultsByText(value);
            } else {
                results = [await articleService.getSellArticleSearchResults(type, value)];
            }
            setArticles(results);
        } catch (err) {
            setError("Fehler beim Abrufen der Artikel");
        } finally {
            setIsLoading(false);
        }
    };

    const debouncedSearch = debounce(() => {
        if (type === "search" && value.length > 5) {
            handleSearch();
        }
    }, 500);

    useEffect(() => {
        if (type === "search") {
            debouncedSearch();
        }
        return () => debouncedSearch.cancel();
    }, [value, type]);

    const handleArticleSelect = (articleId: number) => {
        setSelectedArticleId(articleId);
    };

    const handleConfirmChange = async () => {
        if (!selectedArticleId) return;
        
        try {
            setIsLoading(true);
            onArticleChange(selectedArticleId);
            onOpenChange();
        } catch (error) {
            setError("Fehler beim Wechsel des Artikels");
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Drawer
            hideCloseButton
            backdrop="blur"
            classNames={{
                base: "data-[placement=right]:sm:m-2 data-[placement=left]:sm:m-2 rounded-medium",
            }}
            isOpen={isOpen}
            onOpenChange={onOpenChange}
        >
            <DrawerContent>
                {(onClose) => (
                    <>
                        <DrawerHeader className="absolute top-0 inset-x-0 z-50 flex flex-row gap-2 px-2 py-2 border-b border-default-200/50 justify-between bg-content1/50 backdrop-saturate-150 backdrop-blur-lg">
                            <Tooltip content="Schließen">
                                <Button
                                    isIconOnly
                                    className="text-default-400"
                                    size="sm"
                                    variant="light"
                                    onPress={onClose}
                                >
                                    <svg
                                        fill="none"
                                        height="20"
                                        stroke="currentColor"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        viewBox="0 0 24 24"
                                        width="20"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path d="m13 17 5-5-5-5M6 17l5-5-5-5" />
                                    </svg>
                                </Button>
                            </Tooltip>
                        </DrawerHeader>

                        <DrawerBody className="px-0">
                            <ScrollShadow>
                                <div className="pt-16 px-6 space-y-2">
                                    <Select
                                        fullWidth
                                        disallowEmptySelection
                                        label="Typ"
                                        variant="bordered"
                                        size="sm"
                                        placeholder="Wähle einen Suchtyp"
                                        selectedKeys={[type]}
                                        onChange={(e) => setType(e.target.value)}
                                    >
                                        {types.map((type) => (
                                            <SelectItem key={type.key}>{type.label}</SelectItem>
                                        ))}
                                    </Select>
                                    <div className="flex gap-2 items-center pb-4">
                                        <Input
                                            fullWidth
                                            label="Referenz"
                                            placeholder="Gib eine Referenz ein"
                                            variant="bordered"
                                            size="sm"
                                            value={value}
                                            onChange={(e) => setValue(e.target.value)}
                                            onKeyDown={(e) => {
                                                if (e.key === "Enter" && type !== "search") {
                                                    handleSearch();
                                                }
                                            }}
                                        />
                                        <Button isIconOnly color="primary" size="md" radius="full" aria-label="Search" onPress={handleSearch}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"><path fill="currentColor" fill-rule="evenodd" d="M11.5 2.75a8.75 8.75 0 1 0 0 17.5a8.75 8.75 0 0 0 0-17.5M1.25 11.5c0-5.66 4.59-10.25 10.25-10.25S21.75 5.84 21.75 11.5S17.16 21.75 11.5 21.75S1.25 17.16 1.25 11.5m18.22 7.97a.75.75 0 0 1 1.06 0l2 2a.75.75 0 1 1-1.06 1.06l-2-2a.75.75 0 0 1 0-1.06" clip-rule="evenodd" /></svg>
                                        </Button>
                                    </div>
                                    {isLoading && <Spinner className="mt-2" />}
                                    {error && <p className="text-red-500 mt-2">{error}</p>}
                                    <RadioGroup 
                                        className="w-full"
                                        value={selectedArticleId?.toString()}
                                        onValueChange={(value) => handleArticleSelect(parseInt(value))}
                                    >
                                        {articles?.map((article) => (
                                            <ModelChangeArticle 
                                                key={article.articleId} 
                                                value={article.articleId.toString()} 
                                                article={article}
                                            >
                                                {article.title}
                                            </ModelChangeArticle>
                                        ))}
                                    </RadioGroup>
                                </div>
                            </ScrollShadow>
                        </DrawerBody>

                        <DrawerFooter>
                            <Button 
                                color="primary" 
                                fullWidth
                                onPress={handleConfirmChange}
                                isDisabled={!selectedArticleId || isLoading}
                            >
                                Artikel übernehmen
                            </Button>
                        </DrawerFooter>
                    </>
                )}
            </DrawerContent>
        </Drawer>
    );
}

export const ModelChangeArticle = ({ article, value, ...props }) => {
    const {
        Component,
        children,
        getBaseProps,
        getWrapperProps,
        getInputProps,
        getLabelProps,
        getLabelWrapperProps,
        getControlProps,
    } = useRadio({ value, ...props });

    return (
        <Component
            {...getBaseProps()}
            className={cn(
                "group flex items-center hover:opacity-70 active:opacity-50 tap-highlight-transparent",
                "cursor-pointer border-2 border-default rounded-lg gap-4 p-4",
                "data-[selected=true]:border-primary"
            )}
        >
            <VisuallyHidden>
                <input {...getInputProps()} />
            </VisuallyHidden>
            {article?.imageUrl && (
                <img
                    src={article.imageUrl}
                    alt={article.title}
                    className="w-12 h-12 object-contain rounded-md"
                />
            )}

            <div {...getLabelWrapperProps()} className="flex flex-col flex-1">
                {children && <span {...getLabelProps()}>{children}</span>}
                {article?.eans?.length > 0 && (
                    <Tooltip content={<div className="w-80 text-small">{article.eans.join(", ")}</div>}>
                        <span className="text-small text-foreground opacity-70 line-clamp-1">
                            EANs: {article.eans.join(", ")}
                        </span>
                    </Tooltip>
                )}
            </div>
            <span {...getWrapperProps()} className="ml-auto">
                <span {...getControlProps()} />
            </span>
        </Component>
    );
};

