export class ArticleCheckQuestion {
    id: number;
    text: string;
    info: string;
    short?: string;
    checklistText?: string;
    checklistIcon?: string;
    checklistKeyword?: string;
    helpUrl?: string;
    isMandatory?: boolean;
    hideForRookie?: boolean;
    deliveryScopeIDs?: number[];
    type?: "text" | "select" | "boolean";
    textProperties?: TextProperties;
    dropdownProperties?: DropdownProperties;
    boolProperties?: BoolProperties;
  
    constructor(init: Partial<ArticleCheckQuestion>) {
      Object.assign(this, init);
    }
  
    static fromJson(json: any): ArticleCheckQuestion {
      return new ArticleCheckQuestion({
        id: json.id,
        text: json.question || json.text,
        info: json.infoText || json.info,
        short: json.short,
        checklistText: json.checklistText,
        checklistIcon: json.checklistIcon,
        checklistKeyword: json.checklistKeyword,
        helpUrl: json.helpUrl,
        isMandatory: json.isMandatory,
        hideForRookie: json.hideForRookie,
        deliveryScopeIDs: json.deliveryScopeIDs,
        type: json.type,
        textProperties: json.textProperties,
        dropdownProperties: json.dropdownProperties,
        boolProperties: json.boolProperties,
      });
    }
  
    static toJson(question: ArticleCheckQuestion): any {
      return {
        id: question.id,
        question: question.text,
        infoText: question.info,
        short: question.short,
        checklistText: question.checklistText,
        checklistIcon: question.checklistIcon,
        checklistKeyword: question.checklistKeyword,
        helpUrl: question.helpUrl,
        isMandatory: question.isMandatory,
        hideForRookie: question.hideForRookie,
        deliveryScopeIDs: question.deliveryScopeIDs,
        type: question.type,
        textProperties: question.textProperties,
        dropdownProperties: question.dropdownProperties,
        boolProperties: question.boolProperties,
      };
    }
  }
  
  export interface TextProperties {
    type?: "none" | "text" | "number";
    initialValue?: string;
  }
  
  export interface DropdownProperties {
    initialValue?: string;
    options?: DropdownOption[];
  }
  
  export interface BoolProperties {
    answerId?: string;
    answerText?: string;
    answerValue?: boolean;
  }
  
  export interface DropdownOption {
    answerId: string;
    answerText: string;
  }
  
  export class SellConditionQuestion {
    id: number;
    name: string;
  
    constructor(init: Partial<SellConditionQuestion>) {
      Object.assign(this, init);
    }
  
    static fromJson(json: any): SellConditionQuestion {
      return new SellConditionQuestion({
        id: json.id,
        name: json.name,
      });
    }
  
    static toJson(conditionQuestion: SellConditionQuestion): any {
      return {
        id: conditionQuestion.id,
        name: conditionQuestion.name,
      };
    }
  }