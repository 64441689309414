import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import userService from '@/services/userService';
import NoPermission from '@/components/NoPermission';
import EbayOverview from './overview';
import EbayOffer from './offer';

const Ebay: React.FC = () => {
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const id = params.get('id');

    const [activeComponent, setActiveComponent] = useState<string>('EbayOverview');

    const userHasPermission: boolean =
        userService.user.modules.includes(1);

    useEffect(() => {
        const path = location.pathname;
        if (path.startsWith('/admin-ebay/offer') && id) {
            setActiveComponent('EbayUpload');
        } else {
            setActiveComponent('EbayOverview');
        }
    }, [location.pathname, id]);

    if (!userHasPermission) {
        return <NoPermission />;
    }

    return (
        <>
            {activeComponent === 'EbayOverview' && <EbayOverview />}
            {activeComponent === 'EbayUpload' && (
                <EbayOffer id={id || ''} />
            )}
        </>
    );
};

export default Ebay;