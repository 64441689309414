import { EbayBase } from './EbayBase';
import { EbayRequest } from './EbayRequest';

export interface EbayDoc {
    id: string;
    values: Record<string, any>;
    answers: Record<string, any>;
    request: EbayRequest;
    imageUrls: string[];
}

export const EbayDoc = {
    fromJson: (json: any): EbayDoc => {
        return {
            id: json.id,
            values: json.values || {},
            answers: json.answers || {},
            request: EbayRequest.fromJson(json.request),
            imageUrls: json.imageUrls || []
        };
    }
}; 