import React, { useEffect, useState } from 'react';
import { 
  Modal, 
  ModalContent, 
  ModalHeader, 
  ModalBody, 
  ModalFooter, 
  Button,
  Spinner 
} from "@heroui/react";
import { Download, X, ChevronLeft, ChevronRight, ZoomIn, ZoomOut } from 'lucide-react';
import { Document, Page, pdfjs } from 'react-pdf';

// Configure PDF.js worker
// Note: You'll need to install the required packages and set up the worker
// npm install react-pdf
pdfjs.GlobalWorkerOptions.workerSrc = new URL(
    'pdfjs-dist/build/pdf.worker.min.mjs',
    import.meta.url,
  ).toString();  

const PDFViewerModal = ({ isOpen, onClose, fileUrl, fileName }) => {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [loading, setLoading] = useState(true);
  const [scale, setScale] = useState(1.0);
  const [error, setError] = useState(null);

  useEffect(() => {
    // Reset state when modal opens with a new file
    if (isOpen) {
      setPageNumber(1);
      setScale(1.0);
      setLoading(true);
      setError(null);
    }
  }, [isOpen, fileUrl]);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
    setLoading(false);
  };

  const onDocumentLoadError = (error) => {
    console.error("Error loading PDF:", error);
    setError("Failed to load the PDF document. Please try again later.");
    setLoading(false);
  };

  const handleDownload = () => {
    const link = document.createElement('a');
    link.href = fileUrl;
    link.download = fileName || 'document.pdf';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const goToPrevPage = () => {
    setPageNumber(prevPageNumber => Math.max(1, prevPageNumber - 1));
  };

  const goToNextPage = () => {
    setPageNumber(prevPageNumber => Math.min(numPages, prevPageNumber + 1));
  };

  const zoomIn = () => {
    setScale(prevScale => Math.min(2.5, prevScale + 0.1));
  };

  const zoomOut = () => {
    setScale(prevScale => Math.max(0.5, prevScale - 0.1));
  };

  return (
    <Modal 
      isOpen={isOpen} 
      onClose={onClose}
      size="5xl"
      scrollBehavior="inside"
      backdrop='blur'
    >
      <ModalContent>
        <ModalHeader className="flex items-center justify-between border-b">
          <h3 className="text-xl truncate">{fileName || 'Document'}</h3>
          <div className="flex items-center gap-2">
            <Button 
              isIconOnly 
              variant="light" 
              radius="full" 
              onClick={handleDownload}
              title="Download"
            >
              <Download size={20} />
            </Button>
            <Button 
              isIconOnly 
              variant="light" 
              radius="full" 
              onClick={onClose}
              title="Close"
            >
              <X size={20} />
            </Button>
          </div>
        </ModalHeader>
        <ModalBody className="p-0 flex flex-col items-center">
          {loading && (
            <div className="flex justify-center items-center w-full h-96">
              <Spinner size="lg" />
            </div>
          )}
          
          {error && (
            <div className="flex justify-center items-center w-full h-96 text-center p-4">
              <div>
                <p className="text-red-500">{error}</p>
                <Button 
                  onClick={handleDownload} 
                  color="primary" 
                  className="mt-4"
                  startContent={<Download size={16} />}
                >
                  Download PDF
                </Button>
              </div>
            </div>
          )}
          
          <div className="w-full min-h-[500px] flex justify-center bg-gray-100 dark:bg-gray-800">
            <Document
              file={fileUrl}
              onLoadSuccess={onDocumentLoadSuccess}
              onLoadError={onDocumentLoadError}
              loading={null}
            >
              <Page 
                pageNumber={pageNumber} 
                scale={scale}
                renderTextLayer={false}
                renderAnnotationLayer={false}
                className="py-4"
              />
            </Document>
          </div>
        </ModalBody>
        <ModalFooter className="flex justify-between border-t">
          <div className="flex items-center gap-2">
            <Button
              isIconOnly
              variant="light"
              isDisabled={scale <= 0.5}
              onClick={zoomOut}
              title="Zoom out"
            >
              <ZoomOut size={20} />
            </Button>
            <span className="text-sm">
              {Math.round(scale * 100)}%
            </span>
            <Button
              isIconOnly
              variant="light"
              isDisabled={scale >= 2.5}
              onClick={zoomIn}
              title="Zoom in"
            >
              <ZoomIn size={20} />
            </Button>
          </div>
          
          <div className="flex items-center gap-2">
            <Button
              isIconOnly
              variant="light"
              isDisabled={pageNumber <= 1}
              onClick={goToPrevPage}
              title="Previous page"
            >
              <ChevronLeft size={20} />
            </Button>
            <span className="text-sm">
              {pageNumber} / {numPages || '?'}
            </span>
            <Button
              isIconOnly
              variant="light"
              isDisabled={!numPages || pageNumber >= numPages}
              onClick={goToNextPage}
              title="Next page"
            >
              <ChevronRight size={20} />
            </Button>
          </div>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default PDFViewerModal;