export interface Category {
    id: number;
    title: string;
    alternativeTitle: string | null;
    buyActive: boolean;
    sellActive: boolean;
    subCategories: Category[];
}

export class CategoryModel implements Category {
    id: number;
    title: string;
    alternativeTitle: string | null;
    buyActive: boolean;
    sellActive: boolean;
    subCategories: Category[];

    constructor(data: Category) {
        this.id = data.id;
        this.title = data.title;
        this.alternativeTitle = data.alternativeTitle;
        this.buyActive = data.buyActive;
        this.sellActive = data.sellActive;
        this.subCategories = data.subCategories?.map(subCategory => new CategoryModel(subCategory));
    }

    static fromJson(json: any): CategoryModel {
        return new CategoryModel({
            id: json.id,
            title: json.title,
            alternativeTitle: json.alternativeTitle,
            buyActive: json.buyActive,
            sellActive: json.sellActive,
            subCategories: json.subCategories?.map((subCategory: any) => CategoryModel.fromJson(subCategory))
        });
    }
} 