import React from "react";
import { CardBody, Accordion, AccordionItem, Popover, PopoverTrigger, PopoverContent, Button, Tooltip, Alert, Input } from "@heroui/react";
import QRCode from "react-qr-code";
import WaWiCustomerComponent from "../../wawi/WaWiCustomerComponent";
import { ArticleCheckModel } from "../models/article";
import { ArticleQueryType } from "../models/queryTypes";
import { Icon } from "@iconify/react/dist/iconify.js";

// EXPADABLE CLASSES
const expandableClasses = {
    base: "py-0 w-full",
    trigger: "data-[hover=true]:bg-default-100 rounded-lg h-14 flex items-center",
};

interface ArticleSidebarProps {
    article: ArticleCheckModel;
    id: ArticleQueryType;
    type: string;
    canSeeCustomerData: boolean;
    canViewPrice: boolean;
    canEditPrice: boolean;
    onCameraOpen: () => void;
    onGalleryOpen: () => void;
    onLabelOpen: () => void;
    onPriceEditOpen: () => void;
}

const ArticleSidebar: React.FC<ArticleSidebarProps> = ({
    article,
    id,
    type,
    canSeeCustomerData,
    canViewPrice,
    canEditPrice,
    onCameraOpen,
    onGalleryOpen,
    onLabelOpen,
    onPriceEditOpen
}) => {
    const isDemoMode = type !== ArticleQueryType.detailId;

    return (
        <>
            <CardBody>
                <div className="grid grid-cols-1 sm:grid-cols-3 gap-2 max-w-80 mx-auto mb-3">
                    <div
                        className="aspect-square border flex items-center flex-col justify-center rounded-lg transition-colors hover:bg-primary group cursor-pointer"
                        onClick={onCameraOpen}
                    >
                        <Icon
                            className="text-default-500 group-hover:text-white"
                            icon="solar:camera-outline"
                            width={24}
                        />
                        <span className="text-foreground-500 font-normal text-small group-hover:text-white">
                            Kamera
                        </span>
                    </div>
                    <div
                        className="aspect-square border flex items-center flex-col justify-center rounded-lg transition-colors hover:bg-primary group cursor-pointer"
                        onClick={onGalleryOpen}
                    >
                        <Icon
                            className="text-default-500 group-hover:text-white"
                            icon="solar:gallery-wide-outline"
                            width={24}
                        />
                        <span className="text-foreground-500 font-normal text-small group-hover:text-white">
                            Galerie
                        </span>
                    </div>
                    <div className="aspect-square border flex items-center justify-center rounded-lg cursor-pointer">
                        <Popover showArrow backdrop="blur">
                            <PopoverTrigger>
                                <QRCode value={`checkimage-${id}`} className="w-3/4 h-3/4" />
                            </PopoverTrigger>
                            <PopoverContent>
                                <div className="px-1 py-2 max-w-72">
                                    <div className="text-small font-bold">Hilfe</div>
                                    <div className="text-small">Scannne diesen QR-Code im Fotomodul der inteam-App, um Fotos am Handy zu erstellen.</div>
                                </div>
                            </PopoverContent>
                        </Popover>
                    </div>
                </div>
                <Accordion
                    isCompact
                    selectionMode="multiple"
                    showDivider={false}
                    itemClasses={expandableClasses}
                >
                    <AccordionItem
                        key="notes"
                        aria-label="Interne Notizen"
                        startContent={<Icon
                            className="text-default-500"
                            icon="solar:document-add-outline"
                            width={24}
                        />}
                        subtitle={`10 interne Notizen`}
                        title="Interne Notizen"
                    >
                        <div className="pt-4 pb-4 px-3">{0 > 0 ? (
                            <div></div>
                            // order.internalNotes.map((note, index) => (
                            //     <div key={index}>
                            //         <div
                            //             className="text-small text-default-500"
                            //             dangerouslySetInnerHTML={{
                            //                 __html: note.replace(/(<br\s*\/?>|\r?\n)+$/g, ""),
                            //             }}
                            //         />
                            //         {index < order.internalNotes.length - 1 && <hr className="my-4" />}
                            //     </div>
                            // ))
                        ) : (
                            <p className="text-small text-default-500">Keine Notizen verfügbar</p>
                        )}</div>
                    </AccordionItem>
                    <AccordionItem
                        key="customer"
                        aria-label="Kundendaten"
                        startContent={<Icon
                            className="text-default-500"
                            icon="solar:user-id-outline"
                            width={24}
                        />}
                        subtitle="Stammdaten aus dem Kundencenter"
                        title="Kundendaten"
                    >
                        {
                            canSeeCustomerData ? (
                                <>
                                    <div className="pt-4 pb-4">
                                        <WaWiCustomerComponent type={type} id={id} />
                                    </div>
                                </>
                            ) : (
                                <div className="pt-4 pb-4 text-center max-w-[500px] text-center">
                                    <div className="flex justify-center mb-4">
                                        {/* <Image
                                            width={150}
                                            alt="Map ZOXSY"
                                            src={mapZoxsy}
                                        /> */}
                                    </div>
                                    <h2 className="text-xl font-bold mb-2">Ups - Du hast Dich wohl verlaufen.</h2>
                                    <p className="text-default-500 mb-6">Für diese Aktion hast Du keine Berechtigung.</p>
                                    <div className="flex flex-col gap-3">
                                        <Button color="secondary">
                                            Recht anfragen
                                        </Button>
                                    </div>
                                </div>
                            )
                        }
                    </AccordionItem>
                </Accordion>
            </CardBody>
        </>
    );
};

export default ArticleSidebar;