import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import userService from '@/services/userService';
import NoPermission from '@/components/NoPermission';
import MailOverview from './Mail';
import MailEditorEasyEmail from './MailEasyEmail';
import MailEditorGrapes from './MailEditorGrapes';

const MailPage = () => {
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const id = params.get('id');

    const [activeComponent, setActiveComponent] = useState('Mail');
    const userHasPermission = userService.user.modules.includes(15);

    useEffect(() => {
        const path = location.pathname;
        if (path.startsWith('/admin-mail/grapes/create')) {
            setActiveComponent('MailEditorCreateG');
        } else if (path.startsWith('/admin-mail/easyemail/create')) {
            setActiveComponent('MailEditorCreateE');
        } else if (path.startsWith('/admin-mail/grapes/edit') && id) {
            setActiveComponent('MailEditorEditG');
        } else if (path.startsWith('/admin-mail/easyemail/edit') && id) {
            setActiveComponent('MailEditorEditE');
        } else if (path.startsWith('/admin-mail/preview') && id) {
            setActiveComponent('MailPreview');
        } else {
            setActiveComponent('Mail');
        }
    }, [location.pathname, id]);

    if (!userHasPermission) {
        return <NoPermission />;
    }

    return (
        <>
            {activeComponent === 'Mail' && <MailOverview />}
            {activeComponent === 'MailEditorCreateG' && <MailEditorGrapes />}
            {activeComponent === 'MailEditorCreateE' && <MailEditorEasyEmail />}
            {activeComponent === 'MailEditorEditG' && <MailEditorGrapes id={id} />}
            {activeComponent === 'MailEditorEditE' && <MailEditorEasyEmail id={id} />}
            {activeComponent === 'MailPreview' && <div id={id} />}
        </>
    );
};

export default MailPage;
