class AppUser {
    constructor({
        id,
        firstname,
        surname,
        name,
        mail,
        phone,
        department,
        location,
        isHeadOf = false,
        dateOfEntry,
        dateOfBirth = null,
        latestRefresh,
        avatarUrl = null,
        paymentPreferences = null,
        birthdayPreferences = null,
    }) {
        this.id = id;
        this.firstname = firstname;
        this.surname = surname;
        this.name = name;
        this.mail = mail;
        this.phone = phone;
        this.department = department;
        this.location = location;
        this.isHeadOf = isHeadOf;
        this.dateOfEntry = new Date(dateOfEntry);
        this.dateOfBirth = dateOfBirth ? new Date(dateOfBirth) : null;
        this.latestRefresh = new Date(latestRefresh._seconds * 1000 + latestRefresh._nanoseconds / 1000000);
        this.avatarUrl = avatarUrl; 
        this.paymentPreferences = paymentPreferences;
        this.birthdayPreferences = birthdayPreferences;
    }

    static fromJson(json) {
        return new AppUser({
            id: json.id,
            firstname: json.firstname,
            surname: json.surname,
            name: json.name,
            mail: json.mail || '',
            phone: json.phone,
            department: json.department,
            location: json.location,
            isHeadOf: json.isHeadOf,
            dateOfEntry: json.dateOfEntry,
            latestRefresh: json.latestRefresh,
            avatarUrl: json.avatarUrl,
        });
    }

    updateUserInfo(newInfo) {
        Object.assign(this, newInfo);
    }

    validatePhoneNumber() {
        const phonePattern = /^\+?[1-9]\d{1,14}$/;
        if (!phonePattern.test(this.phone)) {
            throw new Error(`Invalid phone number: ${this.phone}`);
        }
    }

    toJson() {
        return {
            id: this.id,
            firstname: this.firstname,
            surname: this.surname,
            name: this.name,
            mail: this.mail,
            phone: this.phone,
            department: this.department,
            location: this.location,
            isHeadOf: this.isHeadOf,
            dateOfEntry: this.dateOfEntry.toISOString(),
            latestRefresh: {
                _seconds: Math.floor(this.latestRefresh.getTime() / 1000),
                _nanoseconds: (this.latestRefresh.getTime() % 1000) * 1000000,
            },
            avatarUrl: this.avatarUrl,
        };
    }
}

export { AppUser };