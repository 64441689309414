import { FormField } from './FormField';

export class EbayBase {
  category_id: number;
  epid: string | null;
  sn: string | null;
  images: string[];
  formFields: FormField[];

  constructor(data: {
    category_id: number;
    epid: string | null;
    sn: string | null;
    images: string[];
    formFields: FormField[];
  }) {
    this.category_id = data.category_id;
    this.epid = data.epid;
    this.sn = data.sn;
    this.images = data.images || [];
    this.formFields = data.formFields || [];
  }

  static fromJson(json: any): EbayBase {
    return new EbayBase({
      category_id: json.category_id || 0,
      epid: json.epid || null,
      sn: json.sn || null,
      images: Array.isArray(json.images) ? json.images : [],
      formFields: Array.isArray(json.formFields) 
        ? json.formFields
            .map((field: any) => FormField.fromJson(field))
            .filter((field): field is FormField => field !== null)
        : []
    });
  }

  toJson(): any {
    return {
      formFields: this.formFields.map(FormField.toJson),
      sn: this.sn,
      epid: this.epid,
      category_id: this.category_id,
      images: this.images,
    };
  }
} 