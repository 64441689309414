import React, { useEffect, useState, useMemo, useCallback } from 'react';
import peopleService from '../services/peopleService';
import {
  Table,
  TableHeader,
  TableColumn,
  TableBody,
  TableRow,
  TableCell,
  Input,
  Button,
  DropdownTrigger,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  User,
  Spinner,
  Spacer,
  Link,
  Card,
  CardBody,
  CardHeader,
  Badge,
  Chip,
  Tooltip,
  Divider
} from "@heroui/react";
import Location from '../enums/location';
import Department from "../enums/department";
import AgeHelper from '../helpers/getAgeHelper';
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import dayjs from 'dayjs';
import 'dayjs/locale/de';
import relativeTime from 'dayjs/plugin/relativeTime';
import TimeAgo from 'react-timeago'
import germanStrings from 'react-timeago/lib/language-strings/de'
import buildFormatter from 'react-timeago/lib/formatters/buildFormatter'
import userService from '../services/userService';
import { Icon } from '@iconify/react/dist/iconify.js';

const formatter = buildFormatter(germanStrings)
dayjs.extend(relativeTime);
dayjs.locale('de');

const initial_columns_users = ["person", "dateOfEntry", "mail"];
const initial_columns_theme = ["person", "dateOfEntry"];

/**
 * Retrieves a user's profile picture from Firebase Storage
 */
const checkProfilePicture = async (id) => {
  const fileExtensions = ['png', 'jpg'];
  for (const ext of fileExtensions) {
    const filePath = `internal/users/${id}/public/${id}.${ext}`;
    const storage = getStorage();
    const fileRef = ref(storage, filePath);
    try {
      const downloadUrl = await getDownloadURL(fileRef);
      return downloadUrl;
    } catch (error) {
      // Silent fail - continue to next extension
    }
  }
  return null;
};

/**
 * Formats a date in German locale
 */
const formatDate = (dateStr) => {
  const date = new Date(dateStr);
  const options = { year: 'numeric', month: 'long', day: 'numeric' };
  return date.toLocaleDateString('de-DE', options);
};

/**
 * Adapts raw user data into the format needed for display
 */
const adaptUserData = async (user) => {
  const profilePictureUrl = await checkProfilePicture(user.id);

  return {
    id: user.id || Math.random().toString(36).substr(2, 9),
    name: user.name,
    isHeadOf: user.isHeadOf,
    dateOfBirth: user.dateOfBirth,
    dateOfEntry: user.dateOfEntry,
    location: user.location && Location[user.location]
      ? Location[user.location].locationName
      : 'Unbekannter Ort',
    department: user.department && Department[user.department]
      ? Department[user.department].departmentName
      : 'Unbekannte Abteilung',
    mail: user.mail,
    phone: user.phone ? user.phone : null,
    latestRefresh: user.latestRefresh,
    profilePictureUrl: profilePictureUrl,
  };
};

const People = () => {
  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState([]);
  const [newbies, setNewbies] = useState([]);
  const [nextAnniversaries, setNextAnniversaries] = useState([]);

  const [departmentFilter, setDepartmentFilter] = useState(new Set(["all"]));
  const [locationFilter, setLocationFilter] = useState(new Set(["all"]));
  const [visibleColumns, setVisibleColumns] = useState(new Set(initial_columns_users));
  const [visibleColumnsTheme] = useState(new Set(initial_columns_theme));
  const [columns, setColumns] = useState([
    { name: "Person", uid: "person" },
    { name: "Einstiegsdatum", uid: "dateOfEntry", sortable: true },
    { name: "E-Mail", uid: "mail" },
    { name: "Telefon", uid: "phone" },
    { name: "Zuletzt eingeloggt", uid: "latestRefresh", sortable: true },
  ]);

  const [filterValue, setFilterValue] = useState("");
  const [selectedKeys, setSelectedKeys] = useState(new Set([]));
  const [sortDescriptor, setSortDescriptor] = useState({
    column: "person",
    direction: "ascending",
  });

  // Prevent scroll to top when filters change
  useEffect(() => {
    const preventScrollReset = (e) => {
      e.preventDefault();
      e.stopPropagation();
    };
    
    window.addEventListener('scroll', preventScrollReset, { passive: false });
    return () => window.removeEventListener('scroll', preventScrollReset);
  }, []);

  // Check for admin privileges
  useEffect(() => {
    const fetchModules = async () => {
      const user = await userService.getUser();
      if (user.modules.includes(6)) {
        setColumns(prevColumns => [
          { name: "ID", uid: "id", sortable: true },
          { name: "Person", uid: "person" },
          { name: "Geburtstag", uid: "dateOfBirth" },
          { name: "Einstiegsdatum", uid: "dateOfEntry", sortable: true },
          { name: "E-Mail", uid: "mail" },
          { name: "Telefon", uid: "phone" },
          { name: "Zuletzt eingeloggt", uid: "latestRefresh", sortable: true },
          { name: "Optionen", uid: "actions" },
        ]);
      }
    };

    fetchModules();
  }, []);

  // Fetch all user data
  useEffect(() => {
    const fetchUsers = async () => {
      setLoading(true);
      try {
        const fetchedUsers = await peopleService.getAllUsers();
        const categorizedUsers = await peopleService.categorizeUsers(fetchedUsers);

        const allUsers = await Promise.all(
          categorizedUsers.allUsers.map(user => adaptUserData(user))
        );
        const newbies = await Promise.all(
          categorizedUsers.newbies.map(user => adaptUserData(user))
        );
        const nextAnniversaries = await Promise.all(
          categorizedUsers.nextAnniversaries.map(user => adaptUserData(user))
        );

        setUsers(allUsers);
        setNewbies(newbies);
        setNextAnniversaries(nextAnniversaries);
      } catch (error) {
        console.error("Failed to fetch users:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchUsers();
  }, []);

  const hasSearchFilter = Boolean(filterValue);

  const headerColumns = useMemo(() => {
    if (visibleColumns === "all") return columns;
    return columns.filter((column) => Array.from(visibleColumns).includes(column.uid));
  }, [visibleColumns, columns]);

  const headerColumnsTheme = useMemo(() => {
    if (visibleColumnsTheme === "all") return columns;
    return columns.filter((column) => Array.from(visibleColumnsTheme).includes(column.uid));
  }, [visibleColumnsTheme, columns]);

  // Filter users based on search and dropdown selections
  const filteredItems = useMemo(() => {
    let filteredUsers = [...users];

    if (hasSearchFilter) {
      filteredUsers = filteredUsers.filter((user) =>
        user.name.toLowerCase().includes(filterValue.toLowerCase()),
      );
    }

    // Department filtering
    const departmentArray = Array.from(departmentFilter);

    if (departmentFilter.has("all") && departmentArray[departmentArray.length - 1] !== "all") {
      departmentFilter.delete("all");
    }

    if (departmentFilter.size === Object.keys(Department).length) {
      departmentFilter.clear();
      departmentFilter.add("all");
    }

    if (!departmentFilter.has("all") && departmentFilter.size > 0) {
      filteredUsers = filteredUsers.filter((user) =>
        Array.from(departmentFilter).some(department =>
          user.department === department
        )
      );
    }

    if (departmentFilter.has('all')) {
      departmentFilter.clear();
      departmentFilter.add("all");
    }

    // Location filtering
    const locationArray = Array.from(locationFilter);

    if (locationFilter.has("all") && locationArray[locationArray.length - 1] !== "all") {
      locationFilter.delete("all");
    }

    if (locationFilter.size === Object.keys(Location).length) {
      locationFilter.clear();
      locationFilter.add("all");
    }

    if (!locationFilter.has("all") && locationFilter.size > 0) {
      filteredUsers = filteredUsers.filter((user) =>
        Array.from(locationFilter).some(location =>
          user.location === location
        )
      );
    }

    if (locationFilter.has('all')) {
      locationFilter.clear();
      locationFilter.add("all");
    }

    return filteredUsers;
  }, [users, filterValue, departmentFilter, locationFilter, hasSearchFilter]);

  const items = useMemo(() => {
    return filteredItems;
  }, [filteredItems]);

  // Sort items based on current sort descriptor
  const sortedItems = useMemo(() => {
    return [...items].sort((a, b) => {
      const first = a[sortDescriptor.column];
      const second = b[sortDescriptor.column];
      const cmp = first < second ? -1 : first > second ? 1 : 0;

      return sortDescriptor.direction === "descending" ? -cmp : cmp;
    });
  }, [sortDescriptor, items]);

  // Render individual table cells
  const renderCell = useCallback((user, columnKey) => {
    const cellValue = user[columnKey];
    switch (columnKey) {
      case "person":
        return (
          <User
            avatarProps={{ 
              src: user.profilePictureUrl, 
              name: user.name,
              className: "transition-transform hover:scale-110"
            }}
            description={
              <div className="flex items-center gap-2">
                {user.isHeadOf && (
                  <Chip size="sm" color="primary" variant="flat">Head-Of</Chip>
                )}
                <span>{user.department}</span>
                <Divider orientation="vertical" className="h-4" />
                <span>{user.location}</span>
              </div>
            }
            name={user.name}
            className="transition-opacity hover:opacity-80"
          />
        );
      case "dateOfBirth":
        return user.dateOfBirth ? (
          <Tooltip content={`Alter: ${AgeHelper.get(user.dateOfBirth)} Jahre`}>
            <span className="cursor-help">{formatDate(user.dateOfBirth)}</span>
          </Tooltip>
        ) : null;
      case "dateOfEntry":
        return user.dateOfEntry ? (
          <Tooltip content={`Bei ZOXS seit ${dayjs().diff(dayjs(user.dateOfEntry), 'days')} Tagen`}>
            <span className="cursor-help">{formatDate(user.dateOfEntry)}</span>
          </Tooltip>
        ) : null;
      case "mail":
        return (
          <Link 
            href={`mailto:${user.mail}`}
            className="flex items-center gap-2 transition-colors hover:text-primary"
          >
            <Icon icon="solar:mail-linear" width={16} />
            {user.mail}
          </Link>
        );
      case "phone":
        return user.phone ? (
          <Link 
            href={`tel:${user.phone}`}
            className="flex items-center gap-2 transition-colors hover:text-primary"
          >
            <Icon icon="solar:phone-linear" width={16} />
            {user.phone}
          </Link>
        ) : null;
      case "latestRefresh":
        return user.latestRefresh ? (
          <div className="flex items-center gap-2">
            <Icon icon="solar:clock-circle-linear" width={16} />
            <TimeAgo date={user.latestRefresh} formatter={formatter} />
          </div>
        ) : null;
      case "actions":
        return (
          <div className="relative flex justify-end items-center gap-2">
            <Dropdown className="w-[250px]">
              <DropdownTrigger>
                <Button 
                  isIconOnly 
                  size="sm" 
                  variant="light"
                  className="transition-colors hover:bg-default-100"
                >
                  <Icon icon="solar:menu-dots-vertical-linear" width={18} />
                </Button>
              </DropdownTrigger>
              <DropdownMenu>
                <DropdownItem
                  description="Unsichtbar schalten"
                  startContent={<Icon icon="solar:forbidden-circle-linear" width={18} />}
                >
                  Deaktivieren
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </div>
        );
      default:
        return cellValue;
    }
  }, []);

  // Search handlers
  const onSearchChange = useCallback((value) => {
    if (value) {
      setFilterValue(value);
    } else {
      setFilterValue("");
    }
  }, []);

  const onClear = useCallback(() => {
    setFilterValue("");
  }, []);

  // Top content with filters and search
  const topContent = useMemo(() => {
    return (
      <div className="flex flex-col gap-4">
        <div className="flex flex-col sm:flex-row justify-between gap-3 items-start sm:items-end">
          <Input
            isClearable
            className="w-full sm:max-w-[44%]"
            variant="bordered"
            placeholder="Suche nach Namen..."
            startContent={
              <Icon
                className="text-default-400 pointer-events-none"
                height={18}
                icon="solar:magnifer-linear"
                width={18}
              />
            }
            value={filterValue}
            onClear={onClear}
            onValueChange={onSearchChange}
            size="sm"
            classNames={{
              inputWrapper: "shadow-sm bg-white dark:bg-gray-800"
            }}
          />
          <div className="flex flex-wrap gap-2 sm:gap-3 w-full sm:w-auto">
            <Dropdown>
              <DropdownTrigger>
                <Button 
                  endContent={<Icon icon="solar:alt-arrow-down-linear" width={16} />} 
                  variant="flat"
                  size="sm"
                  className="w-full sm:w-auto"
                >
                  Abteilungen
                </Button>
              </DropdownTrigger>
              <DropdownMenu
                disallowEmptySelection
                aria-label="Abteilungen"
                closeOnSelect={false}
                selectedKeys={departmentFilter}
                selectionMode="multiple"
                onSelectionChange={setDepartmentFilter}
              >
                <DropdownItem key="all">Alle Abteilungen</DropdownItem>
                {Object.keys(Department).map((deptKey) => (
                  <DropdownItem key={Department[deptKey].departmentName}>
                    {Department[deptKey].departmentName}
                  </DropdownItem>
                ))}
              </DropdownMenu>
            </Dropdown>

            <Dropdown>
              <DropdownTrigger>
                <Button 
                  endContent={<Icon icon="solar:alt-arrow-down-linear" width={16} />} 
                  variant="flat"
                  size="sm"
                  className="w-full sm:w-auto"
                >
                  Standorte
                </Button>
              </DropdownTrigger>
              <DropdownMenu
                disallowEmptySelection
                aria-label="Standorte"
                closeOnSelect={false}
                selectedKeys={locationFilter}
                selectionMode="multiple"
                onSelectionChange={setLocationFilter}
              >
                <DropdownItem key="all">Alle Standorte</DropdownItem>
                {Object.keys(Location).map((locKey) => (
                  <DropdownItem key={Location[locKey].locationName}>
                    {Location[locKey].locationName}
                  </DropdownItem>
                ))}
              </DropdownMenu>
            </Dropdown>
            <Dropdown>
              <DropdownTrigger>
                <Button 
                  endContent={<Icon icon="solar:alt-arrow-down-linear" width={16} />}
                  variant="flat"
                  size="sm"
                  className="w-full sm:w-auto"
                >
                  Spalten
                </Button>
              </DropdownTrigger>
              <DropdownMenu
                disallowEmptySelection
                aria-label="Table Columns"
                closeOnSelect={false}
                selectedKeys={visibleColumns}
                selectionMode="multiple"
                onSelectionChange={setVisibleColumns}
              >
                {columns.map((column) => (
                  <DropdownItem key={column.uid}>
                    {column.name}
                  </DropdownItem>
                ))}
              </DropdownMenu>
            </Dropdown>
          </div>
        </div>
        <div className="flex justify-between items-center">
          <Badge content={users.length} color="primary" size="sm">
            <span className="text-default-600 text-sm font-medium">Anzahl Nutzer</span>
          </Badge>
        </div>
      </div>
    );
  }, [
    filterValue,
    departmentFilter,
    locationFilter,
    visibleColumns,
    users.length,
    onSearchChange,
    onClear,
    columns,
  ]);

  // Loading state component
  const LoadingState = () => (
    <div className="flex justify-center items-center py-8">
      <Spinner color="primary" size="lg" />
    </div>
  );

  // Section component for consistent styling
  const TeamSection = ({ title, description, children }) => (
    <Card className="w-full rounded-lg border bg-background shadow p-2">
      <CardHeader className="flex flex-col items-start">
        <h3 className="text-lg font-semibold text-default-800">{title}</h3>
        <p className="text-sm text-default-500">{description}</p>
      </CardHeader>
      <CardBody className="px-0 py-0">
        {children}
      </CardBody>
    </Card>
  );

  return (
    <div className="w-full flex-1 p-4 space-y-4">
      {/* Header */}
      <div className="mb-2">
        <div className="flex items-center gap-x-3">
          <h1 className="text-3xl font-bold text-default-900">Team</h1>
        </div>
        <h2 className="mt-2 text-default-600">
          Lerne Deine Teamkollegen kennen und entdecke, wer in welcher Abteilung und an welchem Standort arbeitet.
        </h2>
      </div>

      {/* Newbies Section */}
      <TeamSection 
        title="Newbies" 
        description="Neue Kollegen, die in den letzten 30 Tagen angefangen haben."
      >
        {loading ? (
          <LoadingState />
        ) : (
          <Table
            aria-label="Newbies"
            isHeaderSticky
            bottomContentPlacement="outside"
            removeWrapper
            topContentPlacement="outside"
            classNames={{
              base: "px-4",
              th: "bg-default-50 text-default-700",
              td: "py-4",
              emptyWrapper: "py-8"
            }}
          >
            <TableHeader columns={headerColumnsTheme}>
              {(column) => (
                <TableColumn
                  key={column.uid}
                  align={column.uid === "actions" ? "center" : "start"}
                >
                  {column.name}
                </TableColumn>
              )}
            </TableHeader>
            <TableBody 
              emptyContent={
                <div className="flex flex-col items-center py-4">
                  <Icon icon="solar:emoji-funny-square-linear" width={40} height={40} className="text-default-300 mb-2" />
                  <p>Keine neuen Teammitglieder in den letzten 30 Tagen</p>
                </div>
              } 
              items={newbies}
            >
              {(item) => (
                <TableRow key={item.id} className="transition-colors hover:bg-default-50">
                  {(columnKey) => <TableCell>{renderCell(item, columnKey)}</TableCell>}
                </TableRow>
              )}
            </TableBody>
          </Table>
        )}
      </TeamSection>

      {/* Anniversaries Section */}
      <TeamSection 
        title="Jubiläen" 
        description="Kollegen, die in den nächsten 30 Tagen ihren ZOXS-Geburtstag feiern."
      >
        {loading ? (
          <LoadingState />
        ) : (
          <Table
            aria-label="Anniversaries"
            isHeaderSticky
            bottomContentPlacement="outside"
            removeWrapper
            topContentPlacement="outside"
            classNames={{
              base: "px-4",
              th: "bg-default-50 text-default-700",
              td: "py-4",
              emptyWrapper: "py-8"
            }}
          >
            <TableHeader columns={headerColumnsTheme}>
              {(column) => (
                <TableColumn
                  key={column.uid}
                  align={column.uid === "actions" ? "center" : "start"}
                >
                  {column.name}
                </TableColumn>
              )}
            </TableHeader>
            <TableBody 
              emptyContent={
                <div className="flex flex-col items-center py-4">
                  <Icon icon="solar:confetti-linear" width={40} height={40} className="text-default-300 mb-2" />
                  <p>Keine Jubiläen in den nächsten 30 Tagen</p>
                </div>
              } 
              items={nextAnniversaries}
            >
              {(item) => (
                <TableRow key={item.id} className="transition-colors hover:bg-default-50">
                  {(columnKey) => <TableCell>{renderCell(item, columnKey)}</TableCell>}
                </TableRow>
              )}
            </TableBody>
          </Table>
        )}
      </TeamSection>

      {/* All Users Section */}
      <TeamSection 
        title="Alle Personen" 
        description="Bitte beachte, dass Dir hier nur Mitglieder angezeigt werden, die sich mindestens 1x eingeloggt haben."
      >
        {loading ? (
          <LoadingState />
        ) : (
          <Table
            aria-label="Users"
            isHeaderSticky
            bottomContentPlacement="outside"
            selectedKeys={selectedKeys}
            sortDescriptor={sortDescriptor}
            removeWrapper
            topContent={topContent}
            topContentPlacement="outside"
            onSelectionChange={setSelectedKeys}
            onSortChange={setSortDescriptor}
            classNames={{
              base: "px-4",
              th: "bg-default-50 text-default-700",
              td: "py-4",
              emptyWrapper: "py-8"
            }}
          >
            <TableHeader columns={headerColumns}>
              {(column) => (
                <TableColumn
                  key={column.uid}
                  align={column.uid === "actions" ? "center" : "start"}
                  allowsSorting={column.sortable}
                >
                  {column.name}
                </TableColumn>
              )}
            </TableHeader>
            <TableBody 
              emptyContent={
                <div className="flex flex-col items-center py-4">
                  <Icon icon="solar:users-group-broken-linear" width={40} height={40} className="text-default-300 mb-2" />
                  <p>Keine Nutzer gefunden</p>
                </div>
              } 
              items={sortedItems}
            >
              {(item) => (
                <TableRow key={item.id} className="transition-colors hover:bg-default-50">
                  {(columnKey) => <TableCell>{renderCell(item, columnKey)}</TableCell>}
                </TableRow>
              )}
            </TableBody>
          </Table>
        )}
      </TeamSection>
    </div>
  );
};

export default People;