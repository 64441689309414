'use client';

import { useState, useRef } from 'react';
import { Button, Card, CardBody } from '@heroui/react';
import { Icon } from '@iconify/react/dist/iconify.js';

interface ImageFile {
    id: string;
    file: File;
    preview: string;
}

interface ImageUploadProps {
    images: ImageFile[];
    onImagesChange: (images: ImageFile[]) => void;
}

export default function ImageUpload({ images, onImagesChange }: ImageUploadProps) {
    const [imageError, setImageError] = useState<string | null>(null);
    const fileInputRef = useRef<HTMLInputElement>(null);

    const validateImage = (file: File): string | null => {
        const validTypes = ['image/jpeg', 'image/png', 'image/gif', 'image/tiff', 'image/bmp', 'image/webp', 'image/heic', 'image/avif'];
        const maxSize = 12 * 1024 * 1024; // 12MB
        const maxImages = 24;

        if (!validTypes.includes(file.type)) {
            return 'Nur JPEG, PNG, GIF, TIFF, BMP, WEBP, HEIC oder AVIF Dateien sind erlaubt';
        }

        if (file.size > maxSize) {
            return 'Die Datei darf nicht größer als 12MB sein';
        }

        if (images.length >= maxImages) {
            return 'Maximal 24 Bilder sind erlaubt';
        }

        return null;
    };

    const handleFileSelect = (files: FileList | null) => {
        if (!files) return;

        setImageError(null);

        Array.from(files).forEach(file => {
            const error = validateImage(file);
            if (error) {
                setImageError(error);
                return;
            }

            const reader = new FileReader();
            reader.onload = (e) => {
                const newImage: ImageFile = {
                    id: Math.random().toString(36).substr(2, 9),
                    file,
                    preview: e.target?.result as string
                };
                onImagesChange([...images, newImage]);
            };
            reader.readAsDataURL(file);
        });
    };

    const handleDragStart = (e: React.DragEvent, index: number) => {
        e.dataTransfer.setData('text/plain', index.toString());
    };

    const handleDragOver = (e: React.DragEvent) => {
        e.preventDefault();
    };

    const handleDrop = (e: React.DragEvent, dropIndex: number) => {
        e.preventDefault();
        const dragIndex = parseInt(e.dataTransfer.getData('text/plain'));

        if (dragIndex === dropIndex) return;

        const newImages = [...images];
        const [draggedItem] = newImages.splice(dragIndex, 1);
        newImages.splice(dropIndex, 0, draggedItem);
        onImagesChange(newImages);
    };

    const deleteImage = (id: string) => {
        onImagesChange(images.filter(img => img.id !== id));
    };

    return (
        <Card className="w-full rounded-lg border bg-background shadow">
            <CardBody>
                <div
                    className="border-2 border-dashed border-gray-300 rounded-lg p-4 text-center hover:border-primary-500 transition-colors cursor-pointer"
                    onDragOver={(e) => {
                        e.preventDefault();
                        e.currentTarget.classList.add('border-primary-500');
                    }}
                    onDragLeave={(e) => {
                        e.currentTarget.classList.remove('border-primary-500');
                    }}
                    onDrop={(e) => {
                        e.preventDefault();
                        e.currentTarget.classList.remove('border-primary-500');
                        handleFileSelect(e.dataTransfer.files);
                    }}
                >
                    <div className="text-gray-500">
                        <p className="mb-2">Bilder hier ablegen</p>
                        <p className="text-sm">oder</p>
                        <input
                            type="file"
                            ref={fileInputRef}
                            className="hidden"
                            multiple
                            accept="image/jpeg,image/png,image/gif,image/tiff,image/bmp,image/webp,image/heic,image/avif"
                            onChange={(e) => handleFileSelect(e.target.files)}
                        />
                        <Button
                            variant='bordered'
                            color="primary"
                            className="mt-2"
                            onPress={() => fileInputRef.current?.click()}
                        >
                            Dateien auswählen
                        </Button>
                    </div>
                </div>
                {imageError && (
                    <p className="text-red-500 text-sm mt-2">{imageError}</p>
                )}
                {images.length > 0 && (
                    <div className="mt-4 flex flex-col gap-4">
                        {images.map((image, index) => (
                            <div
                                key={image.id}
                                className="relative group w-full"
                                draggable
                                onDragStart={(e) => handleDragStart(e, index)}
                                onDragOver={handleDragOver}
                                onDrop={(e) => handleDrop(e, index)}
                            >
                                <img
                                    src={image.preview}
                                    alt={`Bild ${index + 1}`}
                                    className="w-full object-contain rounded-lg bg-gray-50"
                                />
                                <Button
                                    isIconOnly
                                    color="primary"
                                    variant="solid"
                                    radius="full"
                                    className="absolute top-2 right-2 opacity-0 group-hover:opacity-100 transition-opacity"
                                    onPress={() => deleteImage(image.id)}
                                >
                                    <Icon
                                        className="text-white"
                                        icon="solar:trash-bin-trash-outline"
                                        width={20}
                                    />
                                </Button>
                            </div>
                        ))}
                    </div>
                )}
            </CardBody>
        </Card>
    );
} 