'use client';

import { useEditor, EditorContent } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import TextAlign from '@tiptap/extension-text-align';
import EditorMenuBar from './ArticleEditorMenuBar';
import { useEffect } from 'react';
import { Spinner } from '@heroui/react';

interface ArticleEditorProps {
    value: string;
    onChange: (value: string) => void;
    label?: string;
    placeholder?: string;
    isLoading?: boolean;
    articleTitle?: string;
    articleConditionOrigin?: string;
    articleConditionNew?: string;
    photos?: string[];
}

export default function ArticleEditor({ 
    value, 
    onChange, 
    label, 
    placeholder, 
    isLoading = false,
    articleTitle,
    articleConditionOrigin,
    articleConditionNew,
    photos
}: ArticleEditorProps) {
    const editor = useEditor({
        extensions: [
            StarterKit,
            TextAlign.configure({
                types: ['heading', 'paragraph'],
            }),
        ],
        content: value,
        editorProps: {
            attributes: {
                class: 'prose prose-sm max-w-none p-4 focus:outline-none min-h-[150px]',
            },
        },
        onUpdate: ({ editor }) => {
            onChange(editor.getHTML());
        },
    });

    // Aktualisiere den Editor-Inhalt, wenn sich der value von außen ändert
    useEffect(() => {
        if (editor && value !== editor.getHTML()) {
            editor.commands.setContent(value);
        }
    }, [value, editor]);

    return (
        <div className="w-full">
            <div className={`bg-white rounded-md relative ${isLoading ? 'opacity-50' : ''}`}>
                <EditorMenuBar 
                    editor={editor} 
                    articleTitle={articleTitle}
                    articleConditionOrigin={articleConditionOrigin}
                    articleConditionNew={articleConditionNew}
                    photos={photos}
                />
                <EditorContent editor={editor} />
                {isLoading && (
                    <div className="absolute inset-0 flex items-center justify-center bg-white/50">
                        <Spinner />
                    </div>
                )}
            </div>
        </div>
    );
} 