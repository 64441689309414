import { DataFile } from "../files/dataFile";
import { VideoFile } from "../files/videoFile";
import { VoiceFile } from "../files/voiceFile";
import { AppUser } from "../user/appUser";

class WikiContent {
    constructor({
        id,
        title,
        author,
        department = 'allgemein',
        imageMedia = [],
        videoMedia = [],
        fileMedia = [],
        voiceMedia = [],
        isPublic = false,
        textContent,
        created,
        updated
    }) {
        this.id = id;
        this.title = title;
        this.author = author instanceof AppUser ? author : new AppUser(author);
        this.department = department;
        this.imageMedia = this.validateStringArray(imageMedia, 'Image Media');
        this.videoMedia = this.validateMediaArray(videoMedia, VideoFile, 'Video Media');
        this.fileMedia = this.validateMediaArray(fileMedia, DataFile, 'File Media');
        this.voiceMedia = this.validateMediaArray(voiceMedia, VoiceFile, 'Voice Media');
        this.isPublic = isPublic;
        this.textContent = textContent;
        this.created = created ? new Date(created.milliseconds) : null;;
        this.updated = updated ? new Date(updated) : null;
    }

    validateStringArray(array, mediaType) {
        if (!Array.isArray(array)) {
            throw new Error(`${mediaType} must be an array.`);
        }

        if (array.length > 0 && !array.every(item => typeof item === 'string')) {
            throw new Error(`${mediaType} must contain only strings (URLs).`);
        }

        return array;
    }

    validateMediaArray(mediaArray, mediaClass, mediaType) {
        if (!Array.isArray(mediaArray)) {
            throw new Error(`${mediaType} must be an array.`);
        }

        if (mediaArray.length > 0 && !mediaArray.every(item => item instanceof mediaClass)) {
            throw new Error(`${mediaType} must contain only instances of ${mediaClass.name}.`);
        }

        return mediaArray;
    }

    static fromJson(json) {
        return new WikiContent({
            id: json.id,
            title: json.title,
            author: new AppUser(json.author),
            department: json.department || 'allgemein',
            imageMedia: json.imageMedia || [],
            videoMedia: json.videoMedia.map(item => new VideoFile(item)),
            fileMedia: json.fileMedia.map(item => new DataFile(item)),
            voiceMedia: json.voiceMedia.map(item => new VoiceFile(item)),
            isPublic: json.isPublic,
            textContent: json.textContent,
            created: json.created ? { milliseconds: json.created.milliseconds } : null,
            updated: json.updated ? new Date(json.updated) : null,
        });
    }

    toJson() {
        return {
            id: this.id,
            title: this.title,
            author: this.author.toJson(),
            department: this.department,
            imageMedia: this.imageMedia,
            videoMedia: this.videoMedia.map(item => item.toJson()),
            fileMedia: this.fileMedia.map(item => item.toJson()),
            voiceMedia: this.voiceMedia.map(item => item.toJson()),
            isPublic: this.isPublic,
            textContent: this.textContent,
            created: this.created ? { milliseconds: this.created.getTime() } : null,
            updated: this.updated ? this.updated.toISOString() : null,
        };
    }
}

export default WikiContent;
