import {
    Drawer,
    DrawerContent,
    DrawerHeader,
    DrawerBody,
    DrawerFooter,
    Button,
    Tooltip,
    Input,
    ScrollShadow,
    Textarea,
} from "@heroui/react";
import { useState } from "react";
import React from "react";

export default function PriceChangeDrawer({ isOpen, onOpenChange, currentPrice }) {
    const [newPrice, setNewPrice] = useState("");
    const [reason, setReason] = useState("");

    return (
        <Drawer
            hideCloseButton
            backdrop="blur"
            classNames={{
                base: "data-[placement=right]:sm:m-2 data-[placement=left]:sm:m-2 rounded-medium",
            }}
            isOpen={isOpen}
            onOpenChange={onOpenChange}
        >
            <DrawerContent>
                {(onClose) => (
                    <>
                        <DrawerHeader className="absolute top-0 inset-x-0 z-50 flex flex-row gap-2 px-2 py-2 border-b border-default-200/50 justify-between bg-content1/50 backdrop-saturate-150 backdrop-blur-lg">
                            <Tooltip content="Schließen">
                                <Button
                                    isIconOnly
                                    className="text-default-400"
                                    size="sm"
                                    variant="light"
                                    onPress={onClose}
                                >
                                    <svg
                                        fill="none"
                                        height="20"
                                        stroke="currentColor"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        viewBox="0 0 24 24"
                                        width="20"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path d="m13 17 5-5-5-5M6 17l5-5-5-5" />
                                    </svg>
                                </Button>
                            </Tooltip>
                        </DrawerHeader>

                        <DrawerBody className="px-0">
                            <ScrollShadow>
                                <div className="pt-16 px-6 space-y-2">
                                    <Input
                                        fullWidth
                                        label="Neuer Preis"
                                        placeholder="Preis"
                                        variant="bordered"
                                        size="sm"
                                        value={newPrice}
                                        onChange={(e) => setNewPrice(e.target.value)}
                                    />
                                    <Textarea
                                        isClearable
                                        defaultValue={reason}
                                        label="Begründung"
                                        placeholder="Weitere Details"
                                        variant="bordered"
                                        onClear={() => console.log("textarea cleared")}
                                        onChange={(e) => setReason(e.target.value)}
                                    />
                                </div>
                            </ScrollShadow>
                        </DrawerBody>

                        <DrawerFooter>
                            <Button color="primary" fullWidth>
                                Text übernehmen
                            </Button>
                        </DrawerFooter>
                    </>
                )}
            </DrawerContent>
        </Drawer>
    );
}

