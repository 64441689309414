export class PriceRequest {
  articleId: number;
  orderId: number;
  selectedCondition: number;
  articleQuestions: PriceRequestArticleQuestion[];
  amount: number;
  isExpert: boolean;
  buyback: boolean;
  skipSellRules: boolean;
  assumeCorrectAnswers: boolean;
  requestZoxsCheck: boolean;
  affectedQuestion?: number;

  constructor(init: Partial<PriceRequest>) {
    Object.assign(this, {
      amount: 1,
      isExpert: false,
      buyback: false,
      skipSellRules: false,
      assumeCorrectAnswers: false,
      requestZoxsCheck: false,
      affectedQuestion: null,
      ...init
    });
  }

  static fromJson(json: any): PriceRequest {
    return new PriceRequest({
      articleId: json.articleId,
      orderId: json.orderId,
      selectedCondition: json.selectedCondition,
      articleQuestions: json.articleQuestions?.map(PriceRequestArticleQuestion.fromJson),
      amount: json.amount !== undefined ? json.amount : 1,
      isExpert: json.isExpert !== undefined ? json.isExpert : false,
      buyback: json.buyback !== undefined ? json.buyback : false,
      skipSellRules: json.skipSellRules !== undefined ? json.skipSellRules : false,
      assumeCorrectAnswers: json.assumeCorrectAnswers !== undefined ? json.assumeCorrectAnswers : false,
      requestZoxsCheck: json.requestZoxsCheck !== undefined ? json.requestZoxsCheck : false,
      affectedQuestion: json.affectedQuestion !== undefined ? json.affectedQuestion : null,
    });
  }

  static toJson(priceRequest: PriceRequest): any {
    return {
      articleId: priceRequest.articleId,
      orderId: priceRequest.orderId,
      selectedCondition: priceRequest.selectedCondition,
      articleQuestions: priceRequest.articleQuestions?.map(PriceRequestArticleQuestion.toJson),
      amount: priceRequest.amount,
      isExpert: priceRequest.isExpert,
      buyback: priceRequest.buyback,
      skipSellRules: priceRequest.skipSellRules,
      assumeCorrectAnswers: priceRequest.assumeCorrectAnswers,
      requestZoxsCheck: priceRequest.requestZoxsCheck,
      affectedQuestion: priceRequest.affectedQuestion,
    };
  }
}

export class PriceRequestArticleQuestion {
  id: number;
  userSelect: boolean | number | string;

  constructor(init: Partial<PriceRequestArticleQuestion>) {
    Object.assign(this, init);
  }

  static fromJson(json: any): PriceRequestArticleQuestion {
    return new PriceRequestArticleQuestion({
      id: json.id,
      userSelect: json.userSelect,
    });
  }

  static toJson(question: PriceRequestArticleQuestion): any {
    return {
      id: question.id,
      userSelect: question.userSelect,
    };
  }
}