export class ConditionCalculatorModel {
    steps: ConditionCalculatorStep[];
  
    constructor(steps: ConditionCalculatorStep[]) {
      this.steps = steps;
    }
  
    static fromJson(json: any): ConditionCalculatorModel {
      return new ConditionCalculatorModel(
        json.steps.map((step: any) => ConditionCalculatorStep.fromJson(step))
      );
    }
  
    toJson(): any {
      return {
        steps: this.steps.map((step) => step.toJson()),
      };
    }
  }
  
  export class ConditionCalculatorStep {
    question: string;
    images: string[];
    answers: string[];
  
    constructor(question: string, images: string[], answers: string[]) {
      this.question = question;
      this.images = images;
      this.answers = answers;
    }
  
    static fromJson(json: any): ConditionCalculatorStep {
      return new ConditionCalculatorStep(json.question, json.images, json.answers);
    }
  
    toJson(): any {
      return {
        question: this.question,
        images: this.images,
        answers: this.answers,
      };
    }
  }
  