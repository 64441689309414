import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import userService from '@/services/userService';
import NoPermission from '@/components/NoPermission';
import ArticleOverview from './overview';
import ArticleDetail from './article';
import { ArticleQueryType } from './models/queryTypes';

const Article: React.FC = () => {
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const id = params.get('id') as ArticleQueryType;
    const type = params.get('type');

    const [activeComponent, setActiveComponent] = useState<string>('ArticleOverview');

    const userHasPermission: boolean =
        userService.user.modules.includes(1);

    useEffect(() => {
        const path = location.pathname;
        if (path.startsWith('/admin-article/article') && type && id) {
            setActiveComponent('ArticleView');
        } else {
            setActiveComponent('ArticleOverview');
        }
    }, [location.pathname, type, id]);

    if (!userHasPermission) {
        return <NoPermission />;
    }

    return (
        <>
            {activeComponent === 'ArticleOverview' && <ArticleOverview />}
            {activeComponent === 'ArticleView' && (
                <ArticleDetail id={id || ''} type={type || ''} />
            )}
        </>
    );
};

export default Article;