import React, { useState, useEffect } from "react";
import { ArticleCheckDeliveryScope } from "../models/articleDeliveryScope";
import { Button, Chip, Alert, Checkbox } from "@heroui/react";
import { Icon } from "@iconify/react/dist/iconify.js";

interface ArticleDeliveryScopeProps {
  deliveryScope: ArticleCheckDeliveryScope[];
  onChange: (selectedItems: number[], noScope?: boolean) => void;
}

const ArticleDeliveryScope: React.FC<ArticleDeliveryScopeProps> = ({
  deliveryScope,
  onChange,
}) => {
  const [selectedItems, setSelectedItems] = useState<number[]>([]);
  const [noDeliveryScope, setNoDeliveryScope] = useState<boolean>(false);

  // WHEN "NO DELIVERY SCOPE" CHECKBOX CHANGES
  const handleNoDeliveryScopeChange = (checked: boolean) => {
    setNoDeliveryScope(checked);
    
    if (checked) {
      // IF "NO DELIVERY SCOPE" IS CHECKED, CLEAR ALL SELECTIONS
      setSelectedItems([]);
      onChange([], true);
    } else {
      onChange(selectedItems, false);
    }
  };

  // WHEN ITEM SELECTION CHANGES
  const toggleItem = (itemId: number) => {
    // IF "NO DELIVERY SCOPE" IS SELECTED, UNCHECK IT FIRST
    if (noDeliveryScope) {
      setNoDeliveryScope(false);
    }

    setSelectedItems((prev) => {
      const newSelection = prev.includes(itemId)
        ? prev.filter((id) => id !== itemId)
        : [...prev, itemId];
      
      // CALL THE ONCHANGE HANDLER WITH THE UPDATED SELECTION
      onChange(newSelection, false);
      
      return newSelection;
    });
  };

  // IF NO DELIVERY ITEMS ARE AVAILABLE, DON'T SHOW THE COMPONENT
  if (!deliveryScope || deliveryScope.length === 0) {
    return (
      <Alert color="primary">
        <div className="flex items-center gap-2">
          <span>Keine Lieferumfangsdaten verfügbar.</span>
        </div>
      </Alert>
    );
  }

  return (
    <div className="space-y-4">
      <div className="mb-4">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
          {deliveryScope.map((item) => (
            <div
              key={item.id}
              onClick={() => toggleItem(item.id)}
              className={`
                p-3 rounded-lg border cursor-pointer transition-all
                ${
                  selectedItems.includes(item.id)
                    ? "border-success bg-success-50 dark:bg-success-900/20"
                    : "border-default-200 hover:border-primary-200 hover:bg-default-50"
                }
                ${noDeliveryScope ? "opacity-50 pointer-events-none" : ""}
              `}
            >
              <div className="flex items-center justify-between">
                <div className="flex flex-col">
                  <span className="font-medium">{item.name}</span>
                  {item.short && (
                    <span className="text-xs text-default-500">{item.short}</span>
                  )}
                </div>
                <div>
                  {selectedItems.includes(item.id) ? (
                    <Chip
                      color="success"
                      variant="flat"
                      size="sm"
                      startContent={<Icon icon="solar:check-circle-bold" width={16} />}
                    >
                      Vorhanden
                    </Chip>
                  ) : (
                    <Chip
                      color="default"
                      variant="flat"
                      size="sm"
                    >
                      Nicht vorhanden
                    </Chip>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* NO DELIVERY SCOPE OPTION */}
      <div className="mt-6 border-t pt-4">
        <div className="flex items-center gap-2">
          <Checkbox
            isSelected={noDeliveryScope}
            onValueChange={handleNoDeliveryScopeChange}
            color="primary"
          />
          <div className="flex flex-col">
            <span className="font-medium">Komplett ohne Lieferumfang</span>
            <span className="text-xs text-default-500">
              Der Artikel ist ohne jegliches Zubehör
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ArticleDeliveryScope;