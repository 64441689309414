import React, { useEffect, useState } from "react";
import {
    CardBody,
    Input,
    Button,
    Tooltip,
    Alert,
    Spinner,
    Select,
    SelectItem
} from "@heroui/react";
import { Icon } from "@iconify/react/dist/iconify.js";
import { ArticleCheckModel } from "../models/article";
import { OpenAiService } from "../api/openAiService";

interface ArticleDetailsCardProps {
    article: ArticleCheckModel | null;
    isDemoMode: boolean;
    canViewPrice: boolean;
    canEditPrice: boolean;
    onLabelOpen: () => void;
    onPriceEditOpen: () => void;
    serialNumber: string;
    onSerialNumberChange: (value: string) => void;
    price: number | null;
    isLoadingPrice: boolean;
    hasAnsweredAllQuestions: boolean;
    hasCondition: boolean;
    onScopeChange?: (value: string) => void;
    selectedScope?: string;
}

const ArticleDetailsCard: React.FC<ArticleDetailsCardProps> = ({
    article,
    isDemoMode,
    canViewPrice,
    canEditPrice,
    onLabelOpen,
    onPriceEditOpen,
    serialNumber,
    onSerialNumberChange,
    price,
    isLoadingPrice,
    hasAnsweredAllQuestions,
    hasCondition,
    onScopeChange,
    selectedScope = ""
}) => {
    const [articleColor, setArticleColor] = useState<{ hex: string; name: string }>({ hex: "#4c4d4f", name: "Unbekannt" });
    const [isLoadingColor, setIsLoadingColor] = useState<boolean>(false);

    const scopeOptions = [
        { value: "A", label: "[A] nur Modul, ohne Anleitung & Verpackung" },
        { value: "B", label: "[B] nur Artikel ohne OVP" },
        { value: "C", label: "[C] ohne IR-Adapter" },
        { value: "D", label: "[D] ohne Expansion Pack" },
        { value: "E", label: "[E] ohne Stromkabel" },
        { value: "F", label: "[F] ohne Anleitung" },
        { value: "G", label: "[G] ohne Pokéwalker" },
        { value: "H", label: "[H] ohne Transfer-Pack" },
        { value: "I", label: "[I] mit Verpackung & ohne Anleitung" }
    ];

    useEffect(() => {
        const fetchArticleColor = async () => {
            if (!article?.title || !article.isDevice) return;
            
            setIsLoadingColor(true);
            try {
                const openAiService = new OpenAiService();
                const color = await openAiService.getArticleHexColor(article.title);
                setArticleColor(color);
            } catch (error) {
                console.error('Fehler bei der Farberkennung:', error);
                setArticleColor({ hex: "#4c4d4f", name: "Unbekannt" });
            } finally {
                setIsLoadingColor(false);
            }
        };

        fetchArticleColor();
    }, [article?.title, article?.isDevice]);

    if (!article) return null;

    const formatPrice = (price: number) => {
        return price.toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + ' €';
    };

    const showPrice = hasAnsweredAllQuestions && hasCondition;

    return (
        <CardBody className="space-y-2">
            {article.hasSerialNumber && (
                <Input 
                    label="IMEI 1 / Seriennummer" 
                    type="text" 
                    size="sm" 
                    value={serialNumber}
                    onChange={(e) => onSerialNumberChange(e.target.value)}
                    disabled={isDemoMode}
                />
            )}
            {article.hasAdditionalSerialNumber && <Input label="Seriennummer" type="sn" size="sm" disabled={isDemoMode} />}
            {article.isDevice && (
                <div className="flex gap-2 items-center pt-4">
                    <div className="w-full flex gap-2 items-center">
                        {isLoadingColor ? (
                            <div className="h-8 w-8 rounded-full bg-[#fff] flex items-center justify-center">
                                <Spinner size="sm" />
                            </div>
                        ) : (
                            <div className="h-8 w-8 rounded-full" style={{ backgroundColor: articleColor.hex }}></div>
                        )}
                        <div>
                            <h4 className="text-small font-semibold leading-none text-default-600">Farbe</h4>
                            <h5 className="text-small tracking-tight text-default-400">{articleColor.name}</h5>
                        </div>
                    </div>
                    <Tooltip content="Geräteetikett" showArrow>
                        <Button isIconOnly
                            radius="full"
                            size="sm"
                            variant="bordered"
                            onPress={onLabelOpen}
                        >
                            <Icon
                                className="text-default-500"
                                icon="solar:qr-code-outline"
                                width={20}
                            />
                        </Button>
                    </Tooltip>
                </div>
            )}
            {!article.isDevice && (
                <div className="flex gap-2 items-center pt-4">
                    <div className="w-full flex gap-2 items-center">
                        <Select
                            label="Lieferumfang"
                            placeholder="Bitte auswählen (falls unvollständig)"
                            selectedKeys={selectedScope ? [selectedScope] : []}
                            onChange={(e) => onScopeChange?.(e.target.value)}
                            size="sm"
                            className="max-w-[400px]"
                        >
                            {scopeOptions.map((option) => (
                                <SelectItem key={option.value}>
                                    {option.label}
                                </SelectItem>
                            ))}
                        </Select>
                    </div>
                    <Tooltip content="ASIN-Etikett" showArrow>
                        <Button isIconOnly
                            radius="full"
                            size="sm"
                            variant="bordered"
                            onPress={onLabelOpen}
                        >
                            <Icon
                                className="text-default-500"
                                icon="solar:qr-code-outline"
                                width={20}
                            />
                        </Button>
                    </Tooltip>
                </div>
            )}
            <div className="py-4">
                <hr />
            </div>
            {canViewPrice && (
                !showPrice ? (
                    <Alert
                        color="primary"
                        description="Beantworte alle Fragen und wähle einen Zustand aus, um den Preis zu erhalten."
                        variant="faded"
                    />
                ) : (
                    <div className="w-full flex justify-between items-center">
                        <div className="flex-1">
                            {isDemoMode ? (
                                <h4 className="text-small leading-none text-default-600">Aktueller Einkaufspreis</h4>
                            ) : (
                                <Alert color="success"
                                    description="Artikelpreis stimmig"
                                    title="Keine Beanstandung"
                                    variant="faded"
                                />
                            )}
                        </div>
                        <div className="flex items-center gap-2 ml-2">
                            <h4 className="text-small font-semibold leading-none text-default-600">
                                {isLoadingPrice ? (
                                    <Spinner size="sm" />
                                ) : formatPrice(price || 0)}
                            </h4>
                            <Tooltip content="Preis bearbeiten" showArrow>
                                <Button 
                                    isIconOnly 
                                    radius="full" 
                                    size="sm" 
                                    variant="bordered" 
                                    onPress={onPriceEditOpen}
                                    isDisabled={!canEditPrice}
                                >
                                    <Icon className="text-default-500" icon="solar:pen-outline" width={20} />
                                </Button>
                            </Tooltip>
                        </div>
                    </div>
                )
            )}
        </CardBody>
    );
};

export default ArticleDetailsCard;