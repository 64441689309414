import {
    Drawer,
    DrawerContent,
    DrawerHeader,
    DrawerBody,
    DrawerFooter,
    Button,
    Tooltip,
    Input,
    ScrollShadow,
    Textarea,
    Chip
} from "@heroui/react";
import { useState } from "react";
import React from "react";
import { QRCodeSVG } from "qrcode.react";
import QRCode from "qrcode";

export default function LabelDrawer({ isOpen, onOpenChange, article, serialNumber, box, condition, defective, comments }) {
    const [info, setInfo] = useState(comments || "");
    const currentDate = new Date().toLocaleDateString('de-DE', {
        year: '2-digit',
        month: '2-digit',
        day: '2-digit'
    }).replace(/\./g, '');

    // Get initials from title
    const getInitials = (title) => {
        const words = title?.split(' ') || [];
        if (words.length >= 2) {
            return `${words[0][0]}${words[1][0]}`;
        }
        return words[0]?.[0] || '';
    };

    const handlePrint = async () => {
        const printWindow = window.open('', '_blank');
        if (!printWindow) return;

        // Generate QR code as data URL
        const qrCodeDataUrl = await QRCode.toDataURL(serialNumber || article.articleRef, {
            width: 88,
            margin: 0,
            errorCorrectionLevel: 'M'
        });

        const titleInitials = getInitials(article.title);
        const referenceCode = `${titleInitials} ${condition}${defective ? '*' : ''} ${serialNumber || article.articleRef}`;

        printWindow.document.write(`
            <html>
                <head>
                    <title>ZOXS Etikett</title>
                    <style>
                        @page {
                            size: 62mm;
                            margin: 0;
                        }
                        body {
                            margin: 0;
                            padding: 8px;
                            font-family: system-ui, -apple-system, sans-serif;
                        }
                        .label {
                            width: 234px;
                        }
                        ${box ? `
                        .title {
                            font-size: 12px;
                            font-weight: 500;
                            text-align: center;
                            margin-bottom: 12px;
                        }
                        .condition {
                            display: flex;
                            justify-content: center;
                            margin-bottom: 12px;
                        }
                        .condition-badge {
                            width: 32px;
                            height: 32px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            border: 1px solid #ccc;
                            border-radius: 4px;
                            background: #f4f4f5;
                            font-weight: 500;
                        }` : `
                        .reference {
                            font-size: 9px;
                            text-align: left;
                            font-family: monospace;
                            display: flex;
                            flex-direction: column;
                            gap: 2px;
                        }`}
                        .ref {
                            font-family: monospace;
                            font-size: 10px;
                            text-align: center;
                            color: #666;
                            margin-bottom: 12px;
                        }
                        ${!box ? `
                        .qr-wrapper {
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            gap: 8px;
                            justify-content: flex-start;
                            padding: 0;
                        }
                        .reference {
                            font-size: 9px;
                            text-align: left;
                            font-family: monospace;
                            display: flex;
                            flex-direction: column;
                            gap: 2px;
                        }` : ''}
                        .qr-section {
                            display: flex;
                            gap: 8px;
                            margin-bottom: 8px;
                            ${!box && `
                            flex-direction: column;
                            align-items: center;
                            margin-bottom: 0;
                            `}
                        }
                        .qr-code {
                            width: 70px;
                            height: 70px;
                            padding: 0;
                            background: white;
                        }
                        .qr-code img {
                            width: 70px;
                            height: 70px;
                        }
                        ${box ? `
                        .logo-section {
                            flex: 1;
                        }
                        .logo {
                            width: 100%;
                            height: 48px;
                            object-fit: contain;
                        }
                        ` : `
                        .simple-info {
                            font-size: 10px;
                            text-align: center;
                            font-family: monospace;
                            margin-top: 8px;
                        }
                        `}
                        .info {
                            font-size: 10px;
                            text-align: center;
                        }
                        .info-text {
                            margin: 12px 0;
                            padding: 8px;
                            border: 1px solid #ccc;
                            border-radius: 4px;
                            min-height: 20px;
                        }
                        .slogan {
                            font-size: 10px;
                            text-align: center;
                            font-style: italic;
                            color: #666;
                        }
                    </style>
                </head>
                <body>
                    <div class="label">
                        ${box ? `
                        <div class="title">${article.title}</div>
                        <div class="condition">
                            <div class="condition-badge">${condition}${defective ? '*' : ''}</div>
                        </div>
                        <div class="ref">${serialNumber || article.articleRef}</div>
                        ` : `
                        <div class="qr-wrapper">
                            <div class="qr-code">
                                <img src="${qrCodeDataUrl}" alt="QR Code" />
                            </div>
                            <div class="reference">
                                <div>${referenceCode}</div>
                                <div>555 ${currentDate.replace(/(\d{2})(\d{2})(\d{2})/, '$1.$2.$3')}</div>
                            </div>
                        </div>
                        `}
                        ${box ? `
                        <div class="qr-section">
                            <div class="qr-code">
                                <img src="${qrCodeDataUrl}" alt="QR Code" />
                            </div>
                            <div class="logo-section">
                                <img class="logo" src="https://www.zoxs.de/assets/images/svg/logo_zoxs-2019-vs02.svg" alt="ZOXS" />
                                <div class="info">
                                    www.zoxs.de<br>
                                    555 ${currentDate.replace(/(\d{2})(\d{2})(\d{2})/, '$1.$2.$3')}
                                </div>
                            </div>
                        </div>
                        ` : ''}
                        ${box && info ? `<div class="info-text">${info}</div>` : ''}
                    </div>
                    <script>
                        window.onload = () => {
                            window.print();
                            window.onafterprint = () => window.close();
                        }
                    </script>
                </body>
            </html>
        `);
        printWindow.document.close();
    };

    return (
        <Drawer
            hideCloseButton
            backdrop="blur"
            classNames={{ 
                base: "data-[placement=right]:sm:m-2 data-[placement=left]:sm:m-2 rounded-medium",
            }}
            isOpen={isOpen}
            onOpenChange={onOpenChange}
            size="sm"
        >
            <DrawerContent>
                {(onClose) => (
                    <>
                        <DrawerHeader className="absolute top-0 inset-x-0 z-50 flex flex-row gap-2 px-2 py-2 border-b border-default-200/50 justify-between bg-content1/50 backdrop-saturate-150 backdrop-blur-lg">
                            <Tooltip content="Schließen">
                                <Button
                                    isIconOnly
                                    className="text-default-400"
                                    size="sm"
                                    variant="light"
                                    onPress={onClose}
                                >
                                    <svg
                                        fill="none"
                                        height="20"
                                        stroke="currentColor"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        viewBox="0 0 24 24"
                                        width="20"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path d="m13 17 5-5-5-5M6 17l5-5-5-5" />
                                    </svg>
                                </Button>
                            </Tooltip>
                        </DrawerHeader>

                        <DrawerBody className="px-0">
                            <ScrollShadow>
                                <div className="pt-16 px-6">
                                    {/* Label Preview */}
                                    <div className="w-[280px] mx-auto p-4 border border-default-200 rounded-medium space-y-3 print:border-none">
                                        {box ? (
                                            <>
                                                {/* Title */}
                                                <div className="text-small font-medium leading-tight text-center">{article.title}</div>

                                                {/* Condition Badge */}
                                                <div className="flex justify-center">
                                                    <div className="w-8 h-8 flex items-center justify-center border rounded bg-default-100 border-default-400 font-medium">
                                                        {condition}{defective && '*'}
                                                    </div>
                                                </div>

                                                {/* Barcode */}
                                                <div className="text-center font-mono text-tiny text-default-500">
                                                    {serialNumber || article.articleRef}
                                                </div>

                                                {/* QR Code and Logo */}
                                                <div className="flex items-start gap-3">
                                                    <div className="w-24 h-24 bg-white p-1">
                                                        <QRCodeSVG
                                                            value={serialNumber || article.articleRef}
                                                            size={70}
                                                            level="M"
                                                        />
                                                    </div>
                                                    <div className="flex-1">
                                                        <img
                                                            src="https://www.zoxs.de/assets/images/svg/logo_zoxs-2019-vs02.svg"
                                                            alt="ZOXS"
                                                            className="w-full h-12 object-contain"
                                                        />
                                                        <div className="flex flex-col items-center mt-2">
                                                            <div className="text-tiny">
                                                                www.zoxs.de
                                                            </div>
                                                            <div className="text-tiny font-mono">
                                                                555 {currentDate.replace(/(\d{2})(\d{2})(\d{2})/, '$1.$2.$3')}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        ) : (
                                            <div className="flex items-center gap-3 justify-center">
                                                <div className="w-24 h-24 bg-white p-1">
                                                    <QRCodeSVG
                                                        value={serialNumber || article.articleRef}
                                                        size={70}
                                                        level="M"
                                                    />
                                                </div>
                                                <div className="flex flex-col gap-1 font-mono text-tiny">
                                                    <div>{getInitials(article.title)} {condition}{defective && '*'} {serialNumber || article.articleRef}</div>
                                                    <div>555 {currentDate.replace(/(\d{2})(\d{2})(\d{2})/, '$1.$2.$3')}</div>
                                                </div>
                                            </div>
                                        )}

                                        {/* Info Field */}
                                        {box && (
                                            <div className="relative">
                                                <Input
                                                    type="text"
                                                    placeholder="Info"
                                                    size="sm"
                                                    value={info}
                                                    onChange={(e) => setInfo(e.target.value)}
                                                />
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </ScrollShadow>
                        </DrawerBody>

                        <DrawerFooter>
                            <Button color="primary" fullWidth onPress={handlePrint}>
                                Drucken
                            </Button>
                        </DrawerFooter>
                    </>
                )}
            </DrawerContent>
        </Drawer>
    );
}

