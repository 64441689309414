import React, { memo } from 'react';
import { Handle, Position, NodeProps } from 'reactflow';

const RectangleNode = ({ data, selected }: NodeProps) => {
    const handleKeyDown = (e: React.KeyboardEvent) => {
        // Verhindere Bubble-Up von Delete/Backspace im Textfeld
        if (e.key === 'Delete' || e.key === 'Backspace') {
            e.stopPropagation();
            e.preventDefault(); // Verhindert das Löschen des Elements
        }
    };

    return (
        <div
            className={`
                relative w-40 h-24 bg-white rounded-lg border-2
                flex items-center justify-center cursor-move
                transition-all duration-200
                ${selected ? 'border-primary shadow-lg ring-2 ring-primary/30' : 'border-gray-200'}
            `}
            onKeyDown={handleKeyDown} // Event-Handler auch auf Container-Ebene
        >
            <div className="px-4 py-2 text-center" onKeyDown={handleKeyDown}>
                <div
                    className="text-sm font-medium text-gray-900 focus:outline-none"
                    contentEditable
                    suppressContentEditableWarning
                    onBlur={(e) => data.onChange(e.target.textContent || '')}
                    onKeyDown={handleKeyDown}
                >
                    {data.label}
                </div>
                {data.description && (
                    <div
                        className="text-xs text-gray-500 mt-1 focus:outline-none"
                        contentEditable
                        suppressContentEditableWarning
                        onBlur={(e) => data.onDescriptionChange(e.target.textContent || '')}
                        onKeyDown={handleKeyDown}
                    >
                        {data.description}
                    </div>
                )}
            </div>

            {/* Knotenpunkte mit unterschiedlichen Typen */}
            <Handle 
                type="target"  // Eingang oben
                position={Position.Top} 
                className="w-2 h-2 !bg-primary" 
                style={{ left: '50%' }}
                id="top"
            />
            
            <Handle 
                type="source"  // Ausgang unten
                position={Position.Bottom} 
                className="w-2 h-2 !bg-primary" 
                style={{ left: '50%' }}
                id="bottom"
            />
            
            <Handle 
                type="target"  // Eingang links
                position={Position.Left} 
                className="w-2 h-2 !bg-primary" 
                style={{ top: '50%' }}
                id="left"
            />
            
            <Handle 
                type="source"  // Ausgang rechts
                position={Position.Right} 
                className="w-2 h-2 !bg-primary" 
                style={{ top: '50%' }}
                id="right"
            />
        </div>
    );
};

export default memo(RectangleNode); 