export interface SelectOption {
  value: string;
  name: string;
}

export const SelectOption = {
  fromJson: (json: any): SelectOption => ({
    value: json.value,
    name: json.name,
  }),

  toJson: (option: SelectOption): any => ({
    value: option.value,
    name: option.name,
  }),
}; 