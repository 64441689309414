import { getCookie } from '@/helpers/cookieHelper';
import { EbayBase } from '../models/EbayBase';
import { EbayMissingData } from '../models/EbayMissingData';
import { MailText } from '../models/mailText';
import { EbayDoc } from '../models/EbayDoc';
import { SpeedUploadConfig } from '../models/SpeedUploadConfig';
import { EbayDraft } from '../models/EbayDraft';
import { EbayRequest } from '../models/EbayRequest';

const BASE_URL = "https://app.api.zoxs.de";

class EbayService {
  private baseUrl: string;

  constructor(baseUrl: string) {
    this.baseUrl = baseUrl;
  }

  // GET EBAY DATA
  async getEbayData(request: EbayRequest): Promise<EbayBase | EbayMissingData> {
    try {
      const authToken = getCookie('inteamuser');

      const headers: HeadersInit = {
        'Content-Type': 'application/json',
      };

      if (authToken) {
        headers['auth-token'] = authToken;
      }

      const response = await fetch(`${this.baseUrl}/v1/app/ebay/startover`, {
        method: 'POST',
        headers,
        body: JSON.stringify(request.toJson()),
      });

      if (!response.ok) {
        throw new Error(`Failed to fetch the article: ${response.statusText}`);
      }

      const jsonResponse = await response.json();

      if (!jsonResponse) {
        throw new Error("Empty response or malformed data.");
      }

      if(jsonResponse.type === "missingCategory") {
        return EbayMissingData.fromJson(jsonResponse);
      }
      try {
        return EbayBase.fromJson(jsonResponse);
      } catch (error: any) {
        return EbayMissingData.fromJson(jsonResponse);
      }
    } catch (error: any) {
      throw new Error(error.message || "Failed to fetch the article.");
    }
  }

  // CONTINUE WITH EBAY DATA
  async continueWithData(url: string, data: EbayMissingData): Promise<EbayBase> {
    try {
      const authToken = getCookie('inteamuser');

      const headers: HeadersInit = {
        'Content-Type': 'application/json',
      };

      if (authToken) {
        headers['auth-token'] = authToken;
      }

      const response = await fetch(url, {
        method: 'POST',
        headers,
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        throw new Error('Failed to continue with data');
      }

      const responseData = await response.json();
      return EbayBase.fromJson(responseData);
    } catch (error: any) {
      throw new Error(error.message || "Failed to continue with data.");
    }
  }

  // GET SPEEDUPLOAD CONFIGS
  async getSpeeduploadConfigs(): Promise<SpeedUploadConfig[]> {
    try {
      const authToken = getCookie('inteamuser');

      const headers: HeadersInit = {
        'Content-Type': 'application/json',
      };

      if (authToken) {
        headers['auth-token'] = authToken;
      } 

      const response = await fetch(`${this.baseUrl}/v1/app/ebay/ebaySpeedUploadConfigs`, {
        method: 'GET',
        headers,
      });

      if (!response.ok) {
        throw new Error('Failed to fetch speedupload configs');
      }

      const data = await response.json();
      if (!data) {
        throw new Error('Empty response or malformed data.');
      }

      const configs = data.map((config: any) => SpeedUploadConfig.fromJson(config));
      return configs;
    } catch (error: any) {
      throw new Error(error.message || "Failed to fetch speedupload configs.");
    }
  }

  // GET AVAILABLE TEXTS
  async getAvailableMailTexts(id: string): Promise<MailText[]> {
    try {
      const authToken = getCookie('inteamuser');

      const headers: HeadersInit = {
        'Content-Type': 'application/json',
      };

      if (authToken) {
        headers['auth-token'] = authToken;
      }

      const response = await fetch(`${this.baseUrl}/v1/app/zz/mailtext/articleId/${id}`, {
        method: 'GET',
        headers,
      });

      if (!response.ok) {
        throw new Error(`Failed to fetch the mail texts: ${response.statusText}`);
      }

      const jsonResponse = await response.json();

      if (!jsonResponse) {
        throw new Error("Empty response or malformed data.");
      }

      const data = jsonResponse.map(MailText.fromJson);

      return data;
    } catch (error: any) {
      throw new Error(error.message || "Failed to fetch the mail texts.");
    }
  }

  // UPLOAD TO API
  async uploadToEbay(data: any): Promise<void> {
    try {
      const authToken = getCookie('inteamuser');

      const headers: HeadersInit = {
        'Content-Type': 'application/json',
      };

      if (authToken) {
        headers['auth-token'] = authToken;
      }

      const response = await fetch(`${this.baseUrl}/v1/app/ebay/uploadArticleToEbay`, {
        method: 'POST',
        headers,
        body: JSON.stringify(data),
      });

      console.log('RESPONSE', response);

      if (!response.ok) {
        throw new Error('Failed to upload to API');
      }

      const responseData = await response.json();
      return responseData;
    } catch (error: any) {
      throw new Error(error.message || "Failed to upload to API.");
    }
  }

  // INTEAM API
  // GET EBAY DATA
  async getEbayDoc(id: string): Promise<EbayDoc | string> {
    if (!id || id === '' || id === undefined) {
      console.error('No ID provided to getEbayDoc');
      throw new Error('No ID provided');
    }

    try {
      const authToken = getCookie('inteamuser');

      const headers: HeadersInit = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${process.env.REACT_APP_BEARER_TOKEN}`,
      };

      if (authToken) {
        headers['auth-token'] = authToken;
      }

      const response = await fetch(`${process.env.REACT_APP_BASE_URL}ebay/id/${id}`, {
        method: 'GET',
        headers,
      });

      if (!response.ok) {
        throw new Error(`Failed to fetch the document: ${response.statusText}`);
      }

      const data = await response.json();
      
      if (data.message === "DOC NOT FOUND") {
        return "DOC NOT FOUND";
      }

      return EbayDoc.fromJson(data);
    } catch (error: any) {
      console.error('Error fetching document:', error);
      throw new Error(error.message || "Failed to fetch the document.");
    }
  }

  async addEbayDoc(doc: EbayDoc): Promise<void> {
    if (!doc.id) {
      console.error('No ID provided to addEbayDoc');
      throw new Error('No ID provided');
    }

    try {
      const authToken = getCookie('inteamuser');

      const headers: HeadersInit = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${process.env.REACT_APP_BEARER_TOKEN}`,
      };

      if (authToken) {
        headers['auth-token'] = authToken;
      }

      const url = `${process.env.REACT_APP_BASE_URL}ebay`;

      const response = await fetch(url, {
        method: 'POST',
        headers,
        body: JSON.stringify(doc)
      });

      if (!response.ok) {
        throw new Error(`Failed to add document: ${response.statusText}`);
      }
    } catch (error: any) {
      console.error('Error adding document:', error);
      throw new Error(error.message || "Failed to add document.");
    }
  }

  async getDrafts(): Promise<EbayDraft[]> {
    try {
      const authToken = getCookie('inteamuser');

      const headers: HeadersInit = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${process.env.REACT_APP_BEARER_TOKEN}`,
      };

      if (authToken) {
        headers['auth-token'] = authToken;
      }

      const response = await fetch(`${process.env.REACT_APP_BASE_URL}ebay/drafts`, {
        method: 'GET',
        headers,
      });

      if (!response.ok) {
        throw new Error(`Failed to fetch the document: ${response.statusText}`);
      }

      const data = await response.json();
      const drafts = data.map((draft: any) => EbayDraft.fromJson(draft));
      return drafts;
    } catch (error: any) {
      console.error('Error fetching document:', error);
      throw new Error(error.message || "Failed to fetch the document.");
    }
  }
}

const ebayService = new EbayService(BASE_URL);
export default ebayService;