export interface MailText {
  id: number;
  title: string;
  sellText: string;
  buyText: string;
  hide: boolean;
  openPriceCheck: boolean;
  subTexts: MailText[];
  path?: string[];
}

export class MailText implements MailText {
  id: number;
  title: string;
  sellText: string;
  buyText: string;
  hide: boolean;
  openPriceCheck: boolean;
  subTexts: MailText[];
  path?: string[];

  constructor(init: Partial<MailText>) {
    Object.assign(this, {
      id: 0,
      title: "",
      sellText: "",
      buyText: "",
      hide: false,
      openPriceCheck: false,
      subTexts: [],
      ...init
    });
  }

  static fromJson(json: any): MailText {
    return new MailText({
      id: json.id,
      title: json.title,
      sellText: json.sellText,
      buyText: json.buyText,
      hide: json.hide,
      openPriceCheck: json.openPriceCheck,
      subTexts: json.subTexts?.map(MailText.fromJson),
    });
  }

  static toJson(mailText: MailText): any {
    return {
      id: mailText.id,
      title: mailText.title,
      sellText: mailText.sellText,
      buyText: mailText.buyText,
      hide: mailText.hide,
      openPriceCheck: mailText.openPriceCheck,
      subTexts: mailText.subTexts?.map(MailText.toJson),
    };
  }
}