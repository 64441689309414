import { Button, Card, Input, Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, Select, SelectItem } from "@heroui/react";
import React, { useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ebayService from "../api/ebayService";
import { EbayMissingData } from "../models/EbayMissingData";
import { EbayRequest } from "../models/EbayRequest";
import { v4 as uuidv4 } from 'uuid';

function EbayStartOverComponent() {
    const navigate = useNavigate();
    const inputRef = useRef<HTMLInputElement>(null);
    const [value, setValue] = React.useState("");
    const [isModalOpen, setIsModalOpen] = React.useState(false);
    const [missingData, setMissingData] = React.useState<EbayMissingData | null>(null);
    const [ebayRequest, setEbayRequest] = React.useState<EbayRequest | null>(null);
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState<string | null>(null);
    const [epid, setEpid] = React.useState("");
    const [category_id, setcategory_id] = React.useState("");
    const [selectedType, setSelectedType] = React.useState("sn");

    const searchTypes = [
        { value: "sn", label: "SN / IMEI" },
        { value: "ean", label: "EAN" },
        { value: "epid", label: "EPID" },
        { value: "category_id", label: "Category-ID" }
    ];

    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.focus();
        }
    }, []);

    const getPlaceholder = () => {
        switch (selectedType) {
            case "sn": return "Scanne eine IMEI / Seriennummer ein";
            case "ean": return "Scanne eine EAN ein";
            case "epid": return "Gib eine EPID ein";
            case "category_id": return "Gib eine Kategorie-ID ein";
            default: return "";
        }
    };

    const handleSearch = async () => {
        if (!value) return;
        
        setLoading(true);
        setError(null);
        
        try {
            const id = uuidv4();
            const request = new EbayRequest(
                null,
                selectedType === "sn" ? value : null,
                selectedType === "ean" ? value : null,
                selectedType === "epid" ? value : null,
                selectedType === "category_id" ? value : null,
                id
            );
            setEbayRequest(request);
            const result = await ebayService.getEbayData(request);
            
            if ('commitUrl' in result) {
                setMissingData(result as EbayMissingData);
                setIsModalOpen(true);
            } else {
                navigate(`/admin-ebay/offer?id=${id}`, { 
                    state: { ebayData: result, ebayRequest: request }
                });
            }
        } catch (err: any) {
            setError(err.message || "Ein Fehler ist aufgetreten");
        } finally {
            setLoading(false);
        }
    };

    const handleContinue = async () => {
        if (!missingData?.commitUrl || !ebayRequest) return;
        
        setLoading(true);
        setError(null);

        missingData.epid = epid;
        missingData.category_id = parseInt(category_id);
        
        try {
            const result = await ebayService.continueWithData(missingData.commitUrl, missingData);
            navigate(`/admin-ebay/offer?id=${ebayRequest.firebaseId}`, { 
                state: { ebayData: result, ebayRequest: ebayRequest }
            });
        } catch (err: any) {
            setError(err.message || "Ein Fehler ist aufgetreten");
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <div className="flex items-center gap-x-3">
                <h1 className="text-3xl font-bold leading-9 text-default-foreground">eBay Angebot erstellen</h1>
            </div>
            <h2 className="mt-2 text-small text-default-500 pb-6">
                Erstelle eBay Auktionen oder Festpreis-Angebote.
            </h2>
            <div className="flex flex-col md:flex-row md:space-x-2 w-full items-center">
                <div className="w-full md:w-1/6 mb-2 md:mb-0">
                    <Select
                        label="Suchtyp"
                        selectedKeys={[selectedType]}
                        onChange={(e) => setSelectedType(e.target.value)}
                        variant="bordered"
                        size="sm"
                        radius="md"
                        defaultSelectedKeys={["sn"]}
                    >
                        {searchTypes.map((type) => (
                            <SelectItem key={type.value}>
                                {type.label}
                            </SelectItem>
                        ))}
                    </Select>
                </div>
                <div className="w-full md:w-4/6">
                    <Input
                        ref={inputRef}
                        fullWidth
                        label={searchTypes.find(t => t.value === selectedType)?.label}
                        placeholder={getPlaceholder()}
                        value={value}
                        type={selectedType === "category_id" ? "number" : "text"}
                        variant="bordered"
                        size="sm"
                        radius="md"
                        onValueChange={setValue}
                        onKeyDown={(e) => {
                            if (e.key === "Enter") {
                                handleSearch();
                            }
                        }}
                    />
                </div>
                <div className="w-full md:w-1/6 flex justify-center mt-2 md:mt-0">
                    <Button
                        color="primary"
                        fullWidth
                        onPress={handleSearch}
                        size="lg"
                        isLoading={loading}
                    >
                        Weiter
                    </Button>
                </div>
            </div>

            <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} backdrop='blur'>
                <ModalContent>
                    <ModalHeader>EPID / Cat-ID erforderlich</ModalHeader>
                    <ModalBody>
                        {missingData?.recommended_category?.id && (
                            <p className="mb-4">
                                Vorgeschlagene Kategorie: <strong>{missingData.recommended_category.name} (ID: {missingData.recommended_category.id})</strong>
                            </p>
                        )}
                        <div className="space-y-4">
                            <Input
                                label="EPID"
                                value={epid}
                                onValueChange={setEpid}
                            />
                            <Input
                                label="Kategorie ID"
                                type="number"
                                value={category_id}
                                onValueChange={setcategory_id}
                            />
                        </div>
                        {error && (
                            <div className="mt-4 p-3 bg-red-50 border border-red-200 rounded-lg">
                                <p className="text-red-600 font-medium">{error}</p>
                            </div>
                        )}
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            color="primary"
                            variant="light"
                            onPress={() => setIsModalOpen(false)}
                        >
                            Abbrechen
                        </Button>
                        <Button
                            color="primary"
                            onPress={handleContinue}
                            isLoading={loading}
                            isDisabled={!epid && !category_id}
                        >
                            Fortfahren
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
}

export default EbayStartOverComponent;