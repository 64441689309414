import React from "react";
import { Card, CardFooter, Image, Button } from "@heroui/react";
import { Icon } from '@iconify/react/dist/iconify.js';
import { EbayDraft } from "../models/EbayDraft";

interface DraftsGridProps {
    items: EbayDraft[];
    onItemClick: (draft: EbayDraft) => void;
}

const DraftsGrid: React.FC<DraftsGridProps> = ({ items, onItemClick }) => {
    if (items.length === 0) {
        return (
            <div className="flex flex-col items-center justify-center p-8 text-center">
                <Icon icon="solar:pallete-2-outline" className="text-default-500 mb-4" width={48} />
                <h3 className="text-xl font-semibold mb-2">Keine Entwürfe vorhanden</h3>
                <p className="text-default-500 mb-4">Erstelle ein neues Angebot und speichere es als Entwurf.</p>
            </div>
        );
    }

    return (
        <div className="grid grid-cols-[repeat(auto-fit,minmax(350px,1fr))] gap-3">
            {items.map((draft) => (
                <div key={draft.id} className="flex justify-center">
                    <Card
                        isFooterBlurred
                        radius="none"
                        className="w-full h-[250px] rounded-lg border bg-background shadow"
                        isPressable
                        onClick={() => onItemClick(draft)}
                    >
                        <Image
                            removeWrapper
                            radius="none"
                            alt={draft.title || 'Entwurf'}
                            className="z-0 w-full h-full object-cover"
                            src={draft.firstImageUrl}
                        />
                        <CardFooter className="absolute bg-black/40 bottom-0 z-10 border-t border-default-600 gap-4">
                            <div className="flex flex-grow gap-2 items-center">
                                <div className="flex flex-col text-left">
                                    <p className="text-white/90 font-medium text-l">{draft.title || 'Unbenannter Entwurf'}</p>
                                    <p className="text-white/70 text-sm">
                                        {draft.updatedAt ? new Date(draft.updatedAt).toLocaleDateString() : 'Kein Datum'}
                                    </p>
                                </div>
                            </div>
                            <Button
                                color="primary"
                                radius="full"
                                size="sm"
                                onPress={() => onItemClick(draft)}
                            >
                                Fortsetzen
                            </Button>
                        </CardFooter>
                    </Card>
                </div>
            ))}
        </div>
    );
};

export default DraftsGrid; 