export interface ControllerButton {
    id: string;
    name: string;
    tested: boolean;
    isDefect: boolean;
}

export interface ControllerTestResult {
    serialNumber: string | null;
    buttons: ControllerButton[];
    isComplete: boolean;
    isDefect: boolean;
}

export const DEFAULT_CONTROLLER_BUTTONS: ControllerButton[] = [
    { id: 'dpad_up', name: 'D-Pad Oben', tested: false, isDefect: false },
    { id: 'dpad_down', name: 'D-Pad Unten', tested: false, isDefect: false },
    { id: 'dpad_left', name: 'D-Pad Links', tested: false, isDefect: false },
    { id: 'dpad_right', name: 'D-Pad Rechts', tested: false, isDefect: false },
    { id: 'button_a', name: 'A-Taste', tested: false, isDefect: false },
    { id: 'button_b', name: 'B-Taste', tested: false, isDefect: false },
    { id: 'button_x', name: 'X-Taste', tested: false, isDefect: false },
    { id: 'button_y', name: 'Y-Taste', tested: false, isDefect: false },
    { id: 'shoulder_l', name: 'L-Schultertaste', tested: false, isDefect: false },
    { id: 'shoulder_r', name: 'R-Schultertaste', tested: false, isDefect: false },
    { id: 'trigger_l', name: 'L-Trigger', tested: false, isDefect: false },
    { id: 'trigger_r', name: 'R-Trigger', tested: false, isDefect: false },
    { id: 'start', name: 'Start', tested: false, isDefect: false },
    { id: 'select', name: 'Select', tested: false, isDefect: false }
]; 