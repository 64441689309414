export class SpeedUploadConfig {
    conf_id: number;
    name: string;
    image_url: string;
    is_adult: boolean;
    has_delivery_note: boolean;

    constructor(data: {
        conf_id: number;
        name: string;
        image_url: string | null;
        is_adult: boolean;
        has_delivery_note: boolean;
    }) {
        this.conf_id = data.conf_id;
        this.name = data.name;
        this.image_url = data.image_url || 'https://www.buyzoxs.de/assets/img/kategorien/Sonstiges.png';
        this.is_adult = data.is_adult;
        this.has_delivery_note = data.has_delivery_note;
    }

    static fromJson(json: any): SpeedUploadConfig {
        return new SpeedUploadConfig({
            conf_id: json.conf_id,
            name: json.name,
            image_url: json.image_url,
            is_adult: json.is_adult,
            has_delivery_note: json.has_delivery_note
        });
    }

    toJson(): any {
        return {
            conf_id: this.conf_id,
            name: this.name,
            image_url: this.image_url,
            is_adult: this.is_adult,
            has_delivery_note: this.has_delivery_note
        };
    }
} 