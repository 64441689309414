import React, { useState, useEffect } from "react";
import { ArticleCheckModel } from "../models/article";
import { ArticleCheckCondition } from "../models/articleCondition";
import { Icon } from "@iconify/react/dist/iconify.js";
import { CardHeader, CardBody, Alert, Accordion, AccordionItem, Select, SelectItem, Button, Dropdown, DropdownTrigger, DropdownMenu, DropdownItem, Chip } from "@heroui/react";
import ArticleQuestionComponent from "./article_question";
import ArticleDeliveryScope from "./article_delivery_scope";
import ArticleEditor from "./ArticleEditor";

interface ArticleInfoCardProps {
    article: ArticleCheckModel;
    onModelChangeOpen: () => void;
    onImprovementOpen: () => void;
    onConditionCalculatorOpen: () => void;
    handleQuestionChange: (questionId: number, newValue: boolean | number | string) => void;
    currentCondition: ArticleCheckCondition | null;
    calculatorCondition: ArticleCheckCondition | null;
    onConditionChange: (conditionId: number) => void;
    onDeliveryScopeChange: (selectedItems: number[], noScope?: boolean) => void;
    conditionChangeText: string;
    onConditionChangeTextChange: (text: string) => void;
    onProcessingInstructionsOpen: () => void;
}

const ArticleInfoCard: React.FC<ArticleInfoCardProps> = ({
    article,
    onModelChangeOpen,
    onImprovementOpen,
    onConditionCalculatorOpen,
    handleQuestionChange,
    currentCondition,
    calculatorCondition,
    onConditionChange,
    onDeliveryScopeChange,
    conditionChangeText,
    onConditionChangeTextChange,
    onProcessingInstructionsOpen,
}) => {

    // AVAILABLE SECTIONS AND COMPLETION STATE
    const [sectionCompletion, setSectionCompletion] = useState({
        optics: false,
        delivery: false,
        checklist: false
    });

    // REFS FOR EACH ACCORDION SECTION
    const opticsRef = React.useRef<HTMLDivElement>(null);
    const deliveryRef = React.useRef<HTMLDivElement>(null);
    const checklistRef = React.useRef<HTMLDivElement>(null);

    // DELIVERY SCOPE
    const [selectedDeliveryScope, setSelectedDeliveryScope] = useState<number[]>([]);

    // OVERRIDE FOR DELIVERY SCOPE (NOTHING PROVIDED)
    const [noDeliveryScope, setNoDeliveryScope] = useState<boolean>(false);

    // DETERMINE IF DELIVERY SCOPE SECTION SHOULD BE SHOWN
    const showDeliveryScopeSection = article.deliveryScope && article.deliveryScope.length > 0;

    // SCROLL TO SECTION WITH DELAY FOR ACCORDION ANIMATION
    const scrollToSection = (sectionKey: string) => {
        setTimeout(() => {
            const accordionItems = document.querySelectorAll('[data-accordion-item]');
            if (sectionKey === "optics" && accordionItems[0]) {
                accordionItems[0].scrollIntoView({ behavior: "smooth", block: "start" });
            } else if (sectionKey === "2" && accordionItems[1]) {
                accordionItems[1].scrollIntoView({ behavior: "smooth", block: "start" });
            } else if (sectionKey === "3" && accordionItems[2]) {
                accordionItems[2].scrollIntoView({ behavior: "smooth", block: "start" });
            }
        }, 200); // ENSURE ACCORDION ANIMATION IS COMPLETE
    };

    // TRACK EXPANDED ACCORDION KEYS
    const [expandedKeys, setExpandedKeys] = useState(new Set(["optics"]));

    // TRACK ANSWERED QUESTIONS
    const [answeredQuestions, setAnsweredQuestions] = useState<Set<number>>(new Set());

    // CHECK IF OPTICAL CONDITION SECTION IS COMPLETE
    useEffect(() => {
        // SECTION IS COMPLETE WHEN A CONDITION IS SELECTED AND ALL CONDITION QUESTIONS ARE ANSWERED
        const conditionSelected = currentCondition !== null;
        const allConditionQuestionsAnswered = article.conditionQuestions?.every(
            (question) => answeredQuestions.has(question.id)
        ) || article.conditionQuestions?.length === 0;

        const isOpticsComplete = conditionSelected && allConditionQuestionsAnswered;

        setSectionCompletion(prev => {
            // IF OPTICS SECTION IS COMPLETED AND WAS NOT COMPLETE BEFORE, AUTO-OPEN THE DELIVERY SECTION
            if (isOpticsComplete && !prev.optics) {
                const newKeys = new Set([...expandedKeys, "2"]);
                setExpandedKeys(newKeys);
                setTimeout(() => scrollToSection("2"), 200);
            }
            return { ...prev, optics: isOpticsComplete };
        });
    }, [currentCondition, article.conditionQuestions, answeredQuestions, expandedKeys]);

    // CHECK IF DELIVERY SECTION IS COMPLETE
    useEffect(() => {
        // SECTION IS COMPLETE WHEN ALL PUBLIC AND CHECK QUESTIONS ARE ANSWERED
        const allPublicQuestionsAnswered = article.publicQuestions?.every(
            (question) => answeredQuestions.has(question.id)
        ) || article.publicQuestions?.length === 0;

        const allCheckQuestionsAnswered = article.checkQuestions?.every(
            (question) => answeredQuestions.has(question.id)
        ) || article.checkQuestions?.length === 0;

        const isDeliveryComplete = allPublicQuestionsAnswered && allCheckQuestionsAnswered;

        setSectionCompletion(prev => {
            // IF DELIVERY SECTION IS COMPLETED AND WAS NOT COMPLETE BEFORE,
            // AUTO-OPEN THE CHECKLIST SECTION (ONLY IF IT EXISTS)
            if (isDeliveryComplete && !prev.delivery && showDeliveryScopeSection) {
                const newKeys = new Set([...expandedKeys, "3"]);
                setExpandedKeys(newKeys);
                setTimeout(() => scrollToSection("3"), 200);
            }
            return { ...prev, delivery: isDeliveryComplete };
        });
    }, [article.publicQuestions, article.checkQuestions, answeredQuestions, expandedKeys, showDeliveryScopeSection]);

    // CHECK IF CHECKLIST SECTION IS COMPLETE
    useEffect(() => {
        // SECTION IS COMPLETE WHEN:
        // 1. AT LEAST ONE DELIVERY SCOPE ITEM IS SELECTED OR
        // 2. "NO DELIVERY SCOPE" OPTION IS SELECTED OR
        // 3. THERE ARE NO DELIVERY SCOPE ITEMS AVAILABLE
        const isChecklistComplete =
            selectedDeliveryScope.length > 0 ||
            noDeliveryScope ||
            !showDeliveryScopeSection;

        setSectionCompletion(prev => ({
            ...prev,
            checklist: Boolean(isChecklistComplete)
        }));
    }, [selectedDeliveryScope, noDeliveryScope, showDeliveryScopeSection]);

    // HANDLE ACCORDION SELECTION CHANGES
    const handleSelectionChange = (keys: Set<string>) => {
        // ONLY ALLOW OPENING SECTIONS THAT ARE AVAILABLE BASED ON COMPLETION STATE
        const newKeys = new Set<string>();
        let scrollTarget = "";

        // OPTICS SECTION IS ALWAYS AVAILABLE
        if (keys.has("optics")) {
            newKeys.add("optics");
            scrollTarget = "optics";
        } else if (expandedKeys.has("optics")) {
            // DON'T ALLOW CLOSING THE OPTICS SECTION IF IT'S NOT COMPLETE
            if (!sectionCompletion.optics) {
                newKeys.add("optics");
            }
        }

        // DELIVERY SECTION IS AVAILABLE IF OPTICS IS COMPLETE
        if (keys.has("2") && sectionCompletion.optics) {
            newKeys.add("2");
            scrollTarget = "2";
        } else if (expandedKeys.has("2")) {
            // DON'T ALLOW CLOSING THE DELIVERY SECTION IF IT'S NOT COMPLETE
            if (!sectionCompletion.delivery && sectionCompletion.optics) {
                newKeys.add("2");
            }
        }

        // CHECKLIST SECTION IS AVAILABLE IF DELIVERY IS COMPLETE AND IT EXISTS
        if (keys.has("3") && sectionCompletion.delivery && showDeliveryScopeSection) {
            newKeys.add("3");
            scrollTarget = "3";
        } else if (expandedKeys.has("3")) {
            // DON'T ALLOW CLOSING THE CHECKLIST SECTION IF IT'S NOT COMPLETE
            if (!sectionCompletion.checklist && sectionCompletion.delivery && showDeliveryScopeSection) {
                newKeys.add("3");
            }
        }

        setExpandedKeys(newKeys);

        // IF THIS IS A NEW SECTION BEING OPENED, SCROLL TO IT
        if (scrollTarget && !expandedKeys.has(scrollTarget)) {
            setTimeout(() => scrollToSection(scrollTarget), 200);
        }
    };

    // TRACK QUESTION CHANGES FOR PROGRESS TRACKING
    const handleQuestionChangeWithTracking = (questionId: number, newValue: boolean | number | string) => {
        handleQuestionChange(questionId, newValue);

        // MARK THE QUESTION AS ANSWERED IN LOCAL TRACKING STATE
        setAnsweredQuestions(prev => {
            const updated = new Set(prev);
            updated.add(questionId);
            return updated;
        });
    };

    return (
        <>
            <CardHeader className="justify-between">
                <div className="flex gap-5 min-w-0 flex-1 overflow-hidden">
                    <img
                        src={article.imageUrl}
                        alt={article.title}
                        className="w-24 h-24 shrink-0 object-contain rounded-md border p-2"
                    />
                    <div className="flex flex-col gap-1 items-start justify-center min-w-0 overflow-hidden">
                        <h4 className="text-small font-semibold leading-none text-default-600 line-clamp-2 w-full">{article.title}</h4>
                        <div className="w-full overflow-hidden">
                            <h5 className="text-small tracking-tight text-default-400 truncate">Modell: {article.modelNumbers}</h5>
                            <h5 className="text-small tracking-tight text-default-400 break-all">
                                EAN: {Array.isArray(article.eans) ? article.eans.join(", ") : article.eans}
                            </h5>
                            <h5 className="text-small tracking-tight text-default-400 truncate">ASIN: {article.articleRef}</h5>
                        </div>
                    </div>
                </div>
                <div className="shrink-0 ml-4">
                    <Dropdown backdrop="blur" showArrow placement="bottom-end">
                        <DropdownTrigger>
                            <Button isIconOnly
                                radius="full"
                                size="sm"
                                variant="bordered"
                            >
                                <Icon
                                    className="text-default-500"
                                    icon="solar:menu-dots-outline"
                                    width={20}
                                />
                            </Button>
                        </DropdownTrigger>
                        <DropdownMenu>
                            <DropdownItem
                                key="change"
                                description="Artikel ändern"
                                onPress={onModelChangeOpen}
                                startContent={<Icon
                                    className="text-default-500"
                                    icon="solar:round-transfer-horizontal-outline"
                                    width={24}
                                />}
                            >
                                Modellwechsel
                            </DropdownItem>
                            <DropdownItem
                                key="edit"
                                description="Daten korrigieren"
                                onPress={onImprovementOpen}
                                startContent={<Icon
                                    className="text-default-500"
                                    icon="solar:pen-new-square-outline"
                                    width={24}
                                />}
                            >
                                Verbesserungsvorschlag
                            </DropdownItem>
                        </DropdownMenu>
                    </Dropdown>
                </div>
            </CardHeader>
            <CardBody>
                {article.checkInformation && (
                    <Alert 
                        key="flat" 
                        title="Es liegen Bearbeitungshinweise vor"
                        endContent={
                            <Button
                                variant="flat"
                                color="default"
                                size="sm"
                                onPress={onProcessingInstructionsOpen}
                            >
                                Anzeigen
                            </Button>
                        }
                    />
                )}

                <Accordion
                    id="accordion-sections"
                    selectedKeys={expandedKeys}
                    onSelectionChange={handleSelectionChange}
                    selectionMode="multiple"
                    keepContentMounted
                    showDivider={false}
                >
                    <AccordionItem
                        key="optics"
                        aria-label="Accordion 1"
                        subtitle="Bewerte die Optik des Artikels"
                        data-accordion-item="optics"
                        title={
                            <div className="flex items-center gap-2">
                                <span>1. Optischer Zustand</span>
                                {sectionCompletion.optics && (
                                    <Icon icon="solar:check-circle-bold" className="text-success" width={20} />
                                )}
                            </div>
                        }
                        classNames={{
                            base: "mb-6",
                            title: "text-base font-medium",
                        }}
                    >
                        <div ref={opticsRef} className="pt-2">
                            {article.hasConditionCalculator && (
                                <Alert className="mb-6" color="success"
                                    description="Ermittle den optischen Zustand mit Hilfe von Bildern."
                                    endContent={
                                        <Button size="sm" variant="flat" color="success" onPress={onConditionCalculatorOpen}>
                                            Zustand ermitteln
                                        </Button>
                                    }
                                    title="Zustandsrechner verfügbar"
                                    variant="faded"
                                />
                            )}
                            {article.conditionQuestions?.length && article.conditionQuestions?.length > 0 ? (
                                <div className="space-y-4">
                                    {article.conditionQuestions?.map((question) => (
                                        <ArticleQuestionComponent
                                            key={question.id}
                                            question={question}
                                            onChange={handleQuestionChangeWithTracking}
                                        />
                                    ))}
                                    {/* CONDITION SELECTION WITH CONDITIONAL COMPARISON ALERT */}
                                    {currentCondition && calculatorCondition ? (
                                        <>
                                            <Alert
                                                color={currentCondition.id === calculatorCondition.id ? "success" : "danger"}
                                                description={currentCondition.id === calculatorCondition.id ?
                                                    "entspricht Zustandsrechner" :
                                                    "weicht vom Zustandsrechner ab"
                                                }
                                                title="Optischer Zustand"
                                                variant="faded"
                                                endContent={
                                                    <Select
                                                        className="max-w-sm"
                                                        size="sm"
                                                        variant="flat"
                                                        label="Zustand auswählen"
                                                        disallowEmptySelection
                                                        selectedKeys={[currentCondition.id.toString()]}
                                                        onSelectionChange={(keys) => {
                                                            const selectedKey = Array.from(keys)[0];
                                                            if (selectedKey) {
                                                                onConditionChange(parseInt(selectedKey.toString()));
                                                            }
                                                        }}
                                                    >
                                                        {article?.conditions?.map((condition) => (
                                                            <SelectItem key={condition.id}>{condition.name}</SelectItem>
                                                        ))}
                                                    </Select>
                                                }
                                            />
                                        </>
                                    ) : (
                                        <>
                                            <Alert
                                                title="Gesamtzustand"
                                                variant="faded"
                                                endContent={
                                                    <Select
                                                        className="max-w-sm"
                                                        size="sm"
                                                        variant="flat"
                                                        label="Zustand auswählen"
                                                        disallowEmptySelection
                                                        selectedKeys={currentCondition ? [currentCondition.id.toString()] : []}
                                                        onSelectionChange={(keys) => {
                                                            const selectedKey = Array.from(keys)[0];
                                                            if (selectedKey) {
                                                                onConditionChange(parseInt(selectedKey.toString()));
                                                            }
                                                        }}
                                                    >
                                                        {article?.conditions?.map((condition) => (
                                                            <SelectItem key={condition.id}>{condition.name}</SelectItem>
                                                        ))}
                                                    </Select>
                                                }
                                            />
                                            {currentCondition && (
                                                <div className="mt-4">
                                                    <ArticleEditor
                                                        value={conditionChangeText}
                                                        onChange={onConditionChangeTextChange}
                                                        label="Begründung der Zustandsänderung"
                                                        placeholder="Bitte beschreiben Sie hier, warum Sie den Zustand geändert haben..."
                                                    />
                                                </div>
                                            )}
                                        </>
                                    )}
                                </div>
                            ) : (
                                <div className="space-y-4">
                                    <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
                                        {article.conditions?.map((condition) => {
                                            let colorClass = "";
                                            const chipColor = condition.id === 0 ? "success" :
                                                condition.id === 2 ? "success" :
                                                condition.id === 3 ? "warning" :
                                                condition.id === 4 ? "primary" : "default" as const;

                                            const dotColor = condition.id === 0 ? "bg-[#006400]" :
                                                chipColor === "success" ? "bg-success" :
                                                chipColor === "warning" ? "bg-warning" :
                                                chipColor === "primary" ? "bg-primary" :
                                                "bg-default";

                                            const isSelected = currentCondition?.id === condition.id;

                                            colorClass = isSelected
                                                ? "border-default-300 bg-default-100 hover:border-default-400 hover:bg-default-200"
                                                : "border-default-200 bg-default-50/50 hover:border-default-300 hover:bg-default-100";

                                            const baseClass = `p-3 rounded-lg border cursor-pointer transition-all ${colorClass}`;

                                            return (
                                                <div
                                                    key={condition.id}
                                                    onClick={() => onConditionChange(condition.id)}
                                                    className={baseClass}
                                                >
                                                    <div className="flex items-center justify-between">
                                                        <div className="flex flex-col">
                                                            <span className="font-medium">{condition.name}</span>
                                                            <span className="text-xs text-default-500">{condition.description}</span>
                                                        </div>
                                                        <div className="flex items-center">
                                                            {isSelected ? (
                                                                <Chip
                                                                    color={chipColor}
                                                                    variant="flat"
                                                                    size="sm"
                                                                    startContent={<Icon icon="solar:check-circle-bold" width={16} />}
                                                                >
                                                                    Ausgewählt
                                                                </Chip>
                                                            ) : (
                                                                <div className={`w-[20px] h-[20px] rounded-full ${dotColor}`} />
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            )}
                        </div>
                    </AccordionItem>

                    <AccordionItem
                        key="2"
                        aria-label="Accordion 2"
                        subtitle="Beatworte Fragen zum Artikel, die für Funktion und Zustand wichtig sind"
                        data-accordion-item="delivery"
                        title={
                            <div className="flex items-center gap-2">
                                <span>2. Wichtige Fragen</span>
                                {sectionCompletion.delivery && (
                                    <Icon icon="solar:check-circle-bold" className="text-success" width={20} />
                                )}
                            </div>
                        }
                        classNames={{
                            base: `mb-6 ${!sectionCompletion.optics ? "opacity-50" : ""}`,
                            title: "text-base font-medium",
                        }}
                        isDisabled={!sectionCompletion.optics}
                    >
                        <div ref={deliveryRef} className="pt-2">
                            <div className="space-y-4">
                                {[...(article.publicQuestions || []), ...(article.checkQuestions || [])].map((question) => (
                                    <ArticleQuestionComponent
                                        key={question.id}
                                        question={question}
                                        onChange={handleQuestionChangeWithTracking}
                                    />
                                ))}
                            </div>
                        </div>
                    </AccordionItem>

                    {showDeliveryScopeSection ? (
                        <AccordionItem
                            key="3"
                            aria-label="Accordion 3"
                            subtitle="Gib an, was alles dabei ist"
                            data-accordion-item="checklist"
                            title={
                                <div className="flex items-center gap-2">
                                    <span>3. Lieferumfang</span>
                                    {sectionCompletion.checklist && (
                                        <Icon icon="solar:check-circle-bold" className="text-success" width={20} />
                                    )}
                                </div>
                            }
                            classNames={{
                                base: `${!sectionCompletion.delivery ? "opacity-50" : ""}`,
                                title: "text-base font-medium",
                            }}
                            isDisabled={!sectionCompletion.delivery}
                        >
                            <div ref={checklistRef} className="pt-2">
                                <ArticleDeliveryScope
                                    deliveryScope={article.deliveryScope || []}
                                    onChange={onDeliveryScopeChange}
                                />
                            </div>
                        </AccordionItem>
                    ) : null}
                </Accordion>
            </CardBody>
        </>
    );
};

export default ArticleInfoCard;