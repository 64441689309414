const emojiMap = {
  0: { emoji: '👍', name: 'Nice' },
  1: { emoji: '❤️', name: 'Love it!' },
  2: { emoji: '😂', name: 'Haha' },
  3: { emoji: '😔', name: 'Traurig' },
  4: { emoji: '🙏', name: 'Danke' },
  5: { emoji: '🚀', name: 'Blast off' },
  6: { emoji: '🥳', name: 'Party' },
  7: { emoji: '😠', name: 'Wütend' },
};

export const getEmojiByEmojiId = (emojiId) =>
  emojiMap[emojiId]?.emoji || '❓';

export const getNameByEmojiId = (emojiId) =>
  emojiMap[emojiId]?.name || 'Unknown';

export const getIdByEmoji = (emoji) => {
  const entry = Object.entries(emojiMap).find(([, value]) => value.emoji === emoji);
  return entry ? parseInt(entry[0]) : null;
};

export { emojiMap };