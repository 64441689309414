export class ArticleBasicModel {
    articleId: number;
    title: string;
    imageUrl: string;
    eans?: (string | null)[];
    modelNumbers?: (string | null)[];
    articleRef?: string;
  
    constructor(init: Partial<ArticleBasicModel>) {
      Object.assign(this, init);
    }
  
    static fromJson(json: any): ArticleBasicModel {
      return new ArticleBasicModel({
        articleId: json.articleId,
        title: json.title,
        imageUrl: json.imageUrl,
        eans: json.eans,
        modelNumbers: json.modelNumbers,
        articleRef: json.articleRef,
      });
    }
  
    static toJson(article: ArticleBasicModel): any {
      return {
        articleId: article.articleId,
        title: article.title,
        imageUrl: article.imageUrl,
        eans: article.eans,
        modelNumbers: article.modelNumbers,
        articleRef: article.articleRef,
      };
    }
  }
  