import { create } from 'zustand';
import { Node, Edge, XYPosition } from 'reactflow';

// Hilfsfunktion zur Generierung eindeutiger IDs
const generateId = () => `${Date.now()}-${Math.random().toString(36).substr(2, 9)}`;

interface FlowState {
    nodes: Node[];
    edges: Edge[];
    selectedNode: Node | null;
    selectedEdge: Edge | null;
    addNode: (node: Partial<Node>) => void;
    updateNode: (nodeId: string, data: any) => void;
    updateNodePosition: (nodeId: string, position: XYPosition) => void;
    deleteNode: (nodeId: string) => void;
    addEdge: (edge: Partial<Edge>) => void;
    updateEdge: (edgeId: string, data: any) => void;
    deleteEdge: (edgeId: string) => void;
    setSelectedNode: (node: Node | null) => void;
    setSelectedEdge: (edge: Edge | null) => void;
    deleteSelectedNode: () => void;
}

export const useFlowStore = create<FlowState>((set, get) => ({
    nodes: [],
    edges: [],
    selectedNode: null,
    selectedEdge: null,

    addNode: (node) => {
        const newNode: Node = {
            id: generateId(),
            type: node.type || 'custom',
            position: node.position || { x: 100, y: 100 },
            data: { 
                label: '',
                description: '',
                ...node.data,
                onChange: (value: string) => {
                    const store = get();
                    store.updateNode(newNode.id, { label: value });
                },
                onDescriptionChange: (value: string) => {
                    const store = get();
                    store.updateNode(newNode.id, { description: value });
                }
            },
        };

        set((state) => ({
            nodes: [...state.nodes, newNode],
        }));
    },

    updateNode: (nodeId, data) => {
        set((state) => ({
            nodes: state.nodes.map((node) =>
                node.id === nodeId
                    ? { ...node, data: { ...node.data, ...data } }
                    : node
            ),
            selectedNode: state.selectedNode?.id === nodeId
                ? { ...state.selectedNode, data: { ...state.selectedNode.data, ...data } }
                : state.selectedNode
        }));
    },

    updateNodePosition: (nodeId, position) => {
        set((state) => ({
            nodes: state.nodes.map((node) =>
                node.id === nodeId
                    ? { ...node, position }
                    : node
            ),
            selectedNode: state.selectedNode?.id === nodeId
                ? { ...state.selectedNode, position }
                : state.selectedNode
        }));
    },

    deleteNode: (nodeId) => {
        set((state) => ({
            nodes: state.nodes.filter((node) => node.id !== nodeId),
            edges: state.edges.filter(
                (edge) => edge.source !== nodeId && edge.target !== nodeId
            ),
            selectedNode: state.selectedNode?.id === nodeId ? null : state.selectedNode
        }));
    },

    addEdge: (edge) => {
        const newEdge: Edge = {
            id: generateId(),
            type: edge.type || 'custom',
            source: edge.source || '',
            target: edge.target || '',
            sourceHandle: edge.sourceHandle,
            targetHandle: edge.targetHandle,
            data: { 
                label: '',
                style: 'arrow-right',
                ...edge.data,
                onChange: (value: string) => {
                    const store = get();
                    store.updateEdge(newEdge.id, { label: value });
                }
            },
        };

        set((state) => ({
            edges: [...state.edges, newEdge],
        }));
    },

    updateEdge: (edgeId, data) => {
        set((state) => ({
            edges: state.edges.map((edge) =>
                edge.id === edgeId
                    ? { ...edge, data: { ...edge.data, ...data.data } }
                    : edge
            ),
            selectedEdge: state.selectedEdge?.id === edgeId
                ? { ...state.selectedEdge, data: { ...state.selectedEdge.data, ...data.data } }
                : state.selectedEdge
        }));
    },

    deleteEdge: (edgeId) => {
        set((state) => ({
            edges: state.edges.filter((edge) => edge.id !== edgeId),
            selectedEdge: state.selectedEdge?.id === edgeId ? null : state.selectedEdge
        }));
    },

    setSelectedNode: (node) => {
        set({ selectedNode: node });
    },

    setSelectedEdge: (edge) => {
        set({ selectedEdge: edge });
    },

    deleteSelectedNode: () => {
        const { selectedNode, deleteNode } = get();
        if (selectedNode) {
            deleteNode(selectedNode.id);
        }
    },
})); 