import { useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { Icon } from "@iconify/react/dist/iconify.js";
import FeedPage from "../feed/Page";
import Food from "../Food";
import People from "../People";
import WikiPage from "../wiki/Page";
import SickReport from "../sick-report/SickReport";
import Settings from "../settings/Settings";
import AppPromotionsPage from "../app-promotions/Page";
import AppFeedPage from "../app-feed/Page";
import AppPushPage from "../app-push/Page";
import AppFeedbackPage from "../app-feedback/Page";
import userService from "@/services/userService";
import WaWiPage from "../wawi/Page";
import MailPage from "../mail/Page";
import WebDesignerPage from "../webdesigner/Page";
import Article from "../article/page";
import FlowMePage from "../flowme/Page";
import Ebay from "../ebay/page";

export function useMenuItems() {
    const navigate = useNavigate();

    // NAVIGATION HANDLERS
    const handleOpenFeedCreate = useCallback(() => {
        navigate('/feed/post/create');
    }, [navigate]);

    const handleOpenChatCreate = useCallback(() => {
        navigate('/chat/create');
    }, [navigate]);

    const handleOpenToDoCreate = useCallback(() => {
        navigate('/todo/create');
    }, [navigate]);

    const handleOpenFoodCreate = useCallback(() => {
        navigate('/food/offer/create');
    }, [navigate]);

    const handleOpenWikiCreate = useCallback(() => {
        navigate('/wiki/create');
    }, [navigate]);

    const handleOpenCalendarCreate = useCallback(() => {
        navigate('/calendar/event/create');
    }, [navigate]);

    // MENU ITEMS
    const menuItems = [
        {
            key: 'feed',
            icon: 'solar:home-2-linear',
            title: 'Feed',
            component: FeedPage,
            endContent: (
                <Icon className="text-default-400" icon="solar:add-circle-outline" width={24} onClick={handleOpenFeedCreate} />
            ),
        },
        {
            key: 'food',
            icon: 'solar:chef-hat-heart-linear',
            title: 'Mittagstisch',
            component: Food,
        },
        // {
        //   key: "chats",
        //   icon: "solar:chat-round-line-linear",
        //   title: "Chats",
        //   component: "Chat",
        //   endContent: (
        //     <Icon className="text-default-400" icon="solar:add-circle-outline" width={24} onClick={handleOpenChatCreate} />
        //   ),
        // },
        // {
        //   key: "todo",
        //   icon: "solar:checklist-minimalistic-linear",
        //   title: "ToDo",
        //   component: "Todo",
        //   endContent: (
        //     <Icon className="text-default-400" icon="solar:add-circle-outline" width={24} onClick={handleOpenToDoCreate} />
        //   ),
        // },
        {
            key: 'team',
            icon: 'solar:users-group-two-rounded-linear',
            title: 'Team',
            component: People,
        },
        {
            key: 'wiki',
            icon: 'solar:notebook-bookmark-linear',
            title: 'Wiki',
            component: WikiPage,
            endContent: (userService.user.modules.includes(6) || userService.user.isHeadOf) && (
                <Icon
                    className="text-default-400"
                    icon="solar:add-circle-outline"
                    width={24}
                    onClick={handleOpenWikiCreate}
                />
            ),
        },
        // {
        //   key: "calendar",
        //   icon: "solar:calendar-linear",
        //   title: "Kalender",
        //   component: "Calendar",
        //   endContent: (
        //     <Icon className="text-default-400" icon="solar:add-circle-outline" width={24} onClick={handleOpenCalendarCreate} />
        //   ),
        // },
        // {
        //   key: "photo",
        //   icon: "solar:gallery-wide-linear",
        //   title: "Fotoalbum",
        //   component: "Photo",
        // },
        {
            key: 'sick',
            icon: 'solar:bacteria-linear',
            title: 'Krankmeldung',
            component: SickReport,
        },
        {
            key: 'settings',
            icon: 'solar:settings-outline',
            title: 'Einstellungen',
            component: Settings,
        },
    ];

    const toolItems = [
        // {
        //     key: 'flowbuilder',
        //     icon: 'solar:routing-outline',
        //     title: 'Flow-Builder',
        //     component: FlowMePage,
        // },
    ];

    // ADMIN ITEMS
    const adminItems = [];
    if (userService.user.modules.includes(1)) {
        adminItems.push({
            key: "admin-article",
            icon: "solar:clipboard-text-linear",
            title: "Artikelaufruf",
            component: Article,
        });
    }
    if (userService.user.modules.includes(1)) {
        adminItems.push({
            key: "admin-ebay",
            icon: "solar:tag-price-outline",
            title: "eBay Upload",
            component: Ebay,
        });
    }
    // if (userService.user.modules.includes(2)) {
    //   adminItems.push({
    //     key: "admin-articlerequests",
    //     icon: "solar:chat-round-money-outline",
    //     title: "Ankaufanfragen",
    //     component: "ArticleRequests",
    //   });
    // }
    if (userService.user.modules.includes(3)) {
        adminItems.push({
            key: 'admin-promotion',
            icon: 'solar:gallery-wide-linear',
            title: 'App-Promotions',
            component: AppPromotionsPage,
        });
    }
    if (userService.user.modules.includes(4)) {
        adminItems.push({
            key: 'admin-feed',
            icon: 'solar:feed-outline',
            title: 'App-Feed',
            component: AppFeedPage,
        });
    }
    if (userService.user.modules.includes(8)) {
        adminItems.push({
            key: 'admin-push',
            icon: 'solar:notification-unread-lines-outline',
            title: 'App-Pushnachrichten',
            component: AppPushPage,
        });
    }
    // if (userService.user.modules.includes(5)) {
    //     adminItems.push({
    //         key: 'admin-feedback',
    //         icon: 'solar:user-speak-rounded-outline',
    //         title: 'App-Feedback',
    //         component: AppFeedbackPage,
    //     });
    // }
    if (userService.user.modules.includes(15)) {
        adminItems.push({
            key: "admin-mail",
            icon: "solar:mailbox-outline",
            title: "Mail-Designer",
            component: MailPage,
        });
    }
    if (userService.user.modules.includes(15)) {
        adminItems.push({
            key: "admin-webdesigner",
            icon: "solar:palette-round-outline",
            title: "Web-Designer",
            component: WebDesignerPage,
        });
    }
    if (userService.user.modules.includes(9) || userService.user.modules.includes(10)) {
        adminItems.push({
            key: "admin-wawi",
            icon: "solar:bag-smile-outline",
            title: "WaWi Aufträge",
            component: WaWiPage,
        });
    }
    // if (userService.user.modules.includes(11)) {
    //   adminItems.push({
    //     key: "admin-customer",
    //     icon: "solar:user-id-outline",
    //     title: "Kundendaten",
    //     component: "Customer",
    //   });
    // }

    // QUICKLINKS
    const quickLinks = [];
    quickLinks.push({
        key: 'ql1',
        icon: 'solar:layers-outline',
        title: 'ZOXS Zentrum',
        href: 'https://www.sx-oz.de/index.html',
        endContent: (
            <Icon className="text-default-400" icon="solar:circle-top-up-outline" width={24} />
        ),
    });
    if (['ma', 'cs', 'ceo'].includes(userService.user.department)) {
        quickLinks.splice(2, 0, {
            key: 'ql3',
            icon: 'solar:call-chat-rounded-outline',
            title: 'Dixa (Customer Service)',
            href: 'https://zoxs.dixa.com/',
            endContent: (
                <Icon className="text-default-400" icon="solar:circle-top-up-outline" width={24} />
            ),
        });
    }
    if (['ma', 'sd', 'ceo'].includes(userService.user.department)) {
        quickLinks.splice(1, 0, {
            key: 'ql2',
            icon: 'solar:lightbulb-bolt-outline',
            title: 'Creado (Zentrum)',
            href: 'https://www.sx-oz.de/zoxsdos.html',
            endContent: (
                <Icon className="text-default-400" icon="solar:circle-top-up-outline" width={24} />
            ),
        });
    }
    if (['ma', 'sd', 'ceo'].includes(userService.user.department) || userService.user.isHeadOf) {
        quickLinks.push({
            key: 'ql6',
            icon: 'solar:ticket-outline',
            title: 'Ticketing (Zentrum)',
            href: 'https://www.sx-oz.de/ticketing.html',
            endContent: (
                <Icon className="text-default-400" icon="solar:circle-top-up-outline" width={24} />
            ),
        });
    }
    quickLinks.push({
        key: 'ql4',
        icon: 'solar:hand-money-outline',
        title: 'ZOXS (Ankauf)',
        href: 'https://www.zoxs.de/index.html',
        endContent: (
            <Icon className="text-default-400" icon="solar:circle-top-up-outline" width={24} />
        ),
    });
    quickLinks.push({
        key: 'ql5',
        icon: 'solar:bag-heart-outline',
        title: 'buyZOXS (Shop)',
        href: 'https://www.buyzoxs.de/',
        endContent: (
            <Icon className="text-default-400" icon="solar:circle-top-up-outline" width={24} />
        ),
    });

    // SOCIAL MEDIA LINKS
    const socialMediaLinks = [
        {
            key: 'sm1',
            icon: 'hugeicons:instagram',
            title: 'Instagram',
            href: 'https://www.instagram.com/zoxs_de/',
            endContent: (
                <Icon className="text-default-400" icon="solar:circle-top-up-outline" width={24} />
            ),
        },
        {
            key: 'sm2',
            icon: 'hugeicons:tiktok',
            title: 'TikTok',
            href: 'https://www.tiktok.com/@zoxs.de',
            endContent: (
                <Icon className="text-default-400" icon="solar:circle-top-up-outline" width={24} />
            ),
        },
        {
            key: 'sm3',
            icon: 'hugeicons:facebook-01',
            title: 'Facebook',
            href: 'https://www.facebook.com/ZOXS.de',
            endContent: (
                <Icon className="text-default-400" icon="solar:circle-top-up-outline" width={24} />
            ),
        },
        {
            key: 'sm4',
            icon: 'hugeicons:linkedin-01',
            title: 'LinkedIn',
            href: 'https://de.linkedin.com/company/zoxs-gmbh',
            endContent: (
                <Icon className="text-default-400" icon="solar:circle-top-up-outline" width={24} />
            ),
        },
    ];

    const sidebarItems = useMemo(() => {
        const sections = [
            {
                key: 'main',
                title: '',
                items: menuItems,
            },
            // {
            //     key: 'tools',
            //     title: 'Tools',
            //     items: toolItems,
            // },
        ];

        if (adminItems.length > 0) {
            sections.push({
                key: 'management',
                title: 'ZOXS Verwaltung',
                items: adminItems,
            });
        }

        if (quickLinks.length > 0) {
            sections.push({
                key: 'quicklinks',
                title: 'Quicklinks',
                items: quickLinks,
            });
        }

        if (socialMediaLinks.length > 0) {
            sections.push({
                key: 'socialmedia',
                title: 'Social-Media',
                items: socialMediaLinks,
            });
        }

        return sections;
    }, [menuItems, /*toolItems*/, adminItems, quickLinks, socialMediaLinks]);

    return sidebarItems;
}
