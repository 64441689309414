import {
    Drawer,
    DrawerContent,
    DrawerHeader,
    DrawerBody,
    DrawerFooter,
    Button,
    Image,
    Tooltip,
    Spinner,
    Card,
    RadioGroup,
} from "@heroui/react";
import { useEffect, useState } from "react";
import articleService from "../api/articleService";
import { ConditionCalculatorModel } from "../models/conditionCalculator";
import { ArticleCheckCondition } from "../models/articleCondition";

interface ConditionCalculatorDrawerProps {
    isOpen: boolean;
    onOpenChange: (isOpen: boolean) => void;
    articleId: number | undefined;
    onConditionSelected?: (condition: ArticleCheckCondition) => void;
}

export default function ConditionCalculatorDrawer({
    isOpen,
    onOpenChange,
    articleId,
    onConditionSelected
}: ConditionCalculatorDrawerProps) {
    const [isLoading, setIsLoading] = useState(false);
    const [calculatorData, setCalculatorData] = useState<ConditionCalculatorModel | null>(null);
    const [error, setError] = useState<string | null>(null);
    const [currentStep, setCurrentStep] = useState(0);
    const [selectedOptions, setSelectedOptions] = useState<Record<number, number | null>>({});
    const [result, setResult] = useState<ArticleCheckCondition | null>(null);
    const [isSubmitting, setIsSubmitting] = useState(false);

    // FETCH CALCULATOR DATA
    useEffect(() => {
        let isMounted = true;
        if (isOpen) {
            setCalculatorData(null);
            setSelectedOptions({});
            setCurrentStep(0);
            setIsLoading(true);
            setError(null);
            setResult(null);

            const fetchData = async () => {
                try {
                    const fetchedData = await articleService.getConditionCalculator(articleId!);
                    if (isMounted && fetchedData) {
                        setCalculatorData(fetchedData);
                    } else {
                        throw new Error("Calculator not found or empty response.");
                    }
                } catch (error: any) {
                    if (isMounted) {
                        setError(error.message || "An unknown error occurred.");
                    }
                } finally {
                    if (isMounted) {
                        setIsLoading(false);
                    }
                }
            };

            fetchData();
        }

        return () => {
            isMounted = false;
        };
    }, [articleId, isOpen]);

    const handleOptionSelect = (index: number) => {
        setSelectedOptions((prev) => ({ ...prev, [currentStep]: index }));
    };

    const handleNext = () => {
        // Only proceed if an option is selected for the current step
        if (selectedOptions[currentStep] !== undefined && selectedOptions[currentStep] !== null) {
            if (currentStep < (calculatorData?.steps.length ?? 0) - 1) {
                setCurrentStep((prev) => prev + 1);
            } else {
                console.log("Ergebnis anzeigen");
            }
        }
    };

    const handleBack = () => {
        if (result) {
            // If we're on the results page, go back to the last question
            setResult(null);
            setCurrentStep((calculatorData?.steps.length ?? 1) - 1);
        } else if (currentStep > 0) {
            // If we're on a question, go back to the previous question
            setCurrentStep((prev) => prev - 1);
        }
    };

    const handleSubmit = async () => {
        setIsSubmitting(true);
        setError(null);
        try {
            const answersArray = Object.values(selectedOptions).filter((answer): answer is number => answer !== null);
            const resultData = await articleService.getConditionCalculatorResult(articleId!, answersArray);
            setResult(resultData);

            // Pass the result to parent component if callback is provided
            if (onConditionSelected && resultData) {
                onConditionSelected(resultData);
            }
        } catch (error: any) {
            setError(error.message || "Failed to fetch the result.");
        } finally {
            setIsSubmitting(false);
        }
    };

    const isLastStep = currentStep === (calculatorData?.steps.length ?? 0) - 1;
    const isNextDisabled = selectedOptions[currentStep] === undefined || selectedOptions[currentStep] === null;

    return (
        <Drawer
            hideCloseButton
            backdrop="blur"
            classNames={{
                base: "data-[placement=right]:sm:m-2 data-[placement=left]:sm:m-2 rounded-medium",
            }}
            isOpen={isOpen}
            onOpenChange={onOpenChange}
        >
            <DrawerContent>
                {(onClose) => (
                    <>
                        <DrawerHeader className="absolute top-0 inset-x-0 z-50 flex flex-row gap-2 px-2 py-2 border-b border-default-200/50 justify-between bg-content1/50 backdrop-saturate-150 backdrop-blur-lg">
                            <Tooltip content="Schließen">
                                <Button
                                    isIconOnly
                                    className="text-default-400"
                                    size="sm"
                                    variant="light"
                                    onPress={onClose}
                                >
                                    <svg
                                        fill="none"
                                        height="20"
                                        stroke="currentColor"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        viewBox="0 0 24 24"
                                        width="20"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path d="m13 17 5-5-5-5M6 17l5-5-5-5" />
                                    </svg>
                                </Button>
                            </Tooltip>
                        </DrawerHeader>
                        <DrawerBody className="pt-16">
                            {isLoading ? (
                                <div className="flex w-full justify-center items-center pt-4">
                                    <Spinner size="lg" />
                                </div>
                            ) : error ? (
                                <div className="flex w-full justify-center items-center pt-4">
                                    <Card className="w-full rounded-lg border bg-background p-4 shadow">
                                        <h3 className="text-red-600 font-bold text-xl">Ups</h3>
                                        <p className="text-red-500">{error}</p>
                                    </Card>
                                </div>
                            ) : result ? (
                                // ERGEBNIS-ANSICHT
                                <div className="flex flex-col items-center">
                                    <div className="w-full mb-6">
                                        <div className="flex items-center justify-center mb-4">
                                            <div className="bg-primary-50 px-4 py-2 rounded-full text-primary-700 font-bold text-lg">
                                                {result.name}
                                            </div>
                                        </div>
                                        <div className="text-center mb-6">
                                            <p className="text-default-700">{result.description}</p>
                                        </div>

                                        <h3 className="font-semibold text-default-700 mb-3">Eigenschaften des Artikels:</h3>
                                        <div className="space-y-3 mb-6">
                                            {Object.entries(selectedOptions).map(([stepIndex, answerIndex]) => {
                                                const step = calculatorData?.steps[parseInt(stepIndex)];
                                                const answer = step?.answers[answerIndex as number];
                                                return step ? (
                                                    <div key={stepIndex} className="flex items-center">
                                                        <div className="w-2 h-2 bg-primary rounded-full mr-2"></div>
                                                        <div className="text-sm text-default-600">
                                                            <span className="font-medium">{step.question}:</span> {answer}
                                                        </div>
                                                    </div>
                                                ) : null;
                                            })}
                                        </div>
                                    </div>

                                    <h3 className="font-semibold text-default-700 mb-3 w-full">Ausgewählte Bilder:</h3>
                                    <div className="grid grid-cols-2 gap-3 w-full">
                                        {Object.entries(selectedOptions).map(([stepIndex, answerIndex]) => {
                                            const step = calculatorData?.steps[parseInt(stepIndex)];
                                            const imageUrl = step?.images[answerIndex as number];
                                            return imageUrl ? (
                                                <div key={stepIndex} className="overflow-hidden">
                                                    <Image
                                                        alt={`Condition ${stepIndex}`}
                                                        src={imageUrl}
                                                        className="w-full h-auto"
                                                    />
                                                    <div className="p-2 mt-4 text-xs text-default-600">
                                                        {step?.question}
                                                    </div>
                                                </div>
                                            ) : null;
                                        })}
                                    </div>
                                </div>
                            ) : calculatorData ? (
                                // FRAGEN-ANSICHT
                                <>
                                    <div className="flex w-full justify-center items-center pt-4">
                                        <Image
                                            isBlurred
                                            alt="Calc-Image"
                                            className="w-full"
                                            height={400}
                                            src={calculatorData.steps[currentStep].images[selectedOptions[currentStep] ?? 0]}
                                        />
                                    </div>
                                    <div className="flex flex-col gap-2 py-4">
                                        <h1 className="text-2xl font-bold leading-7 mb-4">{calculatorData.steps[currentStep].question}</h1>
                                        <RadioGroup>
                                            {calculatorData.steps[currentStep].answers.map((answer, index) => (
                                                <ConditionCalculatorRadio
                                                    key={index}
                                                    onClick={() => handleOptionSelect(index)}
                                                    isSelected={selectedOptions[currentStep] === index}
                                                >
                                                    {answer}
                                                </ConditionCalculatorRadio>
                                            ))}
                                        </RadioGroup>
                                    </div>
                                </>
                            ) : (
                                <div className="flex w-full justify-center items-center pt-4">
                                    <p className="text-default-500">Keine Rechnerdaten verfügbar.</p>
                                </div>
                            )}
                        </DrawerBody>
                        <DrawerFooter className="flex flex-col gap-1">
                            <div className="flex gap-1 items-center justify-end">
                                {/* Show back button both when not on first step and when on result page */}
                                {(currentStep > 0 || result) && (
                                    <Tooltip content="Zurück">
                                        <Button
                                            isIconOnly
                                            radius="full"
                                            className="text-default-500"
                                            size="sm"
                                            variant="flat"
                                            onPress={handleBack}
                                        >
                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24">
                                                <path
                                                    fill="currentColor"
                                                    fillRule="evenodd"
                                                    d="M15.488 4.43a.75.75 0 0 1 .081 1.058L9.988 12l5.581 6.512a.75.75 0 1 1-1.138.976l-6-7a.75.75 0 0 1 0-.976l6-7a.75.75 0 0 1 1.057-.081"
                                                    clipRule="evenodd"
                                                />
                                            </svg>
                                        </Button>
                                    </Tooltip>
                                )}
                                {result ? (
                                    <Button
                                        color="primary"
                                        radius="full"
                                        size="sm"
                                        onPress={() => onOpenChange(false)}
                                    >
                                        Schließen
                                    </Button>
                                ) : (
                                    <Button
                                        color="primary"
                                        radius="full"
                                        size="sm"
                                        onPress={isLastStep ? handleSubmit : handleNext}
                                        disabled={isNextDisabled || isSubmitting}
                                        className={isNextDisabled ? "opacity-50 cursor-not-allowed" : ""}
                                    >
                                        {isSubmitting ? <Spinner size="sm" /> : isLastStep ? "Ergebnis anzeigen" : "Weiter"}
                                    </Button>
                                )}
                            </div>
                        </DrawerFooter>
                    </>
                )}
            </DrawerContent>
        </Drawer>
    );
}

export const ConditionCalculatorRadio = ({ children, onClick, isSelected }) => {
    return (
        <div
            className={`cursor-pointer border-2 rounded-lg p-4 transition-all ${isSelected ? "border-primary" : "border-default"
                }`}
            onClick={onClick}
        >
            <span>{children}</span>
        </div>
    );
};