import React, { useEffect, useRef, useState } from "react";
import {
    Modal,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Button,
    useDraggable,
    ButtonGroup,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownTrigger,
    Selection,
    Alert,
} from "@heroui/react";
import { Camera, CameraType } from "react-camera-pro";
import { Icon } from "@iconify/react/dist/iconify.js";
import zoxsyCamera from "../../../assets/svg/zoxsy_camera.svg";
import captureSound from "../../../assets/sounds/capture.mp3";

interface Photo {
    id: string;
    original: string;
    edited?: string;
    isEdited: boolean;
}

interface CameraModalProps {
    isOpen: boolean;
    onOpenChange: () => void;
    detailId: string;
    onPhotosUpdate: (photos: Photo[]) => void;
    existingPhotos: Photo[];
    onNavigateToGallery: () => void;
}

// Hilfsfunktion für eindeutige IDs
const generateUniqueId = () => {
    return `${Date.now()}-${Math.random().toString(36).substr(2, 9)}`;
};

export default function CameraModal({ isOpen, onOpenChange, detailId, onPhotosUpdate, existingPhotos, onNavigateToGallery }: CameraModalProps) {
    const targetRef = React.useRef(null);
    const { moveProps } = useDraggable({ targetRef, isDisabled: !isOpen });

    const cameraRef = useRef<CameraType | null>(null);
    const cameraContainerRef = useRef<HTMLDivElement | null>(null);

    const [cameraHeight, setCameraHeight] = useState<number>(0);
    const [capturedPhotos, setCapturedPhotos] = useState<Photo[]>([]);
    const [selectedOption, setSelectedOption] = useState<Selection>(new Set(["direct"]));
    const [countdown, setCountdown] = useState<number | null>(null);
    const [flash, setFlash] = useState<boolean>(false);

    useEffect(() => {
        if (cameraContainerRef.current) {
            setCameraHeight(cameraContainerRef.current.clientHeight);
        }
    }, [isOpen]);

    // Reset captured photos when modal opens
    useEffect(() => {
        if (isOpen) {
            setCapturedPhotos([]);
        }
    }, [isOpen]);

    const labelsMap = {
        direct: "Foto aufnehmen",
        three: "in 3 Sekunden aufnehmen",
        five: "in 5 Sekunden aufnehemn",
    };

    const selectedOptionValue = Array.from(selectedOption)[0];

    const playCaptureSound = () => {
        const sound = new Audio(captureSound);
        sound.play();
    };

    const triggerFlashEffect = () => {
        setFlash(true);
        setTimeout(() => setFlash(false), 150);
    };

    const handleCapture = (delay = 0) => {
        if (delay > 0) {
            setCountdown(delay);
            const interval = setInterval(() => {
                setCountdown((prev) => (prev !== null ? prev - 1 : null));
            }, 1000);

            setTimeout(() => {
                clearInterval(interval);
                setCountdown(null);
                capturePhoto();
            }, delay * 1000);
        } else {
            capturePhoto();
        }
    };

    const capturePhoto = () => {
        if (cameraRef.current) {
            const photo = cameraRef.current.takePhoto();
            if (typeof photo === "string") {
                playCaptureSound();
                triggerFlashEffect();
                const newPhoto: Photo = {
                    id: generateUniqueId(),
                    original: photo,
                    isEdited: false
                };
                const updatedPhotos = [...existingPhotos, newPhoto];
                setCapturedPhotos([...capturedPhotos, newPhoto]);
                onPhotosUpdate(updatedPhotos);
            }
        }
    };

    return (
        <Modal ref={targetRef} isOpen={isOpen} onOpenChange={onOpenChange} backdrop="blur" isDismissable={false} size="5xl">
            <ModalContent>
                {(onClose) => (
                    <>
                        <ModalHeader {...moveProps}>Kamera</ModalHeader>
                        <ModalBody>
                            <div className="flex gap-4 items-start">
                                <div ref={cameraContainerRef} className="w-2/3 flex flex-col items-end">
                                    <Alert className="mb-2"
                                        color="primary"
                                        title="Bitte nimm immer ein Foto des gesamten Artikels auf, danach die Mängel im Detail."
                                        variant="flat"
                                    />
                                    <div className="relative w-full aspect-[16/9] bg-black flex items-center justify-center rounded-lg overflow-hidden">
                                        <Camera
                                            ref={cameraRef}
                                            facingMode="environment"
                                            aspectRatio={16 / 9}
                                            errorMessages={{
                                                noCameraAccessible: "Keine Kamera gefunden",
                                                permissionDenied: "Zugriff verweigert",
                                            }}
                                        />
                                        {countdown !== null && (
                                            <div className="absolute inset-0 flex items-center justify-center text-white text-6xl font-bold bg-black/50">
                                                {countdown}
                                            </div>
                                        )}
                                        {flash && (
                                            <div className="absolute inset-0 bg-white opacity-75 transition-opacity duration-150"></div>
                                        )}
                                    </div>
                                </div>
                                <div className="w-1/3 rounded-lg overflow-y-auto"
                                    style={{ maxHeight: cameraHeight ? `${cameraHeight}px` : "auto" }}>
                                    <h3 className="text-sm font-semibold mb-2">Aufgenommene Fotos</h3>
                                    <div className="flex flex-col gap-2 items-center justify-center h-full">
                                        {capturedPhotos.length > 0 ? (
                                            [...capturedPhotos].reverse().map((photo) => (
                                                <div key={photo.id} className="relative w-full">
                                                    <img
                                                        src={photo.edited || photo.original}
                                                        alt={`Captured ${photo.id}`}
                                                        className="w-full rounded-md"
                                                    />
                                                    {photo.isEdited && (
                                                        <div className="absolute top-2 right-2 bg-yellow-500 text-white text-xs px-2 py-1 rounded">
                                                            Bearbeitet
                                                        </div>
                                                    )}
                                                </div>
                                            ))
                                        ) : (
                                            <div className="flex flex-col items-center justify-center text-center">
                                                <img src={zoxsyCamera} alt="Placeholder" className="max-w-[150px] max-h-[150px] mb-2 mt-4" />
                                                <p className="text-gray-500 text-xs mt-2">Noch keine Fotos</p>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </ModalBody>
                        <ModalFooter className="justify-between">
                            <ButtonGroup variant="flat">
                                <Button
                                    startContent={<Icon className="text-default-500" icon="solar:camera-outline" width={20} />}
                                    onPress={() => {
                                        if (selectedOptionValue === "direct") {
                                            handleCapture();
                                        } else if (selectedOptionValue === "three") {
                                            handleCapture(3);
                                        } else if (selectedOptionValue === "five") {
                                            handleCapture(5);
                                        }
                                    }}
                                >
                                    {labelsMap[selectedOptionValue]}
                                </Button>
                                <Dropdown placement="bottom-end" showArrow backdrop="blur">
                                    <DropdownTrigger>
                                        <Button isIconOnly>
                                            <Icon className="text-default-500" icon="solar:alt-arrow-down-outline" width={20} />
                                        </Button>
                                    </DropdownTrigger>
                                    <DropdownMenu
                                        disallowEmptySelection
                                        aria-label="fotomodi"
                                        className="max-w-[300px]"
                                        selectedKeys={selectedOption}
                                        selectionMode="single"
                                        onSelectionChange={(keys) => setSelectedOption(keys as Selection)}
                                    >
                                        <DropdownItem key="direct">{labelsMap["direct"]}</DropdownItem>
                                        <DropdownItem key="three">{labelsMap["three"]}</DropdownItem>
                                        <DropdownItem key="five">{labelsMap["five"]}</DropdownItem>
                                    </DropdownMenu>
                                </Dropdown>
                            </ButtonGroup>
                            <Button color="primary" onPress={onNavigateToGallery}>Weiter zur Galerie</Button>
                        </ModalFooter>
                    </>
                )}
            </ModalContent>
        </Modal>
    );
}
