'use client';

import { useEditor, EditorContent } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import TextAlign from '@tiptap/extension-text-align';
import EditorMenuBar from './EditorMenuBar';

interface EditorProps {
    value: string;
    onChange: (value: string) => void;
    order: number;
    formData?: Record<string, any>;
}

export default function Editor({ value, onChange, order, formData }: EditorProps) {
    const editor = useEditor({
        extensions: [
            StarterKit,
            TextAlign.configure({
                types: ['heading', 'paragraph'],
            }),
        ],
        content: value,
        editorProps: {
            attributes: {
                class: 'prose prose-sm max-w-none p-4 focus:outline-none min-h-[200px]',
            },
        },
        onUpdate: ({ editor }) => {
            onChange(editor.getHTML());
        },
    });

    return (
        <div className="bg-white">
            <EditorMenuBar editor={editor} formData={formData} />
            <EditorContent editor={editor} />
        </div>
    );
} 