import ZoxsPromotionModel from '@/models/zoxsapp/zoxsPromotionModel';
import { getCookie } from '../helpers/cookieHelper';
import ZoxsFeedModel from '@/models/zoxsapp/zoxsFeedModel';
import ZoxsPushModel from '@/models/zoxsapp/zoxsPushModel';

class ZoxsManagementService {
  constructor() {
    this.baseUrl = process.env.REACT_APP_BASE_URL;
    this.bearerToken = process.env.REACT_APP_BEARER_TOKEN;
  }

  async getPromotions(type) {
    try {
      const authToken = getCookie('inteamuser');

      const response = await fetch(`${this.baseUrl}zx/promotion/${type}?showInactive=true`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${this.bearerToken}`,
          'auth-token': authToken,
        },
      });

      if (!response.ok) {
        throw new Error(`Error fetching promotions: ${response.statusText}`);
      }

      const data = await response.json();

      if (!data || !Array.isArray(data)) {
        throw new Error('Invalid response format: Expected an array of promotions');
      }

      const promotions = data.map((prom) => ZoxsPromotionModel.fromJson(prom));

      return { promotions: promotions };
    } catch (error) {
      console.error('Error fetching promotions:', error.message);
      throw error;
    }
  }

  async getPushs() {
    try {
      const authToken = getCookie('inteamuser');

      const response = await fetch(`${this.baseUrl}notification/campaigns`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${this.bearerToken}`,
          'auth-token': authToken,
        },
      });

      if (!response.ok) {
        throw new Error(`Error fetching pushs: ${response.statusText}`);
      }

      const data = await response.json();

      if (!data || !Array.isArray(data)) {
        throw new Error('Invalid response format: Expected an array of pushs');
      }

      const pushs = data.map((push) => ZoxsPushModel.fromJson(push));

      return { pushs: pushs };
    } catch (error) {
      console.error('Error fetching pushs:', error.message);
      throw error;
    }
  }

  async createPromotion(payload) {
    try {
      const authToken = getCookie('inteamuser');

      const response = await fetch(`${this.baseUrl}zx/promotion/content`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${this.bearerToken}`,
          'auth-token': authToken,
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        throw new Error(`Error creating promotion element: ${response.statusText}`);
      }

      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Error creating promotion element:', error.message);
      throw error;
    }
  }

  async updatePromotion(id, payload) {
    try {
      const authToken = getCookie('inteamuser');

      const response = await fetch(`${this.baseUrl}zx/promotion/content/${id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${this.bearerToken}`,
          'auth-token': authToken,
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        throw new Error(`Error updating promotion element: ${response.statusText}`);
      }

      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Error updating promotion element:', error.message);
      throw error;
    }
  }

  async getPromotionById(id) {
    try {
      const authToken = getCookie('inteamuser');

      const response = await fetch(`${this.baseUrl}zx/promotion/content/${id}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${this.bearerToken}`,
          'auth-token': authToken,
        },
      });

      if (!response.ok) {
        throw new Error(`Error fetching promotion element: ${response.statusText}`);
      }

      const data = await response.json();
      const element = ZoxsPromotionModel.fromJson(data);
      return { element: element };
    } catch (error) {
      console.error('Error fetching promotion element:', error.message);
      throw error;
    }
  }

  async updatePromotionOrder(payload) {
    const body = JSON.stringify(payload);
    try {
      const authToken = getCookie('inteamuser');

      const response = await fetch(`${this.baseUrl}zx/promotion/content`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${this.bearerToken}`,
          'auth-token': authToken,
        },
        body: body,
      });

      if (!response.ok) {
        throw new Error(`Error updating promotion order: ${response.statusText}`);
      }

      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Error updating promotion order:', error.message);
      throw error;
    }
  }

  async getFeed(type) {
    try {
      const authToken = getCookie('inteamuser');

      const response = await fetch(`${this.baseUrl}zx/feed/${type}?showInactive=true`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${this.bearerToken}`,
          'auth-token': authToken,
        },
      });

      if (!response.ok) {
        throw new Error(`Error fetching feed: ${response.statusText}`);
      }

      const data = await response.json();

      if (!data || !Array.isArray(data)) {
        throw new Error('Invalid response format: Expected an array of feed');
      }

      const feed = data.map((f) => ZoxsFeedModel.fromJson(f));

      return { feed: feed };
    } catch (error) {
      console.error('Error fetching feed:', error.message);
      throw error;
    }
  }

  async getPushCampaigns() {
    try {
      const authToken = getCookie('inteamuser');

      const response = await fetch(`${this.baseUrl}notification/campaigns`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${this.bearerToken}`,
          'auth-token': authToken,
        },
      });

      if (!response.ok) {
        throw new Error(`Error fetching feed: ${response.statusText}`);
      }

      const data = await response.json();

      if (!data || !Array.isArray(data)) {
        throw new Error('Invalid response format: Expected an array of feed');
      }

      const feed = data.map((f) => ZoxsFeedModel.fromJson(f));

      return { feed: feed };
    } catch (error) {
      console.error('Error fetching feed:', error.message);
      throw error;
    }
  }

  async createFeedElement(formData) {
    try {
      const authToken = getCookie('inteamuser');

      const response = await fetch(`${this.baseUrl}zx/feed/content`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${this.bearerToken}`,
          'auth-token': authToken,
        },
        body: formData,
      });

      if (!response.ok) {
        throw new Error(`Error creating feed element: ${response.statusText}`);
      }

      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Error creating feed element:', error.message);
      throw error;
    }
  }

  async updateFeedElement(payload) {
    const body = JSON.stringify(payload);
    try {
      const authToken = getCookie('inteamuser');

      const response = await fetch(`${this.baseUrl}zx/feed/content/${payload.id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${this.bearerToken}`,
          'auth-token': authToken,
        },
        body: body,
      });

      if (!response.ok) {
        throw new Error(`Error updating feed element: ${response.statusText}`);
      }

      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Error updating feed element:', error.message);
      throw error;
    }
  }

  async getFeedElementById(id) {
    try {
      const authToken = getCookie('inteamuser');

      const response = await fetch(`${this.baseUrl}zx/feed/content/${id}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${this.bearerToken}`,
          'auth-token': authToken,
        },
      });

      if (!response.ok) {
        throw new Error(`Error fetching feed element: ${response.statusText}`);
      }

      const data = await response.json();
      const element = ZoxsFeedModel.fromJson(data);
      return { element: element };
    } catch (error) {
      console.error('Error fetching feed element:', error.message);
      throw error;
    }
  }

  async updateFeedOrder(payload) {
    const body = JSON.stringify(payload);
    try {
      const authToken = getCookie('inteamuser');

      const response = await fetch(`${this.baseUrl}zx/feed/content`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${this.bearerToken}`,
          'auth-token': authToken,
        },
        body: body,
      });

      if (!response.ok) {
        throw new Error(`Error updating feed order: ${response.statusText}`);
      }

      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Error updating feed order:', error.message);
      throw error;
    }
  }

  async createPush(formData) {
    try {
      const authToken = getCookie('inteamuser');

      const response = await fetch(`${this.baseUrl}notification/za`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${this.bearerToken}`,
          'auth-token': authToken,
        },
        body: formData,
      });

      if (!response.ok) {
        throw new Error(`Error creating push element: ${response.statusText}`);
      }

      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Error creating push element:', error.message);
      throw error;
    }
  }

  async updatePush(payload) {
    const body = JSON.stringify(payload);
    try {
      const authToken = getCookie('inteamuser');

      const response = await fetch(`${this.baseUrl}notification/za/${payload.id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${this.bearerToken}`,
          'auth-token': authToken,
        },
        body: body,
      });

      if (!response.ok) {
        throw new Error(`Error updating push element: ${response.statusText}`);
      }

      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Error updating push element:', error.message);
      throw error;
    }
  }
}

const zoxsManagementService = new ZoxsManagementService();
export default zoxsManagementService;
