import { Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, Button, Checkbox } from "@heroui/react";
import { useState } from "react";

interface ImageSelectionModalProps {
    isOpen: boolean;
    onClose: () => void;
    images: { url: string; width: number; height: number }[];
    onImagesSelect: (selectedUrls: string[]) => void;
}

export default function ImageSelectionModal({ isOpen, onClose, images, onImagesSelect }: ImageSelectionModalProps) {
    const [selectedImages, setSelectedImages] = useState<string[]>([]);

    const toggleImage = (url: string) => {
        setSelectedImages(prev => 
            prev.includes(url) 
                ? prev.filter(i => i !== url) 
                : [...prev, url]
        );
    };

    const handleConfirm = () => {
        onImagesSelect(selectedImages);
        onClose();
    };

    return (
        <Modal isOpen={isOpen} onClose={onClose} backdrop="blur" size="5xl">
            <ModalContent>
                <ModalHeader>Vorhandene Bilder übernehmen</ModalHeader>
                <ModalBody>
                    <div className="grid grid-cols-2 md:grid-cols-3 gap-4">
                        {images.map((image, index) => {
                            const isSelected = selectedImages.includes(image.url);
                            return (
                                <div 
                                    key={index} 
                                    className={`
                                        relative group cursor-pointer 
                                        transition-all duration-200 
                                    `}
                                    onClick={() => toggleImage(image.url)}
                                >
                                    <div className="relative">
                                        <img 
                                            src={image.url} 
                                            alt={`Bild ${index + 1}`}
                                            className={`
                                                w-full rounded-lg 
                                                transition-opacity duration-200
                                                ${isSelected ? 'opacity-90' : 'hover:opacity-80'}
                                            `}
                                        />
                                        <Checkbox
                                            className="absolute top-2 right-2 pointer-events-none"
                                            isSelected={isSelected}
                                        />
                                        {isSelected && (
                                            <div className="absolute inset-0 bg-primary-500/10 rounded-lg" />
                                        )}
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onPress={handleConfirm}>
                        {selectedImages.length} Bilder übernehmen
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
} 