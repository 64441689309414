import { Input, Select, SelectItem, RadioGroup, Radio, Popover, PopoverTrigger, PopoverContent } from "@heroui/react";
import { ArticleCheckQuestion } from "../models/articleQuestion";
import { Icon } from "@iconify/react/dist/iconify.js";

interface ArticleQuestionComponentProps {
    question: ArticleCheckQuestion;
    onChange: (questionId: number, newValue: boolean | number | string) => void;
}

const ArticleQuestionComponent: React.FC<ArticleQuestionComponentProps> = ({ question, onChange }) => {
    return (
        <div className="flex justify-between items-center gap-4 min-h-[30px]">
            <div className="flex items-center gap-2">
                <span className="text-small text-foreground-500 font-normal">{question.text}</span>
                {question.info && (
                    <Popover placement="top" showArrow={true} backdrop="blur">
                        <PopoverTrigger>
                            <Icon
                                className="text-default-500"
                                icon="solar:info-circle-outline"
                                width={16}
                            />
                        </PopoverTrigger>
                        <PopoverContent>
                            <div className="px-1 py-2 max-w-96">
                                <div className="text-small font-bold mb-1">Hilfe</div>
                                <div className="text-small" dangerouslySetInnerHTML={{ __html: question.info }} />
                            </div>
                        </PopoverContent>
                    </Popover>
                )}
            </div>

            <div className="flex justify-end w-96">
                {question.type === "text" && (
                    <Input
                        aria-label={question.text}  // Added aria-label here
                        type={question.textProperties?.type === "number" ? "number" : "text"}
                        placeholder="Antwort eingeben..."
                        defaultValue={question.textProperties?.initialValue || ""}
                        onChange={(e) => onChange(question.id, e.target.value)}
                    />
                )}

                {question.type === "select" && question.dropdownProperties?.options && (
                    <Select
                        aria-label="Bitte auswählen" // Added aria-label here
                        disallowEmptySelection
                        placeholder="Bitte auswählen"
                        defaultSelectedKeys={question.dropdownProperties.initialValue ? [question.dropdownProperties.initialValue] : []}
                        onChange={(value) => onChange(question.id, parseInt(value.target.value, 10))}
                    >
                        {question.dropdownProperties.options.map((option) => (
                            <SelectItem key={option.answerId} value={option.answerId}>
                                {option.answerText}
                            </SelectItem>
                        ))}
                    </Select>
                )}

                {question.type === "boolean" && (
                    <RadioGroup
                        aria-label="Ja oder Nein"  // Added aria-label here
                        orientation="horizontal"
                        className="gap-4"
                        onChange={(value) => onChange(question.id, value.target.value === "yes")}
                    >
                        <Radio value="yes">Ja</Radio>
                        <Radio value="no">Nein</Radio>
                    </RadioGroup>
                )}
            </div>
        </div>
    );
};

export default ArticleQuestionComponent;
