import { ArticleCheckCondition } from "./articleCondition";
import { ArticleCheckDeliveryScope } from "./articleDeliveryScope";
import { ArticleCheckQuestion } from "./articleQuestion";

export class ArticleCheckModel {
    articleId: number;
    title: string;
    imageUrl: string;
    conditions: ArticleCheckCondition[];
    maxPrice?: number;
    hasConditionCalculator?: boolean;
    isDevice?: boolean;
    hasZoxsCheck?: boolean;
    eans?: (string | null)[];
    modelNumbers?: (string | null)[];
    categoryPath?: string;
    articleRef?: string;
    publicQuestions?: ArticleCheckQuestion[];
    checkQuestions?: ArticleCheckQuestion[];
    rookieQuestions?: ArticleCheckQuestion[];
    conditionQuestions?: ArticleCheckQuestion[];
    deliveryScope?: ArticleCheckDeliveryScope[];
    checkInformation?: string;
    additionalProductInformation?: string;
    hasSerialNumber?: boolean;
    hasAdditionalSerialNumber?: boolean;
    canComplete?: boolean;
  
    constructor(init: Partial<ArticleCheckModel>) {
      Object.assign(this, init);
    }
  
    static fromJson(json: any): ArticleCheckModel {
      return new ArticleCheckModel({
        articleId: json.articleId,
        title: json.title,
        imageUrl: json.imageUrl,
        conditions: json.conditions?.map(ArticleCheckCondition.fromJson),
        maxPrice: json.maxPrice,
        hasConditionCalculator: json.hasConditionCalculator,
        isDevice: json.isDevice,
        hasZoxsCheck: json.hasZoxsCheck,
        eans: json.eans,
        modelNumbers: json.modelNumbers,
        categoryPath: json.categoryPath,
        articleRef: json.articleRef,
        publicQuestions: json.publicQuestions?.map(ArticleCheckQuestion.fromJson),
        checkQuestions: json.checkQuestions?.map(ArticleCheckQuestion.fromJson),
        rookieQuestions: json.rookieQuestions?.map(ArticleCheckQuestion.fromJson),
        conditionQuestions: json.conditionQuestions?.map(ArticleCheckQuestion.fromJson),
        deliveryScope: json.deliveryScope?.map(ArticleCheckDeliveryScope.fromJson),
        checkInformation: json.checkInformation,
        additionalProductInformation: json.additionalProductInformation,
        hasSerialNumber: json.hasSerialNumber,
        hasAdditionalSerialNumber: json.hasAdditionalSerialNumber,
        canComplete: json.canComplete,
      });
    }
  
    static toJson(article: ArticleCheckModel): any {
      return {
        articleId: article.articleId,
        title: article.title,
        imageUrl: article.imageUrl,
        conditions: article.conditions?.map(ArticleCheckCondition.toJson),
        maxPrice: article.maxPrice,
        hasConditionCalculator: article.hasConditionCalculator,
        isDevice: article.isDevice,
        hasZoxsCheck: article.hasZoxsCheck,
        eans: article.eans,
        modelNumbers: article.modelNumbers,
        categoryPath: article.categoryPath,
        articleRef: article.articleRef,
        publicQuestions: article.publicQuestions?.map(ArticleCheckQuestion.toJson),
        checkQuestions: article.checkQuestions?.map(ArticleCheckQuestion.toJson),
        rookieQuestions: article.rookieQuestions?.map(ArticleCheckQuestion.toJson),
        conditionQuestions: article.conditionQuestions?.map(ArticleCheckQuestion.toJson),
        deliveryScope: article.deliveryScope?.map(ArticleCheckDeliveryScope.toJson),
        checkInformation: article.checkInformation,
        additionalProductInformation: article.additionalProductInformation,
        hasSerialNumber: article.hasSerialNumber,
        hasAdditionalSerialNumber: article.hasAdditionalSerialNumber,
        canComplete: article.canComplete,
      };
    }
  }
  