import React, { useEffect, useState } from "react";
import EbayStartOverComponent from "./components/startover";
import SpeeduploadGrid from "./components/SpeeduploadGrid";
import DraftsGrid from "./components/DraftsGrid";
import { SpeedUploadConfig } from "./models/SpeedUploadConfig";
import { Button, Input, Skeleton } from "@heroui/react";
import { Icon } from "@iconify/react/dist/iconify.js";
import ebayService from "./api/ebayService";
import { v4 as uuidv4 } from 'uuid';
import { useNavigate } from "react-router-dom";
import { EbayRequest } from "./models/EbayRequest";

const EbayOverview: React.FC = () => {
    const navigate = useNavigate();
    const [speeduploadConfigs, setSpeeduploadConfigs] = useState<SpeedUploadConfig[]>([]);
    const [drafts, setDrafts] = useState<any[]>([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);
    const [draftsError, setDraftsError] = useState<string | null>(null);
    const [searchTerm, setSearchTerm] = useState("");

    const fetchDrafts = async () => {
        setDraftsError(null);
        try {
            const draftsData = await ebayService.getDrafts();
            setDrafts(draftsData);
        } catch (err: any) {
            console.error("Fehler beim Laden der Entwürfe:", err);
            setDraftsError(err.message);
        }
    };

    const fetchSpeeduploadConfigs = async () => {
        setError(null);
        try {
            const configs = await ebayService.getSpeeduploadConfigs();
            setSpeeduploadConfigs(configs);
        } catch (err: any) {
            console.error("Fehler beim Laden der Konfigurationen:", err);
            setError(err.message);
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                await Promise.all([
                    fetchSpeeduploadConfigs(),
                    fetchDrafts()
                ]);
            } catch (err: any) {
                console.error("Fehler beim Laden:", err);
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    const filteredConfigs = speeduploadConfigs.filter(config =>
        config.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const handleConfigClick = async (config: SpeedUploadConfig) => {
        try {
            const id = uuidv4();
            const ebayRequest = new EbayRequest(
                config.conf_id,
                null,
                null,
                null,
                null,
                id
            );
            const result = await ebayService.getEbayData(ebayRequest);
            navigate(`/admin-ebay/offer?id=${id}`, {
                state: { ebayData: result, ebayRequest: ebayRequest }
            });
        } catch (err: any) {
            console.error("Fehler beim Laden der Konfiguration:", err);
            setError(err.message);
        }
    };

    const handleDraftClick = (draft: any) => {
        if (!draft) {
            return;
        }
        navigate(`/admin-ebay/offer?id=${draft.id}`);
    };

    const renderSkeletons = () => (
        <div className="grid grid-cols-[repeat(auto-fit,minmax(350px,1fr))] gap-3">
            {[1, 2, 3, 4, 5, 6, 7, 8, 9].map((i) => (
                <div key={i} className="flex justify-center">
                    <div className="w-full h-[250px] rounded-lg border bg-background shadow">
                        <Skeleton className="w-full h-full rounded-lg">
                            <div className="h-full"></div>
                        </Skeleton>
                    </div>
                </div>
            ))}
        </div>
    );

    return (
        <div className="w-full h-full flex-1 p-4" style={{ overflowY: "auto" }}>
            <div className="mb-8">
                <EbayStartOverComponent />
            </div>

            <div className="mb-8">
                <div className="flex items-center gap-x-3">
                    <h1 className="text-3xl font-bold leading-9 text-default-foreground">Meine Entwürfe</h1>
                </div>
                <h2 className="mt-2 text-small text-default-500 pb-6">
                    Speichere Auktionen zwischen und starte sie später wieder.
                </h2>
                {loading ? renderSkeletons() : (
                    draftsError ? (
                        <div className="flex flex-col items-center justify-center p-8 text-center">
                            <Icon icon="solar:shield-warning-outline" className="text-default-500 mb-4" width={48} />
                            <h3 className="text-xl font-semibold mb-2">Fehler beim Laden der Entwürfe</h3>
                            <p className="text-default-500 mb-4">{draftsError}</p>
                            <Button
                                color="primary"
                                onPress={fetchDrafts}
                            >
                                Wiederholen
                            </Button>
                        </div>
                    ) : (
                        <DraftsGrid items={drafts} onItemClick={handleDraftClick} />
                    )
                )}
            </div>

            <div className="flex items-center gap-x-3">
                <h1 className="text-3xl font-bold leading-9 text-default-foreground">Speedupload-Konfigurationen</h1>
            </div>
            <h2 className="mt-2 text-small text-default-500 pb-6">
                Klicke eine Kachel an und starte den Upload einer Konfiguration.
            </h2>
            <div className="w-full max-w-md mb-6">
                <Input
                    placeholder="Nach Konfiguration suchen..."
                    value={searchTerm}
                    variant="bordered"
                    onChange={(e) => setSearchTerm(e.target.value)}
                    startContent={<Icon
                        icon="solar:minimalistic-magnifer-outline"
                        className="text-gray-400 cursor-help"
                        width={20}
                    />}
                    className="w-full"
                />
            </div>

            {loading && renderSkeletons()}

            {error && (
                <div className="flex flex-col items-center justify-center p-8 text-center">
                    <Icon icon="solar:shield-warning-outline" className="text-default-500 mb-4" width={48} />
                    <h3 className="text-xl font-semibold mb-2">Fehler beim Laden der Konfigurationen</h3>
                    <p className="text-default-500 mb-4">{error}</p>
                    <Button
                        color="primary"
                        onPress={fetchSpeeduploadConfigs}
                    >
                        Wiederholen
                    </Button>
                </div>
            )}

            {!loading && !error && (
                <SpeeduploadGrid items={filteredConfigs} onItemClick={handleConfigClick} />
            )}
        </div>
    );
};

export default EbayOverview;