import { SelectOption, SelectOption as SelectOptionUtils } from './SelectOption';

export enum TextFieldType {
  text = 'text',
  number = 'number',
  numberWithDigits = 'numberWithDigits'
}

interface BaseFormField {
  key: string;
  name: string;
  mandatory: boolean;
  info?: string;
}

export interface TextFormField extends BaseFormField {
  type: 'text';
  fieldType: TextFieldType;
  minLength?: number;
  maxLength?: number;
  initialValue?: string;
  value?: string;
}

export interface HtmlFormField extends BaseFormField {
  type: 'html';
  initialValue?: string;
  value?: string;
}

export interface BoolFormField extends BaseFormField {
  type: 'bool';
  initialValue?: boolean;
}

export interface SingleSelectFormField extends BaseFormField {
  type: 'singleSelect';
  options: SelectOption[];
  initialValue?: SelectOption;
  value?: SelectOption;
  allowCustomValue?: boolean;
}

export interface MultiSelectFormField extends BaseFormField {
  type: 'multiSelect';
  options: SelectOption[];
  initialValues?: SelectOption[];
  values?: string[];
}

export type FormField = 
  | TextFormField 
  | HtmlFormField 
  | BoolFormField 
  | SingleSelectFormField 
  | MultiSelectFormField;

export const FormField = {
  fromJson: (json: any): FormField | null => {
    if (!json || typeof json !== 'object') {
      return null;
    }

    const baseField = {
      key: json.key,
      name: json.name,
      type: json.type,
      mandatory: json.mandatory || false,
      fieldType: json.fieldType,
      minLength: json.minLength,
      maxLength: json.maxLength,
      minValue: json.minValue,
      maxValue: json.maxValue,
      step: json.step,
      pattern: json.pattern,
      placeholder: json.placeholder,
      helpText: json.helpText,
      validationMessage: json.validationMessage,
      options: Array.isArray(json.options) ? json.options.map((opt: any) => SelectOptionUtils.fromJson(opt)).filter(Boolean) : []
    };

    switch (json.type) {
      case 'text':
      case 'html':
        return {
          ...baseField,
          initialValue: json.initialValue || ''
        } as TextFormField;
      case 'bool':
        return {
          ...baseField,
          initialValue: json.initialValue || false
        } as BoolFormField;
      case 'singleSelect':
        return {
          ...baseField,
          initialValue: json.initialValue || null
        } as SingleSelectFormField;
      case 'multiSelect':
        return {
          ...baseField,
          initialValues: Array.isArray(json.initialValues) ? json.initialValues.filter(Boolean) : []
        } as MultiSelectFormField;
      default:
        return null;
    }
  },

  toJson: (field: FormField): any => {
    const baseJson = {
      key: field.key,
      name: field.name,
      mandatory: field.mandatory,
      info: field.info,
      type: field.type,
    };

    switch (field.type) {
      case 'text':
        return {
          ...baseJson,
          fieldType: field.fieldType,
          minLength: field.minLength,
          maxLength: field.maxLength,
          initialValue: field.initialValue,
          value: field.value,
        };
      case 'html':
        return {
          ...baseJson,
          initialValue: field.initialValue,
          value: field.value,
        };
      case 'bool':
        return {
          ...baseJson,
          initialValue: field.initialValue,
        };
      case 'singleSelect':
        return {
          ...baseJson,
          options: field.options.map(SelectOptionUtils.toJson),
          initialValue: field.initialValue ? SelectOptionUtils.toJson(field.initialValue) : undefined,
          value: field.value ? SelectOptionUtils.toJson(field.value) : undefined,
          allowCustomValue: field.allowCustomValue,
        };
      case 'multiSelect':
        return {
          ...baseJson,
          options: field.options.map(SelectOptionUtils.toJson),
          initialValues: field.initialValues?.map(SelectOptionUtils.toJson),
          values: field.values,
        };
    }
  },
}; 