export class ArticleCheckCondition {
    id: number;
    name: string;
    description?: string;
    userSelect?: string;
  
    constructor(init: Partial<ArticleCheckCondition>) {
      Object.assign(this, init);
    }
  
    static fromJson(json: any): ArticleCheckCondition {
      return new ArticleCheckCondition({
        id: json.id,
        name: json.name,
        description: json.description,
        userSelect: json.userSelect,
      });
    }
  
    static toJson(condition: ArticleCheckCondition): any {
      return {
        id: condition.id,
        name: condition.name,
        description: condition.description,
        userSelect: condition.userSelect,
      };
    }
  }