import OpenAI from "openai";
import { OpenAiTextGenerator, OpenAiResponse } from "../models/openAiTextGenerator";
import { ChatCompletionMessageParam } from "openai/resources/index.mjs";

export class OpenAiService {
    private openai: OpenAI;

    constructor() {
        this.openai = new OpenAI({
            apiKey: process.env.REACT_APP_OPENAI_API_KEY,
            dangerouslyAllowBrowser: true
        });
    }

    public async generateArticleText(data: OpenAiTextGenerator): Promise<OpenAiResponse> {
        try {
            const prompt = `
                Artikel: ${data.articleTitle}
                Ursprünglicher Zustand: ${data.articleConditionOrigin}
                Neuer Zustand: ${data.articleConditionNew}
                Bisheriger Text: ${data.legacyText}
                
                Bitte generiere einen professionellen und freundlichen Text, der die Änderung des Zustands erklärt.
                Der Text sollte dem Kunden helfen zu verstehen, warum eine Neubewertung notwendig war.
            `;

            const completion = await this.openai.chat.completions.create({
                model: "gpt-3.5-turbo",
                messages: [
                    {
                        role: "system",
                        content: `Du bist ein erfahrener und professioneller Grader und erklärst dem Kunden freundlich, aber bestimmt, warum es zu einer Neubewertung seines Artikels gekommen ist. Dabei gelten folgende Regeln:
            
1. **Keine Begrüßung** – beginne direkt mit dem Inhalt (z. B. keine Anrede wie "Hallo" oder "Lieber Kunde").
2. **Immer Duzen** – verwende ausschließlich "Du", "Dich", "Dir" usw. und schreibe diese Wörter **immer groß**.
3. **Ich-Perspektive** – schreibe aus der Sicht eines menschlichen Graders ("Ich habe festgestellt...", "Aus meiner Sicht...").
4. **HTML-Format als Ausgabe** – keine Markdown-Syntax! Verwende <b>, <i>, <ul>, <li>, <p>, <br>, <a>, etc.
5. **Strukturierter Aufbau** – erst eine **Stichpunktliste**, die die Gründe für die Neubewertung kurz aufführt.
6. Danach folgt ein **ausführlicher Erklärungstext** in Absätzen.
7. **Handlungsaufforderungen** wie "Bitte sende uns..." oder "Falls Du Fragen hast..." sollen immer **fett** sein oder in einem eigenen Abschnitt klar erkennbar sein.
8. Du darfst gerne ein freundliches Emoji wie 😊 einbauen, aber sparsam.
9. **Keine Abschlussfloskeln** – keine Sätze wie "Falls Du Fragen hast, stehe ich gerne zur Verfügung" oder ähnliche.
10. **Vermeide Redundanzen** – keine Wiederholungen von Informationen oder überflüssige Ausschmückungen.
11. **Formatierung** – verwende <b> für wichtige Informationen und <a target="_blank" rel="noopener noreferrer"> für Links.

Deine Antwort muss in **deutscher Sprache** erfolgen und **immer** im beschriebenen HTML-Format sein.`
                    },
                    {
                        role: "user",
                        content: prompt
                    }
                ],
                temperature: 0.7,
                max_tokens: 500
            });

            console.log('OpenAI Response:', {
                prompt,
                response: completion.choices[0]?.message?.content,
                fullResponse: completion
            });

            return completion as unknown as OpenAiResponse;
        } catch (error) {
            console.error('Fehler bei der OpenAI-Anfrage:', error);
            throw error;
        }
    }

    public async getArticleHexColor(productName: string): Promise<{ hex: string; name: string }> {
        try {
            const messages = [
                {
                    role: "system",
                    content: `Du bist ein Experte für Farben und Farbzuweisungen. Deine Aufgabe ist es, aus Produktnamen die enthaltene Farbbezeichnung zu erkennen und den dazugehörigen ungefähren HEX-Farbcode zurückzugeben.
    
    Regeln:
    1. Gib die Antwort im Format "FARBNAME|HEXCODE" zurück, z. B. "Schwarz|#1C1C1E"
    2. Der HEX-Code sollte der typischen Farbe der Bezeichnung im Produktnamen entsprechen
    3. Falls mehrere Farben enthalten sind, wähle die **dominante** oder am deutlichsten erkennbare
    4. Der Farbname soll in deutscher Sprache sein
    5. Falls keine Farbe erkannt werden kann, gib "Unbekannt|#000000" zurück`
                },
                {
                    role: "user",
                    content: `Produktname: ${productName}`
                }
            ];
    
            const completion = await this.openai.chat.completions.create({
                model: "gpt-3.5-turbo",
                messages: messages as ChatCompletionMessageParam[],
                temperature: 0,
                max_tokens: 20
            });
    
            const response = completion.choices[0]?.message?.content?.trim() || "Unbekannt|#000000";
            const [name, hex] = response.split("|");
    
            console.log('Farbcode aus Artikel:', {
                productName,
                name,
                hex
            });
    
            return { name, hex };
        } catch (error) {
            console.error("Fehler bei Farberkennung via OpenAI:", error);
            return { name: "Unbekannt", hex: "#000000" };
        }
    }
    
}