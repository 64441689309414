import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Card, Spinner, useDisclosure, Button, addToast, Input, Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, Alert, Select, SelectItem, CardHeader, CardBody, Tooltip } from "@heroui/react";
import { Icon } from "@iconify/react/dist/iconify.js";

// SERVICES
import userService from "@/services/userService";
import articleService from "./api/articleService";
import { OpenAiService } from "./api/openAiService";

// MODELS
import { ArticleCheckModel } from "./models/article";
import { AppOrderState } from "./models/appOrderState";
import { AppArticleState } from "./models/appArticleState";
import { ArticleQueryType } from "./models/queryTypes";
import { PriceRequest, PriceRequestArticleQuestion } from "./models/priceRequest";
import { ArticleCheckCondition } from "./models/articleCondition";
import { Photo } from "./models/photo";
import { ControllerTestResult } from "./models/controller";
import { ConditionCalculatorLightRequestModel, ConditionCalculatorLightResponseModel } from "./models/conditionCalculatorLight";
import { MailText } from "./models/mailText";

// COMPONENTS
import ArticleStartOverComponent from "./components/startover";
import ArticleInfoCard from "./components/article_info";
import ArticleSidebar from "./components/article_sidebar";
import ArticleDetailsCard from "./components/article_details_card";
import ArticleEditor from './components/ArticleEditor';

// DRAWERS & MODALS
import ConditionCalculatorDrawer from "./components/drawer_condition_calc";
import ModelChangeDrawer from "./components/drawer_model_change";
import ImporvementDrawer from "./components/drawer_improvement";
import PriceChangeDrawer from "./components/drawer_price_change";
import LabelDrawer from "./components/drawer_label";
import CameraModal from "./components/modal_camera";
import GalleryModal from "./components/modal_gallery";
import PhotoEdiorModal from "./components/modal_editor";
import ControllerTestModal from "./components/modal_controller_test";
import TextListDrawer from "./components/drawer_text_list";

// WHEN DEMO MODE: TYPES CAN BE EAN, ASIN, ARTICLE-ID
// WHEN ORDER PROCESSING: TYPE IS DETAIL-ID
interface ArticleDetailProps {
    id: ArticleQueryType;
    type: string;
}

const ArticleDetail: React.FC<ArticleDetailProps> = ({ id, type }) => {
    // DEMO MODE
    const isDemoMode = type !== ArticleQueryType.detailId;

    // BASE
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [article, setArticle] = useState<ArticleCheckModel | null>(null);
    const [error, setError] = useState<string | null>(null);
    const [editorContent, setEditorContent] = useState<string>('');

    // MODEL CHANGE
    const [oldArticle, setOldArticle] = useState<ArticleCheckModel | null>(null);
    const [isLoadingNewArticle, setIsLoadingNewArticle] = useState(false);
    const [showModelChangeAlert, setShowModelChangeAlert] = useState(false);

    // IMPROVEMENT REQUEST
    const [improvementId, setImprovementId] = useState<number | null>(null);

    // CONDITION TRACKING
    const [currentCondition, setCurrentCondition] = useState<ArticleCheckCondition | null>(null);
    const [calculatorCondition, setCalculatorCondition] = useState<ArticleCheckCondition | null>(null);
    const [conditionChangeText, setConditionChangeText] = useState<string>('');
    const [isProcessingCondition, setIsProcessingCondition] = useState(false);
    const [lastProcessedAnswers, setLastProcessedAnswers] = useState<Record<string, number>>({});
    const [opticsTextIds, setOpticsTextIds] = useState<number[]>([]);
    const [priceResponseTextIds, setPriceResponseTextIds] = useState<number[]>([]);

    // POPUPS AND DRAWERS
    const { isOpen: isConditionCalculatorOpen, onOpen: onConditionCalculatorOpen, onOpenChange: onConditionCalculatorOpenChange } = useDisclosure();
    const { isOpen: isTextListOpen, onOpen: onTextListOpen, onOpenChange: onTextListOpenChange } = useDisclosure();
    const { isOpen: isModelChangeOpen, onOpen: onModelChangeOpen, onOpenChange: onModelChangeOpenChange } = useDisclosure();
    const { isOpen: isPriceEditOpen, onOpen: onPriceEditOpen, onOpenChange: onPriceEditOpenChange } = useDisclosure();
    const { isOpen: isImprovementOpen, onOpen: onImprovementOpen, onOpenChange: onImprovementOpenChange } = useDisclosure();
    const [isLabelOpen, setIsLabelOpen] = useState(false);
    const [labelOpenChange, setLabelOpenChange] = useState(false);

    // CAMERA AND PHOTO EDITOR
    const { isOpen: isCameraOpen, onOpen: onCameraOpen, onOpenChange: onCameraOpenChange } = useDisclosure();
    const { isOpen: isGalleryOpen, onOpen: onGalleryOpen, onOpenChange: onGalleryOpenChange } = useDisclosure();
    const { isOpen: isPhotoEditorOpen, onOpen: onPhotoEditorOpen, onOpenChange: onPhotoEditorOpenChange } = useDisclosure();

    // STATE MANAGEMENT - IN DEMO MODE INACTIVE
    const [orderState, setOrderState] = useState<AppOrderState | null>(null);
    const [articleState, setArticleState] = useState<AppArticleState | null>(null);
    const [isLoadingStates, setIsLoadingStates] = useState(false);
    const [availableStates, setAvailableStates] = useState<AppArticleState[] | null>(null);
    const [statesError, setStatesError] = useState<string | null>(null);

    // MODULE PERMISSIONS
    const canViewComparison = userService.user.modules.includes(1);
    const canViewPrice = userService.user.modules.includes(1);
    const canEditPrice = userService.user.modules.includes(1);
    const canSkipRookieQuestions = userService.user.modules.includes(1);
    const canSeeOrderDetails = userService.user.modules.includes(1);
    const canSeeCustomerData = userService.user.modules.includes(1);
    const canAccessStates = userService.user.modules.includes(1);

    // PRICE REQUEST AND QUESTION ANSWERS
    const [priceRequest, setPriceRequest] = useState<PriceRequest | null>(null);
    const [price, setPrice] = useState<{ amount: number; text?: MailText | null; textIdsToRemove?: number[] } | null>(null);
    const [isLoadingPrice, setIsLoadingPrice] = useState(false);
    const [answeredQuestions, setAnsweredQuestions] = useState<Set<number>>(new Set());
    const [photos, setPhotos] = useState<Photo[]>([]);
    const [serialNumber, setSerialNumber] = useState<string>("");
    const [selectedDeliveryScope, setSelectedDeliveryScope] = useState<number[]>([]);
    const [noDeliveryScope, setNoDeliveryScope] = useState<boolean>(false);

    // Initialisiere priceRequest wenn der Artikel geladen ist
    useEffect(() => {
        if (article?.articleId) {
            setPriceRequest(new PriceRequest({
                articleId: article.articleId,
                orderId: 0,
                selectedCondition: 0,
                articleQuestions: [],
                amount: 1,
                isExpert: false,
                buyback: false,
                skipSellRules: false,
                assumeCorrectAnswers: false,
                requestZoxsCheck: false,
            }));
        }
    }, [article?.articleId]);

    // Call getArticlePrice when priceRequest changes
    useEffect(() => {
        let isMounted = true;
        const fetchPrice = async () => {
            // Nur wenn der Artikel geladen ist und eine ID hat
            if (!article?.articleId || isLoading || !priceRequest) return;
            
            setIsLoadingPrice(true);
            try {
                const response = await articleService.getArticlePrice(priceRequest);
                if (!isMounted) return;
                
                console.log('Price Response:', response);
                setPrice({
                    amount: response.price,
                    text: response.text,
                    textIdsToRemove: response.textIdsToRemove
                });

                // Wenn ein neuer Text vorhanden ist, füge ihn zum Editor hinzu
                const text = response.text;
                if (text && 'sellText' in text && text.sellText) {
                    setEditorContent(prev => {
                        const newContent = prev ? `${prev}<br><br>${text.sellText}` : text.sellText;
                        return newContent.trim();
                    });
                }
            } catch (error) {
                console.error('Fehler beim Abrufen des Preises:', error);
            } finally {
                if (isMounted) {
                    setIsLoadingPrice(false);
                }
            }
        };

        fetchPrice();

        return () => {
            isMounted = false;
        };
    }, [priceRequest]); // Nur priceRequest als Dependency

    // Überprüfe, ob alle conditionQuestions beantwortet sind
    useEffect(() => {
        if (article?.conditionQuestions && article.conditionQuestions.length > 0) {
            const allConditionQuestionsAnswered = article.conditionQuestions.every(
                question => answeredQuestions.has(question.id)
            );

            if (allConditionQuestionsAnswered && !isProcessingCondition) {
                // Erstelle ein Objekt mit den aktuellen Antworten
                const currentAnswers = Object.fromEntries(
                    Array.from(answeredQuestions).map(qId => [
                        qId.toString(),
                        priceRequest?.articleQuestions.find(q => q.id === qId)?.userSelect as number
                    ])
                );

                // Prüfe, ob sich die Antworten geändert haben
                const answersChanged = JSON.stringify(currentAnswers) !== JSON.stringify(lastProcessedAnswers);

                if (answersChanged) {
                    processConditionCalculator();
                }
            }
        }
    }, [answeredQuestions, article?.conditionQuestions, isProcessingCondition, lastProcessedAnswers]);

    // STATE PERSISTENCE
    useEffect(() => {
        // SAVE STATE IN LOCAL STORAGE
        const stateToSave = {
            currentCondition,
            calculatorCondition,
            answeredQuestions: Array.from(answeredQuestions),
            photos,
            serialNumber,
            selectedDeliveryScope,
            noDeliveryScope,
            priceRequest,
            editorContent,
            opticsTextIds,
            priceResponseTextIds
        };

        localStorage.setItem(`article_state_${id}`, JSON.stringify(stateToSave));
    }, [
        currentCondition,
        calculatorCondition,
        answeredQuestions,
        photos,
        serialNumber,
        selectedDeliveryScope,
        noDeliveryScope,
        priceRequest,
        editorContent,
        opticsTextIds,
        priceResponseTextIds,
        id
    ]);

    // LOAD STATE FROM LOCAL STORAGE
    useEffect(() => {
        const savedState = localStorage.getItem(`article_state_${id}`);
        if (savedState) {
            try {
                const parsedState = JSON.parse(savedState);
                setCurrentCondition(parsedState.currentCondition);
                setCalculatorCondition(parsedState.calculatorCondition);
                setAnsweredQuestions(new Set(parsedState.answeredQuestions));
                setPhotos(parsedState.photos);
                setSerialNumber(parsedState.serialNumber);
                setSelectedDeliveryScope(parsedState.selectedDeliveryScope);
                setNoDeliveryScope(parsedState.noDeliveryScope);
                setPriceRequest(parsedState.priceRequest);
                setEditorContent(parsedState.editorContent);
                setOpticsTextIds(parsedState.opticsTextIds || []);
                setPriceResponseTextIds(parsedState.priceResponseTextIds || []);
            } catch (error) {
                console.error('Fehler beim Laden des gespeicherten States:', error);
            }
        }
    }, [id]);

    // CLEANUP
    useEffect(() => {
        return () => {
            // OPTIONAL: DELETE STATE WHEN COMPONENT IS UNMOUNTED
            // localStorage.removeItem(`article_state_${id}`);
        };
    }, [id]);

    // CONDITION FROM CALCULATOR
    const handleConditionFromCalculator = (condition: ArticleCheckCondition) => {
        setCalculatorCondition(condition);
        setCurrentCondition(condition);
        setPriceRequest(prev => {
            if (!prev || !article?.articleId) return null;
            return new PriceRequest({
                ...prev,
                selectedCondition: condition.id,
                affectedQuestion: undefined
            });
        });
    };

    // CONDITION FROM DROPDOWN
    const handleConditionChange = (conditionId: number) => {
        const selectedCondition = article?.conditions?.find(c => c.id === conditionId) || null;
        setCurrentCondition(selectedCondition);
        setPriceRequest(prev => {
            if (!prev || !article?.articleId) return null;
            return new PriceRequest({
                ...prev,
                selectedCondition: conditionId,
                affectedQuestion: undefined
            });
        });
    };

    const handleQuestionChange = (questionId: number, newValue: boolean | number | string) => {
        // Finde die Frage in allen Frage-Typen
        const question = [
            ...(article?.conditionQuestions || []),
            ...(article?.publicQuestions || []),
            ...(article?.checkQuestions || [])
        ].find(q => q.id === questionId);

        // Wenn die Frage vom Typ "text" ist, füge sie nicht zum priceRequest hinzu
        if (question?.type === "text") {
            return;
        }

        setPriceRequest(prev => {
            if (!prev || !article?.articleId) return null;

            let updatedQuestions = prev.articleQuestions ? [...prev.articleQuestions] : [];
            const index = updatedQuestions.findIndex(q => q.id === questionId);

            // Konvertiere Boolean zu number (0 oder 1)
            const numericValue = typeof newValue === 'boolean' ? (newValue ? 1 : 0) : newValue;

            if (index !== -1) {
                updatedQuestions[index] = new PriceRequestArticleQuestion({
                    id: questionId,
                    userSelect: numericValue,
                });
            } else {
                updatedQuestions.push(
                    new PriceRequestArticleQuestion({
                        id: questionId,
                        userSelect: numericValue,
                    })
                );
            }

            // Prüfe, ob die Frage aus conditionQuestions kommt
            const isConditionQuestion = article?.conditionQuestions?.some(q => q.id === questionId);
            
            // Erstelle einen neuen PriceRequest mit allen erforderlichen Feldern
            const newPriceRequest = new PriceRequest({
                articleId: article.articleId,
                orderId: prev.orderId,
                selectedCondition: prev.selectedCondition,
                articleQuestions: updatedQuestions,
                amount: prev.amount,
                isExpert: prev.isExpert,
                buyback: prev.buyback,
                skipSellRules: prev.skipSellRules,
                assumeCorrectAnswers: prev.assumeCorrectAnswers,
                requestZoxsCheck: prev.requestZoxsCheck,
                affectedQuestion: isConditionQuestion ? undefined : questionId
            });

            // Wenn es sich um eine Boolean-Frage handelt, setze affectedQuestion
            if (typeof newValue === 'boolean') {
                newPriceRequest.affectedQuestion = questionId;
            }

            return newPriceRequest;
        });
    };

    // SERIAL NUMBER CHANGE
    const handleSerialNumberChange = (value: string) => {
        setSerialNumber(value); //TODO: CHECK SN IN SYSTEM
    };

    // PHOTO UPDATES
    const handlePhotoUpdate = (newPhotos: Photo[]) => {
        setPhotos(newPhotos);
    };

    const handlePhotoDelete = (photoId: string) => {
        setPhotos(prev => prev.map(photo =>
            photo.id === photoId
                ? { ...photo, isDeleted: true }
                : photo
        ));
    };

    const handlePhotoEdit = (photoId: string, editedUrl: string) => {
        console.log('Editing photo:', photoId, 'with URL:', editedUrl);
        setPhotos(prev => prev.map(photo =>
            photo.id === photoId
                ? {
                    ...photo,
                    edited: editedUrl,
                    isEdited: true
                }
                : photo
        ));
    };

    const handlePhotoRestore = (photoId: string) => {
        setPhotos(prev => prev.map(photo =>
            photo.id === photoId
                ? {
                    ...photo,
                    isDeleted: false,
                    edited: undefined,
                    isEdited: false
                }
                : photo
        ));
    };

    // REMOVE DELETED PHOTOS
    const activePhotos = photos.filter(photo => !photo.isDeleted);

    // TRACK QUESTION CHANGES FOR PROGRESS TRACKING
    const handleQuestionChangeWithTracking = (questionId: number, newValue: boolean | number | string) => {
        handleQuestionChange(questionId, newValue);
        setAnsweredQuestions(prev => {
            const newSet = new Set(prev);
            newSet.add(questionId);
            return newSet;
        });
    };

    const processConditionCalculator = async () => {
        console.log("PROCESS CONDITION CALCULATOR")
        if (!article || isProcessingCondition) return;
        
        setIsProcessingCondition(true);
        try {
            const request = new ConditionCalculatorLightRequestModel({
                answers: Object.fromEntries(
                    Array.from(answeredQuestions).map(qId => [
                        qId.toString(),
                        priceRequest?.articleQuestions.find(q => q.id === qId)?.userSelect as number
                    ])
                )
            });

            const response = await articleService.getConditionPercentageCalculator(request);
            
            if (response) {
                console.log("RESPONSE FROM CONDITION CALCULATOR:", response);
                setCurrentCondition(response.condition);
                setCalculatorCondition(response.condition);
                
                // NEW PRICE REQUEST
                setPriceRequest(prev => prev ? new PriceRequest({
                    ...prev,
                    selectedCondition: response.condition.id,
                    affectedQuestion: undefined
                }) : null);

                // Speichere die IDs der Texte
                const textIds = response.texts.map(text => text.id);
                setOpticsTextIds(textIds);

                // MERGE TEXTS mit HTML-Formatierung
                const mergedText = response.texts
                    .map(text => text.sellText)
                    .filter(text => text)
                    .join('<br><br>');
                
                setEditorContent(mergedText);

                // Speichere die verarbeiteten Antworten
                setLastProcessedAnswers(request.answers);
            }
        } catch (error) {
            console.error('Fehler beim Verarbeiten der Condition:', error);
            addToast({
                title: 'Fehler',
                description: 'Beim Verarbeiten der Optikfragen ist ein Fehler aufgetreten.',
                color: 'danger',
                timeout: 5000,
                shouldShowTimeoutProgress: true
            });
        } finally {
            setIsProcessingCondition(false);
        }
    };

    // Aktualisiere die PriceResponse-Texte
    useEffect(() => {
        if (price) {
            // Wenn ein neuer Text vorhanden ist, füge seine ID hinzu
            const text = price.text;
            if (text && 'id' in text) {
                setPriceResponseTextIds(prev => [...prev, text.id]);
            }

            // Entferne die IDs, die entfernt werden sollen
            if (price.textIdsToRemove?.length) {
                setPriceResponseTextIds(prev => 
                    prev.filter(id => !price.textIdsToRemove!.includes(id))
                );
            }
        }
    }, [price]);

    // DELIVERY SCOPE CHANGE
    const handleDeliveryScopeChange = (selectedItems: number[], noScope: boolean = false) => {
        setSelectedDeliveryScope(selectedItems);
        setNoDeliveryScope(noScope);
    };

    // RETRIVE CHECK QUESTIONS
    useEffect(() => {
        let isMounted = true;
        const fetchData = async () => {
            setIsLoading(true);
            setError(null);
            try {
                const fetchedArticle = await articleService.getSellArticleForCheck(type, id);

                if (isMounted && fetchedArticle) {
                    setArticle(fetchedArticle);
                } else {
                    throw new Error("Article not found or empty response.");
                }
            } catch (error: any) {
                if (isMounted) {
                    setError(error.message || "An unknown error occurred.");
                }
            } finally {
                if (isMounted) {
                    setIsLoading(false);
                }
            }
        };

        fetchData();

        return () => {
            isMounted = false;
        };
    }, [id, type]);

    // IF CHECK MODE RETRIEVE FORMER STATES
    useEffect(() => {
        let isMounted = true;
        const fetchAvailableStates = async () => {
            if (isDemoMode) {
                return;
            }
            setIsLoadingStates(true);
            setStatesError(null);
            try {
                const fetchedStates = await articleService.getArticleStates(parseInt(id));

                if (isMounted && fetchedStates) {
                    setAvailableStates(fetchedStates);
                } else {
                    throw new Error("Article not found or empty response.");
                }
            } catch (error: any) {
                if (isMounted) {
                    setStatesError(error.message || "An unknown error occurred.");
                }
            } finally {
                if (isMounted) {
                    setIsLoadingStates(false);
                }
            }
        };

        fetchAvailableStates();

        return () => {
            isMounted = false;
        };
    }, [article]);

    useEffect(() => {
        console.log("Updated PriceRequest:", priceRequest);
    }, [priceRequest]);

    // VALIDATE COMPLETION
    const validateCompletion = () => {
        const errors: string[] = [];

        if (!currentCondition) {
            errors.push("Zustand fehlt");
        }

        const allQuestions = [
            ...(article?.conditionQuestions || []),
            ...(article?.publicQuestions || []),
            ...(article?.checkQuestions || [])
        ];

        const unansweredQuestions = allQuestions.filter(q => !answeredQuestions.has(q.id));
        if (unansweredQuestions.length > 0) {
            errors.push(`Folgende Fragen sind noch nicht beantwortet: ${unansweredQuestions.map(q => q.text).join(", ")}`);
        }

        if (article?.hasSerialNumber && !serialNumber) {
            errors.push("Seriennummer / IMEI fehlt");
        }

        const requiredPhotos = currentCondition && currentCondition.id > 4 ? 2 : 1;
        const activePhotos = photos.filter(photo => !photo.isDeleted);
        if (!activePhotos || activePhotos.length < requiredPhotos) {
            errors.push(requiredPhotos === 2 ?
                "Mindestens 2 Fotos erforderlich" :
                "Mindestens 1 Foto erforderlich");
        }

        if (article?.deliveryScope && article.deliveryScope.length > 0) {
            if (selectedDeliveryScope.length === 0 && !noDeliveryScope) {
                errors.push("Bitte wähle mindestens einen Lieferumfang aus oder bestätige explizit 'Kein Lieferumfang'");
            }
        }

        return errors;
    };

    useEffect(() => {
        const errors = validateCompletion();
        if (article) {
            article.canComplete = errors.length === 0;
        }
    }, [currentCondition, answeredQuestions, serialNumber, photos, selectedDeliveryScope, noDeliveryScope]);

    const [validationErrors, setValidationErrors] = useState<string[]>([]);
    const { isOpen: isValidationModalOpen, onOpen: onValidationModalOpen, onOpenChange: onValidationModalOpenChange } = useDisclosure();

    const handleModelChange = async (newArticleId: number) => {
        try {
            setIsLoadingNewArticle(true);

            if (article) {
                setOldArticle(article);
            }

            const newArticle = await articleService.getSellArticleForCheck(ArticleQueryType.id, newArticleId.toString());

            if (newArticle) {
                const updatedAnsweredQuestions = new Set<number>();
                const updatedPriceRequest = new PriceRequest({
                    ...priceRequest,
                    articleId: newArticle.articleId,
                    articleQuestions: []
                });

                newArticle.conditionQuestions?.forEach(newQuestion => {
                    const oldQuestion = article?.conditionQuestions?.find(q => q.id === newQuestion.id);
                    if (oldQuestion && answeredQuestions.has(oldQuestion.id)) {
                        updatedAnsweredQuestions.add(newQuestion.id);
                        const oldAnswer = priceRequest?.articleQuestions.find(q => q.id === oldQuestion.id);
                        if (oldAnswer) {
                            updatedPriceRequest.articleQuestions.push(new PriceRequestArticleQuestion({
                                id: newQuestion.id,
                                userSelect: oldAnswer.userSelect
                            }));
                        }
                    }
                });

                newArticle.publicQuestions?.forEach(newQuestion => {
                    const oldQuestion = article?.publicQuestions?.find(q => q.id === newQuestion.id);
                    if (oldQuestion && answeredQuestions.has(oldQuestion.id)) {
                        updatedAnsweredQuestions.add(newQuestion.id);
                        const oldAnswer = priceRequest?.articleQuestions.find(q => q.id === oldQuestion.id);
                        if (oldAnswer) {
                            updatedPriceRequest.articleQuestions.push(new PriceRequestArticleQuestion({
                                id: newQuestion.id,
                                userSelect: oldAnswer.userSelect
                            }));
                        }
                    }
                });

                newArticle.checkQuestions?.forEach(newQuestion => {
                    const oldQuestion = article?.checkQuestions?.find(q => q.id === newQuestion.id);
                    if (oldQuestion && answeredQuestions.has(oldQuestion.id)) {
                        updatedAnsweredQuestions.add(newQuestion.id);
                        const oldAnswer = priceRequest?.articleQuestions.find(q => q.id === oldQuestion.id);
                        if (oldAnswer) {
                            updatedPriceRequest.articleQuestions.push(new PriceRequestArticleQuestion({
                                id: newQuestion.id,
                                userSelect: oldAnswer.userSelect
                            }));
                        }
                    }
                });

                setArticle(newArticle);
                setAnsweredQuestions(updatedAnsweredQuestions);
                setPriceRequest(updatedPriceRequest);
                setShowModelChangeAlert(true);

                setCurrentCondition(null);
                setPhotos([]);
                setSerialNumber("");
                setSelectedDeliveryScope([]);
                setNoDeliveryScope(false);
            }
        } catch (error) {
            console.error('Error during model change:', error);
            addToast({
                title: "Fehler beim Modellwechsel",
                description: "Der Artikel konnte nicht gewechselt werden. Bitte versuche es erneut.",
                color: "primary",
                timeout: 5000,
                shouldShowTimeoutProgress: true,
            });
        } finally {
            setIsLoadingNewArticle(false);
        }
    };

    const handleRevertModelChange = async () => {
        if (!oldArticle) return;

        try {
            setIsLoadingNewArticle(true);

            setArticle(oldArticle);

            const oldAnsweredQuestions = new Set<number>();
            const oldPriceRequest = new PriceRequest({
                ...priceRequest,
                articleId: oldArticle.articleId,
                articleQuestions: []
            });

            oldArticle.conditionQuestions?.forEach(question => {
                if (answeredQuestions.has(question.id)) {
                    oldAnsweredQuestions.add(question.id);
                    const oldAnswer = priceRequest?.articleQuestions.find(q => q.id === question.id);
                    if (oldAnswer) {
                        oldPriceRequest.articleQuestions.push(new PriceRequestArticleQuestion({
                            id: question.id,
                            userSelect: oldAnswer.userSelect
                        }));
                    }
                }
            });

            oldArticle.publicQuestions?.forEach(question => {
                if (answeredQuestions.has(question.id)) {
                    oldAnsweredQuestions.add(question.id);
                    const oldAnswer = priceRequest?.articleQuestions.find(q => q.id === question.id);
                    if (oldAnswer) {
                        oldPriceRequest.articleQuestions.push(new PriceRequestArticleQuestion({
                            id: question.id,
                            userSelect: oldAnswer.userSelect
                        }));
                    }
                }
            });

            oldArticle.checkQuestions?.forEach(question => {
                if (answeredQuestions.has(question.id)) {
                    oldAnsweredQuestions.add(question.id);
                    const oldAnswer = priceRequest?.articleQuestions.find(q => q.id === question.id);
                    if (oldAnswer) {
                        oldPriceRequest.articleQuestions.push(new PriceRequestArticleQuestion({
                            id: question.id,
                            userSelect: oldAnswer.userSelect
                        }));
                    }
                }
            });

            setAnsweredQuestions(oldAnsweredQuestions);
            setPriceRequest(oldPriceRequest);
            setShowModelChangeAlert(false);
            setOldArticle(null);
        } catch (error) {
            console.error('Error during model change revert:', error);
            addToast({
                title: "Fehler beim Rückgängig machen",
                description: "Der Modellwechsel konnte nicht rückgängig gemacht werden. Bitte versuche es erneut.",
                color: "danger",
                timeout: 5000,
                shouldShowTimeoutProgress: true,
            });
        } finally {
            setIsLoadingNewArticle(false);
        }
    };

    // CONTROLLER TEST
    const { isOpen: isControllerTestOpen, onOpen: onControllerTestOpen, onOpenChange: onControllerTestOpenChange } = useDisclosure();
    const [controllerTestResult, setControllerTestResult] = useState<ControllerTestResult | null>(null);
    const { isOpen: isProcessingInstructionsOpen, onOpen: onProcessingInstructionsOpen, onOpenChange: onProcessingInstructionsOpenChange } = useDisclosure();

    const isControllerArticle = article?.title?.toLowerCase().includes('controller');

    const handleControllerTestComplete = (result: ControllerTestResult) => {
        setControllerTestResult(result);
    };

    const handleTextSelect = (text: string) => {
        setEditorContent(prev => {
            const newContent = prev ? `${prev}\n\n${text}` : text;
            return newContent.trim();
        });
    };

    // UI
    return (
        <div className="w-full h-screen flex-1 p-4 overflow-y-auto overflow-x-hidden">
            {isDemoMode && <ArticleStartOverComponent /> /* SHOW SEARCH COMPONENT IN DEMO MODE */}
            <div className="flex items-center gap-x-3">
                <h1 className="text-3xl font-bold leading-9 text-default-foreground">Warenprüfung</h1>
            </div>
            <h2 className="mt-2 text-small text-default-500 pb-6">
                Beantworte die Fragen und folge den Anweisungen, um den Artikel korrekt zu prüfen.
            </h2>

            {showModelChangeAlert && oldArticle && (
                <div className="mb-4">
                    <Alert
                        className="w-full"
                        color="warning"
                        variant="flat"
                    >
                        <div className="flex flex-col gap-1 w-full">
                            <div className="flex items-center justify-between w-full">
                                <div className="flex items-center gap-2 w-full">
                                    <span className="font-medium">Modellwechsel durchgeführt</span>
                                </div>
                                <Button
                                    color="warning"
                                    variant="flat"
                                    size="sm"
                                    onPress={handleRevertModelChange}
                                    isLoading={isLoadingNewArticle}
                                >
                                    Rückgängig
                                </Button>
                            </div>
                            <p className="text-sm">
                                Der Artikel wurde von "{oldArticle.title}" zu "{article?.title}" gewechselt.
                                Gemeinsame Antworten wurden übernommen.
                            </p>
                        </div>
                    </Alert>
                </div>
            )}

            {isLoading || isLoadingStates ? (
                <div className="flex justify-center">
                    <Spinner size="lg" />
                </div>
            ) : error || statesError ? (
                <Card className="w-full rounded-lg border bg-background p-4 shadow">
                    <h3 className="text-red-600 font-bold text-xl">Ups</h3>
                    <p className="text-red-500">{error ?? 'Fehler beim Artikeldaten-Abruf'}</p>
                    <p className="text-red-500">{statesError ?? 'Fehler beim Abruf vorheriger Prüfungen'}</p>
                    {/* TODO: IF STATES ERROR OFFER OPTION TO START OVER AGAIN */}
                </Card>
            ) : article ? (
                <div className="flex flex-col lg:flex-row lg:gap-4 lg:order-first relative">
                    <div className="flex-grow lg:flex-grow-0 lg:w-2/3 space-y-4">
                        <Card className="w-full rounded-lg border bg-background shadow">
                            <ArticleInfoCard
                                article={article}
                                onModelChangeOpen={onModelChangeOpen}
                                onImprovementOpen={onImprovementOpen}
                                onConditionCalculatorOpen={onConditionCalculatorOpen}
                                handleQuestionChange={handleQuestionChangeWithTracking}
                                currentCondition={currentCondition}
                                calculatorCondition={calculatorCondition}
                                onConditionChange={handleConditionChange}
                                onDeliveryScopeChange={handleDeliveryScopeChange}
                                conditionChangeText={conditionChangeText}
                                onConditionChangeTextChange={setConditionChangeText}
                                onProcessingInstructionsOpen={onProcessingInstructionsOpen}
                            />
                        </Card>
                        <Card className="w-full rounded-lg border bg-background shadow">
                            <CardBody>
                                <div className="flex flex-col gap-4">
                                    <div className="relative">
                                        <ArticleEditor
                                            value={editorContent}
                                            onChange={setEditorContent}
                                            label="Artikelbeschreibung"
                                            placeholder="Beschreibung des aktuellen Zustands und der Funktion des Artikels."
                                            articleTitle={article?.title}
                                            articleConditionOrigin={calculatorCondition?.name}
                                            articleConditionNew={currentCondition?.name}
                                            photos={photos.filter(p => !p.isDeleted).map(p => p.edited || p.original || '')}
                                        />
                                        <div className="absolute bottom-2 right-2 flex gap-2">
                                            <Button
                                                isIconOnly
                                                size="sm"
                                                variant="flat"
                                                color="primary"
                                                onPress={onTextListOpen}
                                            >
                                                <Icon icon="solar:add-circle-outline" width={20} />
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                        <div className="flex justify-between items-center w-full pt-4">
                            <Button
                                variant="light"
                                color="primary"
                                onPress={() => {
                                    addToast({
                                        title: "Bearbeitung abbrechen",
                                        description: "Bearbeitung wird abgebrochen, alle Änderungen werden verworfen.",
                                        timeout: 3000,
                                        shouldShowTimeoutProgress: true,
                                    });
                                }}
                            >
                                Prüfung abbrechen
                            </Button>
                            <div className="flex gap-3">
                                <Button color="default" variant="bordered" onPress={() => {
                                    addToast({
                                        title: "Aktuellen Stand speichern",
                                        description: "Der aktuelle Stand wird gespeichert und kann später wieder geladen werden.",
                                        timeout: 3000,
                                        shouldShowTimeoutProgress: true,
                                    });
                                }}>
                                    Speichern
                                </Button>
                                <Button
                                    color={article.canComplete ? "primary" : "default"}
                                    variant="solid"
                                    onPress={() => {
                                        const errors = validateCompletion();
                                        if (errors.length > 0) {
                                            setValidationErrors(errors);
                                            onValidationModalOpen();
                                        } else {
                                            addToast({
                                                title: "Prüfung wird abgeschlossen",
                                                description: "Die Prüfung wird abgeschlossen und die Daten werden geschrieben.",
                                                timeout: 3000,
                                                shouldShowTimeoutProgress: true,
                                                color: "success"
                                            });
                                        }
                                    }}
                                >
                                    Abschließen
                                </Button>
                            </div>
                        </div>
                    </div>
                    <div className="flex-grow lg:flex-grow-0 lg:w-1/3 space-y-4">
                        <div className="sticky top-2">
                            <Card className="w-full rounded-lg border bg-background shadow mb-4">
                                <ArticleSidebar
                                    article={article}
                                    id={id}
                                    type={type}
                                    canSeeCustomerData={canSeeCustomerData}
                                    canViewPrice={canViewPrice}
                                    canEditPrice={canEditPrice}
                                    onCameraOpen={onCameraOpen}
                                    onGalleryOpen={onGalleryOpen}
                                    onLabelOpen={() => setIsLabelOpen(true)}
                                    onPriceEditOpen={onPriceEditOpen}
                                />
                            </Card>
                            <Card className="w-full rounded-lg border bg-background shadow">
                                <ArticleDetailsCard
                                    article={article}
                                    isDemoMode={isDemoMode}
                                    canViewPrice={canViewPrice}
                                    canEditPrice={canEditPrice}
                                    onLabelOpen={() => {
                                        const hasCondition = !!currentCondition;
                                        const nonRookieQuestions = [
                                            ...(article?.conditionQuestions || []),
                                            ...(article?.publicQuestions || []),
                                            ...(article?.checkQuestions || [])
                                        ];
                                        const hasAnsweredNonRookieQuestions = nonRookieQuestions.every(q => answeredQuestions.has(q.id));

                                        if (!hasCondition || !hasAnsweredNonRookieQuestions) {
                                            addToast({
                                                title: "Ups, fehlende Angaben",
                                                description: "Beantworte alle Fragen und wähle einen Zustand aus, um ein Etikett drucken zu können.",
                                                color: "primary",
                                                timeout: 5000,
                                                shouldShowTimeoutProgress: true,
                                            });
                                            return;
                                        }
                                        setIsLabelOpen(true);
                                    }}
                                    onPriceEditOpen={onPriceEditOpen}
                                    serialNumber={serialNumber}
                                    onSerialNumberChange={handleSerialNumberChange}
                                    price={price?.amount ?? null}
                                    isLoadingPrice={isLoadingPrice}
                                    hasAnsweredAllQuestions={answeredQuestions.size === (article?.conditionQuestions?.length || 0) + (article?.publicQuestions?.length || 0) + (article?.checkQuestions?.length || 0)}
                                    hasCondition={!!currentCondition}
                                />
                            </Card>
                        </div>
                    </div>
                </div>
            ) : (
                <p className="text-default-500">Keine Artikeldaten verfügbar.</p>
            )}

            {isControllerArticle && (
                <div className="mb-4 p-4 bg-primary-50 border border-primary-200 rounded-lg">
                    <div className="flex justify-between items-center">
                        <div>
                            <h3 className="text-lg font-semibold mb-2">Controller Funktionstest</h3>
                            <p className="text-gray-600">
                                {controllerTestResult
                                    ? `Test abgeschlossen - ${controllerTestResult.isDefect ? 'Defekte gefunden' : 'Alle Tests bestanden'}`
                                    : 'Führe einen Funktionstest für diesen Controller durch.'}
                            </p>
                            {controllerTestResult?.isDefect && (
                                <div className="mt-2">
                                    <p className="font-semibold">Defekte Tasten:</p>
                                    <ul className="list-disc list-inside">
                                        {controllerTestResult.buttons
                                            .filter(button => button.isDefect)
                                            .map(button => (
                                                <li key={button.id} className="text-primary-600">{button.name}</li>
                                            ))}
                                    </ul>
                                </div>
                            )}
                        </div>
                        <Button
                            variant="solid"
                            className={controllerTestResult?.isDefect
                                ? 'bg-primary-500 text-white hover:bg-primary-600'
                                : 'bg-success-500 text-white hover:bg-success-600'
                            }
                            onClick={onControllerTestOpen}
                        >
                            {controllerTestResult ? 'Test wiederholen' : 'Funktionstest starten'}
                        </Button>
                    </div>
                </div>
            )}

            <ConditionCalculatorDrawer
                isOpen={isConditionCalculatorOpen}
                onOpenChange={onConditionCalculatorOpenChange}
                articleId={article?.articleId}
                onConditionSelected={handleConditionFromCalculator}
            />
            <ModelChangeDrawer
                isOpen={isModelChangeOpen}
                onOpenChange={onModelChangeOpenChange}
                articleId={article?.articleId || 0}
                onArticleChange={handleModelChange}
            />
            <ImporvementDrawer
                isOpen={isImprovementOpen}
                onOpenChange={onImprovementOpenChange}
                improvementId={improvementId}
            />
            <PriceChangeDrawer
                isOpen={isPriceEditOpen}
                onOpenChange={onPriceEditOpenChange}
                currentPrice={0}
            />
            <LabelDrawer
                isOpen={isLabelOpen}
                onOpenChange={() => setIsLabelOpen(false)}
                article={article}
                serialNumber={serialNumber}
                box={true}
                condition={currentCondition?.id ?? 0}
                defective={false}
                comments={""}
            />
            <CameraModal
                isOpen={isCameraOpen}
                onOpenChange={onCameraOpenChange}
                detailId={id}
                onPhotosUpdate={handlePhotoUpdate}
                existingPhotos={photos}
                onNavigateToGallery={() => {
                    onCameraOpenChange();
                    onGalleryOpenChange();
                }}
            />
            <GalleryModal
                isOpen={isGalleryOpen}
                onOpenChange={onGalleryOpenChange}
                detailId={id}
                photos={photos}
                onPhotoDelete={handlePhotoDelete}
                onPhotoEdit={handlePhotoEdit}
                onPhotoRestore={handlePhotoRestore}
                onNavigateToCamera={() => {
                    onGalleryOpenChange();
                    onCameraOpenChange();
                }}
            />
            <PhotoEdiorModal
                isOpen={isPhotoEditorOpen}
                onOpenChange={onPhotoEditorOpenChange}
                img={""}
                photoId={""}
                onSave={handlePhotoEdit}
                onCancel={() => onPhotoEditorOpenChange()}
            />
            <TextListDrawer
                isOpen={isTextListOpen}
                onOpenChange={onTextListOpenChange}
                onTextSelect={handleTextSelect}
                articleId={article?.articleId ?? 0}
            />

            {/* Validation Modal */}
            <Modal
                isOpen={isValidationModalOpen}
                onOpenChange={onValidationModalOpenChange}
                size="lg"
                backdrop="blur"
            >
                <ModalContent>
                    <ModalHeader className="flex flex-col gap-1">
                        <div className="flex items-center gap-2">
                            <h3 className="text-xl font-semibold">Prüfung kann nicht abgeschlossen werden</h3>
                        </div>
                        <p className="text-sm text-default-500">
                            Bitte korrigiere die folgenden Punkte:
                        </p>
                    </ModalHeader>
                    <ModalBody>
                        <div className="space-y-6">
                            {validationErrors.some(error => error.includes("Fragen")) && (
                                <div className="space-y-2">
                                    <div className="flex items-center gap-2 text-default-600 dark:text-default-400 font-medium">
                                        <Icon icon="solar:question-square-outline" width={20} />
                                        <span>Offene Fragen</span>
                                    </div>
                                    <ul className="list-disc list-inside text-sm text-default-600 dark:text-default-400 ml-6 space-y-1">
                                        {validationErrors
                                            .filter(error => error.includes("Fragen"))
                                            .map(error => error.replace("Folgende Fragen sind noch nicht beantwortet: ", "").split(", "))
                                            .flat()
                                            .map((question, idx) => (
                                                <li key={idx}>{question}</li>
                                            ))}
                                    </ul>
                                </div>
                            )}

                            {validationErrors.some(error => error.includes("Foto")) && (
                                <div className="space-y-2">
                                    <div className="flex items-center gap-2 text-default-600 dark:text-default-400 font-medium">
                                        <Icon icon="solar:camera-outline" width={20} />
                                        <span>Fehlende Fotos</span>
                                    </div>
                                    <div className="text-sm text-default-600 dark:text-default-400 ml-8">
                                        {validationErrors.find(error => error.includes("Foto"))}
                                    </div>
                                </div>
                            )}

                            {(validationErrors.some(error => error === "Zustand fehlt") ||
                                validationErrors.some(error => error.includes("Seriennummer")) ||
                                validationErrors.some(error => error.includes("Lieferumfang"))) && (
                                    <div className="space-y-2">
                                        <div className="flex items-center gap-2 text-default-600 dark:text-default-400 font-medium">
                                            <Icon icon="solar:checklist-minimalistic-outline" width={20} />
                                            <span>Weitere Angaben</span>
                                        </div>
                                        <div className="space-y-2 ml-8">
                                            {validationErrors.some(error => error === "Zustand fehlt") && (
                                                <div className="flex items-center gap-2 text-sm text-default-600 dark:text-default-400">
                                                    <span>Zustand fehlt</span>
                                                </div>
                                            )}
                                            {validationErrors.some(error => error.includes("Seriennummer")) && (
                                                <div className="flex items-center gap-2 text-sm text-default-600 dark:text-default-400">
                                                    <span>Seriennummer / IMEI fehlt</span>
                                                </div>
                                            )}
                                            {validationErrors.some(error => error.includes("Lieferumfang")) && (
                                                <div className="flex items-center gap-2 text-sm text-default-600 dark:text-default-400">
                                                    <span>Lieferumfang fehlt</span>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                )}
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            color="primary"
                            variant="solid"
                            onPress={onValidationModalOpenChange}
                            className="w-full sm:w-auto"
                        >
                            Alles klar
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
            <ControllerTestModal
                isOpen={isControllerTestOpen}
                onClose={onControllerTestOpenChange}
                onComplete={handleControllerTestComplete}
            />

            {/* Bearbeitungshinweise Modal */}
            <Modal
                isOpen={isProcessingInstructionsOpen}
                onOpenChange={onProcessingInstructionsOpenChange}
                size="2xl"
                backdrop="blur"
            >
                <ModalContent>
                    <ModalHeader className="flex flex-col gap-1">
                        <div className="flex items-center gap-2">
                            <h3 className="text-xl font-semibold">Bearbeitungshinweise</h3>
                        </div>
                    </ModalHeader>
                    <ModalBody>
                        <div className="prose max-w-none" dangerouslySetInnerHTML={{ __html: article?.checkInformation || '' }} />
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            color="primary"
                            variant="solid"
                            onPress={onProcessingInstructionsOpenChange}
                            className="w-full sm:w-auto"
                        >
                            Schließen
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </div>
    );
};

export default ArticleDetail;