import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Layout from './components/Layout';
import AuthMiddleware from './middlewares/authMiddleware';
import AntiAuthMiddleware from './middlewares/antiAuthMiddleware';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AuthPage from './pages/Auth';
import AppHomePage from './pages/home/Page';

function App() {
  return (
    <Router>
      <Layout>
        <Routes>
          <Route path="/auth" element={
            <AntiAuthMiddleware>
              <AuthPage />
            </AntiAuthMiddleware>
          } />
          <Route path="/*" element={
            <AuthMiddleware>
              <AppHomePage />
            </AuthMiddleware>
          } />
        </Routes>
      </Layout>
      <ToastContainer />
    </Router>
  );
}

export default App;
