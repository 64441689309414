import WikiContent from './wikiContent';
class Wiki {
    constructor({
        id,
        title,
        created,
        updated,
        img,
        description,
        department,
        documentCount,
        wikis,
    }) {
        this.id = id;
        this.title = title;
        this.created = created;
        this.updated = updated;
        this.img = img;
        this.description = description;
        this.department = department;
        this.documentCount = documentCount;
        this.wikis = wikis;
    }

    static fromJson(json) {
        return new Wiki({
            id: json.id || null,
            title: json.title || '',
            created: json.created || null,
            updated: json.updated || null,
            img: json.img || '',
            description: json.description || '',
            department: json.department || '',
            documentCount: json.documentCount || 0,
            wikis: json.wikis ? json.wikis.map(wiki => WikiContent.fromJson(wiki)) : [],
        });
    }

    toJson() {
        return {
            id: this.id,
            title: this.title,
            created: this.created,
            updated: this.updated,
            img: this.img,
            description: this.description,
            department: this.department,
            documentCount: this.documentCount,
            wikis: this.wikis,
        };
    }
}

export default Wiki;