import React from "react";
import { Card, CardFooter, Image, Button } from "@heroui/react";
import { SpeedUploadConfig } from "../models/SpeedUploadConfig";
import { Icon } from '@iconify/react/dist/iconify.js';

interface SpeeduploadGridProps {
    items: SpeedUploadConfig[];
    onItemClick: (config: SpeedUploadConfig) => void;
}

const SpeeduploadGrid: React.FC<SpeeduploadGridProps> = ({ items, onItemClick }) => {
    if (items.length === 0) {
        return (
            <div className="flex flex-col items-center justify-center p-8 text-center">
                <Icon icon="solar:layers-outline" className="text-default-500 mb-4" width={48} />
                <h3 className="text-xl font-semibold mb-2">Keine Konfigurationen gefunden</h3>
                <p className="text-default-500 mb-4">Erstelle eine neue Konfiguration oder passe deine Suche an.</p>
                <Button
                    color="primary"
                    as="a"
                    href="https://www.sx-oz.de/zoxs-ebay-speedupload/show/konfigurator.html"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    Neue Konfiguration erstellen
                </Button>
            </div>
        );
    }

    return (
        <div className="grid grid-cols-[repeat(auto-fit,minmax(350px,1fr))] gap-3">
            {items.map((config) => (
                <div key={config.conf_id} className="flex justify-center">
                    <Card
                        isFooterBlurred
                        radius="none"
                        className="w-full h-[250px] rounded-lg border bg-background shadow"
                        isPressable
                        onPress={() => onItemClick(config)}
                    >
                        <Image
                            removeWrapper
                            radius="none"
                            alt={config.name}
                            className="z-0 w-full h-full object-cover"
                            src={config.image_url || 'https://via.placeholder.com/350'}
                        />
                        <CardFooter className="absolute bg-black/40 bottom-0 z-10 border-t border-default-600 gap-4">
                            <div className="flex flex-grow gap-2 items-center">
                                <div className="flex flex-col text-left">
                                    <p className="text-white/90 font-medium text-l">{config.name}</p>
                                </div>
                            </div>
                            <Button
                                color="primary"
                                radius="full"
                                size="sm"
                                onPress={() => onItemClick(config)}
                            >
                                Starten
                            </Button>
                        </CardFooter>
                    </Card>
                </div>
            ))}
        </div>
    );
};

export default SpeeduploadGrid; 