export interface EbayMissingData {
  commitUrl: string;
  state: any;
  type: string;
  message: string;
  error: string;
  recommended_category?: {
    id: string;
    name: string;
  };
  epid?: string;
  category_id?: number;
}

export const EbayMissingData = {
  fromJson: (json: any): EbayMissingData => ({
    commitUrl: json.commitUrl,
    state: json.state,
    type: json.type,
    message: json.message,
    error: json.error,
    recommended_category: json.recommended_category,
    epid: json.epid,
    category_id: json.category_id,
  }),

  toJson: (missingData: EbayMissingData): any => ({
    commitUrl: missingData.commitUrl,
    state: missingData.state,
    type: missingData.type,
    message: missingData.message,
    error: missingData.error,
    recommended_category: missingData.recommended_category,
    epid: missingData.epid,
    category_id: missingData.category_id,
  }),
}; 