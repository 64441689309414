import React, { useEffect, useState } from 'react';
import {
    Breadcrumbs, BreadcrumbItem, Spinner,
    Button,
    Accordion,
    AccordionItem,
} from "@heroui/react";
import { useNavigate, useLocation } from 'react-router-dom';
import { Trash, Share2 } from 'lucide-react';
import wikiService from '@/services/wikiService';
import WikiContent from './WikiContent';
import userService from "@/services/userService";
import ComingSoon from '@/components/ComingSoon';

const itemClasses = {
    base: "py-0 w-full",
    trigger: "data-[hover=true]:bg-default-100 rounded-lg h-14 flex items-center",
};

const WikiCategory = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const pathParts = location.pathname.split('/');
    const id = pathParts[pathParts.length - 1];
    let wikiId = null;
    
    if (location.hash) {
        wikiId = location.hash.substring(1);
    }
    
    const user = userService.user;

    const [wiki, setWiki] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [selectedItemId, setSelectedItemId] = useState(null);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
    const [expandedKeys, setExpandedKeys] = useState(new Set([]));
    const [initialLoadComplete, setInitialLoadComplete] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        const fetchWiki = async () => {
            try {
                const wiki = await wikiService.getWikiContent(id);
                setWiki(wiki.wiki);
                
                if (wikiId) {
                    const exists = wiki.wiki.wikis && wiki.wiki.wikis.some(item => item.id === wikiId);
                    if (exists) {
                        setSelectedItemId(wikiId);
                        setExpandedKeys(new Set([wikiId]));
                    } else if (wiki.wiki.wikis && wiki.wiki.wikis.length > 0) {
                        setSelectedItemId(wiki.wiki.wikis[0].id);
                        setExpandedKeys(new Set([wiki.wiki.wikis[0].id]));
                        // Only replace URL if the hash wasn't specified or was invalid
                        navigate(`/wiki/${id}#${wiki.wiki.wikis[0].id}`, { replace: true });
                    }
                } else if (wiki.wiki.wikis && wiki.wiki.wikis.length > 0) {
                    setSelectedItemId(wiki.wiki.wikis[0].id);
                    setExpandedKeys(new Set([wiki.wiki.wikis[0].id]));
                    navigate(`/wiki/${id}#${wiki.wiki.wikis[0].id}`, { replace: true });
                }
                setInitialLoadComplete(true);
            } catch (err) {
                setError(err.message);
                setInitialLoadComplete(true);
            } finally {
                setLoading(false);
            }
        };

        if (id) {
            fetchWiki();
        } else {
            setLoading(false);
            setInitialLoadComplete(true);
        }
    }, [id, navigate]);

    // Separate effect to handle hash changes after initial load
    useEffect(() => {
        if (!initialLoadComplete || !wiki || !wiki.wikis || wiki.wikis.length === 0) {
            return;
        }

        // Only process hash changes after initial load is complete
        if (location.hash) {
            const hashId = location.hash.substring(1);
            const exists = wiki.wikis.some(item => item.id === hashId);
            
            if (exists) {
                setSelectedItemId(hashId);
                setExpandedKeys(new Set([hashId]));
            }
        }
    }, [location.hash, initialLoadComplete, wiki]);

    const handleBreadcrumbClick = () => {
        navigate(`/wiki`);
    };

    const handleCreateClick = () => {
        if (id) {
            navigate(`/wiki/create?collection=${id}`);
        }
    };

    const handleItemSelect = (itemId) => {
        setSelectedItemId(itemId);
        navigate(`/wiki/${id}#${itemId}`);
    };

    const handleAccordionChange = (keys) => {
        // The keys parameter contains the set of expanded keys
        const expandedKeysArray = Array.from(keys);
        
        // If we have at least one expanded key, update the selectedItemId and URL
        if (expandedKeysArray.length > 0) {
            const newSelectedItemId = expandedKeysArray[expandedKeysArray.length - 1];
            setSelectedItemId(newSelectedItemId);
            navigate(`/wiki/${id}#${newSelectedItemId}`);
        }
        
        setExpandedKeys(keys);
    };

    const handleShareClick = (itemId, e) => {
        if (e) e.stopPropagation(); // Prevent accordion toggle
        const url = `${window.location.origin}/wiki/${id}#${itemId}`;
        navigator.clipboard.writeText(url);
        // You could add a toast notification here
    };

    const handleEditClick = (itemId, e) => {
        if (e) e.stopPropagation(); // Prevent accordion toggle
        navigate(`/wiki/edit?id=${itemId}`);
    };

    const handleDeleteClick = (itemId, e) => {
        if (e) e.stopPropagation(); // Prevent accordion toggle
        // Implement delete functionality or confirmation modal
        console.log('Delete item:', itemId);
    };

    const selectedItem = wiki?.wikis?.find(item => item.id === selectedItemId);
    const canEdit = user.modules.includes(6) || (user.isHeadOf === true && wiki && user.department === wiki.department);

    const ActionButtons = ({ itemId, withStopPropagation = false }) => (
        <div className="flex gap-2">
            {canEdit && (
                <>
                    {/* Delete button */}
                    <Button 
                        isIconOnly
                        size="sm"
                        color="default" 
                        variant="light" 
                        onClick={withStopPropagation ? (e) => handleDeleteClick(itemId, e) : () => handleDeleteClick(itemId)}
                        title="Delete"
                    >
                        <Trash size={18} />
                    </Button>
                    
                    {/* Edit button */}
                    <Button 
                        isIconOnly
                        size="sm"
                        color="default" 
                        variant="light" 
                        onClick={withStopPropagation ? (e) => handleEditClick(itemId, e) : () => handleEditClick(itemId)}
                        title="Edit"
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path d="M17 3a2.85 2.85 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5Z"/></svg>
                    </Button>
                </>
            )}
            
            {/* Share button (available to everyone) */}
            <Button 
                isIconOnly
                size="sm"
                color="default" 
                variant="light" 
                onClick={withStopPropagation ? (e) => handleShareClick(itemId, e) : () => handleShareClick(itemId)}
                title="Share"
            >
                <Share2 size={18} />
            </Button>
        </div>
    );

    return (
        <div className="w-full flex-1 p-4">
            <div className="flex items-center gap-x-3">
                <h1 className="text-3xl font-bold leading-9 text-default-foreground">Wiki</h1>
            </div>
            <h2 className="mt-2 text-small text-default-500 mb-6">
                Deine Knowledge-Base für Deinen Alltag im ZOXS-Universum.
            </h2>
            <Breadcrumbs variant="bordered" radius='md'>
                <BreadcrumbItem onClick={handleBreadcrumbClick} onPress={handleBreadcrumbClick} >Wiki Home</BreadcrumbItem>
                {wiki && wiki.title ? (
                    <BreadcrumbItem>{wiki.title}</BreadcrumbItem>
                ) : (
                    <BreadcrumbItem>Unbekannte Kategorie</BreadcrumbItem>
                )}
                {selectedItem && (
                    <BreadcrumbItem>{selectedItem.title}</BreadcrumbItem>
                )}
            </Breadcrumbs>
            {loading ? (
                <div className="flex justify-center my-6">
                    <Spinner size="lg" />
                </div>
            ) : error ? (
                <div className="mt-6">
                    <h3 className="text-xl font-semibold text-red-500">Fehler</h3>
                    <p>{error}</p>
                </div>
            ) : wiki ? (
                <>
                    <div className="flex items-center gap-x-3 mt-6">
                        <h1 className="text-3xl font-bold leading-9 text-default-foreground">{wiki.title}</h1>
                    </div>
                    <h2 className="mt-2 text-small text-default-500 mb-6">
                        {wiki.description}
                    </h2>
                    
                    {isMobile ? (
                        wiki.wikis && wiki.wikis.length > 0 ? (
                            <Accordion
                                isCompact
                                selectionMode="multiple"
                                showDivider={false}
                                className="rounded-lg border bg-background p-4 shadow"
                                variant="shadow"
                                itemClasses={itemClasses}
                                selectedKeys={expandedKeys}
                                onSelectionChange={handleAccordionChange}
                            >
                                {wiki.wikis.map((item) => (
                                    <AccordionItem
                                        key={item.id}
                                        aria-label={`Accordion ${item.id}`}
                                        title={
                                            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                                <span>{item.title}</span>
                                                <ActionButtons itemId={item.id} withStopPropagation={true} />
                                            </div>
                                        }
                                    >
                                        <WikiContent 
                                            item={item} 
                                            userRights={{ canEdit }}
                                        />
                                    </AccordionItem>
                                ))}
                            </Accordion>
                        ) : (
                            <div className="rounded-lg border bg-background p-4 shadow flex justify-center items-center h-32">
                                <p className="text-default-500">Die Kategorie ist leer</p>
                            </div>
                        )
                    ) : (
                        <div className="flex flex-row gap-4">
                            <div className="w-1/4 max-w-1/4">
                                {wiki.wikis && wiki.wikis.length > 0 ? (
                                    <ul className="space-y-2">
                                        {wiki.wikis.map((item) => (
                                            <li 
                                                key={item.id}
                                                className={`
                                                    p-2 rounded-xl cursor-pointer 
                                                    ${selectedItemId === item.id ? 'bg-default-100 text-primary font-medium' : 'hover:bg-default-100'}
                                                `}
                                                onClick={() => handleItemSelect(item.id)}
                                            >
                                                {item.title}
                                            </li>
                                        ))}
                                    </ul>
                                ) : (
                                    <div className="flex justify-center items-center h-32">
                                        <p className="text-default-500">Die Kategorie ist leer</p>
                                    </div>
                                )}
                            </div>
                            <div className="flex-1 rounded-lg border bg-background p-4 shadow">
                                {selectedItem ? (
                                    <div>
                                        <div className="flex justify-between items-center mb-4">
                                            <h3 className="text-xl font-bold">{selectedItem.title}</h3>
                                            <ActionButtons itemId={selectedItem.id} />
                                        </div>
                                        <WikiContent 
                                            item={selectedItem} 
                                            userRights={{ canEdit }}
                                        />
                                    </div>
                                ) : wiki.wikis && wiki.wikis.length > 0 ? (
                                    <div className="flex justify-center items-center h-64">
                                        <p className="text-default-500">Wähle ein Thema aus der Liste aus</p>
                                    </div>
                                ) : (
                                    <div className="flex justify-center items-center h-64">
                                        <p className="text-default-500">Die Kategorie ist leer</p>
                                    </div>
                                )}
                            </div>
                        </div>
                    )}
                </>
            ) : (
                <div className="mt-6">
                    <h3 className="text-xl font-semibold text-default-500">Ups</h3>
                    <p>Dieses Wiki gibt es nicht.</p>
                </div>
            )}
            {(userService.user.modules.includes(6) || userService.user.isHeadOf) && (
                <Button
                    onPress={handleCreateClick}
                    radius="full"
                    className="fixed bottom-6 right-6 z-50"
                    color="primary"
                    auto
                >
                    Inhalt erstellen
                </Button>
            )}
        </div>
    );
};

export default WikiCategory;