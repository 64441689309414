import { Button, Card, Input, Select, SelectItem } from "@heroui/react";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export const types = [
    { key: "asin", label: "ASIN" },
    { key: "ean", label: "EAN" },
    { key: "articleId", label: "Artikel-ID" },
];

function ArticleStartOverComponent({ presetId, presetType }: { presetId?: string; presetType?: string }) {
    const navigate = useNavigate();
    const [type, setType] = React.useState("asin");
    const [value, setValue] = React.useState("");

    useEffect(() => {
        if (presetType) {
            setType(presetType);
        }
        if (presetId) {
            setValue(presetId);
        }
    }, [presetType, presetId]);

    const handleSearch = () => {
        const route = "/admin-article/article";
        navigate(`${route}?type=${type}&id=${value}`);
    };

    return (
        <>
            <div className="flex items-center gap-x-3">
                <h1 className="text-3xl font-bold leading-9 text-default-foreground">Artikelaufruf</h1>
            </div>
            <h2 className="mt-2 text-small text-default-500 pb-6">
                Suche Artikel anhand von ASIN, EAN oder Artikel-ID.
            </h2>
            <Card className="w-full rounded-lg border bg-background shadow mb-6">
                <div className="flex flex-col gap-3 p-4">
                    <div className="flex flex-col md:flex-row md:space-x-2 w-full items-center">
                        <div className="w-full md:w-1/6">
                            <Select
                                fullWidth
                                disallowEmptySelection
                                label="Typ"
                                variant="bordered"
                                size="sm"
                                placeholder="Wähle einen Suchtyp"
                                selectedKeys={[type]}
                                onChange={(e) => setType(e.target.value)}
                            >
                                {types.map((type) => (
                                    <SelectItem key={type.key}>{type.label}</SelectItem>
                                ))}
                            </Select>
                        </div>
                        <div className="w-full md:w-5/6">
                            <Input
                                fullWidth
                                label="Referenz"
                                placeholder="Gib eine Referenz ein"
                                value={value}
                                variant="bordered"
                                size="sm"
                                onValueChange={setValue}
                                onKeyDown={(e) => {
                                    if (e.key === "Enter") {
                                        handleSearch();
                                    }
                                }}
                            />
                        </div>
                        <div className="w-full md:w-1/6 flex justify-center">
                            <Button color="primary" fullWidth onPress={handleSearch} size="lg">
                                Suchen
                            </Button>
                        </div>
                    </div>
                </div>
            </Card>
        </>
    );
}

export default ArticleStartOverComponent;